import React, { Component } from "react";
import { Link, withRouter  } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { databasesActions, utils } from "../../actions/generalActions";


/*
import {
    Modal,
    ModalHeader,
    ModalTitle,
    ModalClose,
    ModalBody,
    ModalFooter
} from "./react-modal-bootstrap/"; */

var reactMB = require("react-modal-bootstrap");
var Modal = reactMB.Modal;
var ModalHeader = reactMB.ModalHeader;
var ModalTitle = reactMB.ModalTitle;
var ModalClose = reactMB.ModalClose;
var ModalBody = reactMB.ModalBody;
var ModalFooter = reactMB.ModalFooter;


class Navbar extends Component {

    constructor() {
        super();
        this.state = {
            userZone: "",
            isCreateAcalculatorModalOpen: false,
            newCalculator: {
                name: "",
                description: ""
            },
            isErrorModalOpen: false,
        };
    }
    

    componentDidMount() {

        //const { user } = this.props.auth;
        
        this.updateUserZone(this.props);

        this.assetLinkCol = "white";
        this.myCalculatorLinkCol = "white";
        this.documentationLinkCol = "white";

        if (window.location.pathname == "/assets" || window.location.pathname == "/assetPage") {
            this.assetLinkCol = "#f1c111";
        }
        else if (window.location.pathname == "/myCalculators") {
            this.myCalculatorLinkCol = "#f1c111";
        }
        else if (window.location.pathname == "/documentation") {
            this.documentationLinkCol = "#f1c111";
        }

    }

    closeErrorModal = () => {
        this.setState({ isErrorModalOpen: false });
    };



    createAcalculator = () => {
        if (this.props.auth.isAuthenticated) {
            this.state.newCalculator = { name: "", description: "" };
            this.setState({
                isCreateAcalculatorModalOpen: true
            });
        }
        else {
            this.errorText = [];
            this.errorText.push(
                <div style={{
                    margin: "25px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    flexDirection: "column"
                }}>
                    <div style={{ color: "red" }}>Error</div><br />
                    {"You must be logged in to create calculators. By logging in, you will be able to create, delete calculators, access public calculators, add them and publish your own. Furthermore, you will have the ability to manage your calculators and make calculator updates as soon as available. You will also have access to all the functions published by users"}</div>)
            this.setState({ isErrorModalOpen: true });
        }
    };

    cancelCreateAcalculator = () => {
        this.setState({
            isCreateAcalculatorModalOpen: false
        });
    };

    confirmCreateAcalculator = (e) => {
        e.preventDefault();

        console.log(this.props.auth.guid);

        const datas = {
            calculatorName: utils.getCodedFrom(this.state.newCalculator.name),
            description: utils.getCodedFrom(this.state.newCalculator.description),
            authorGuid: this.props.auth.guid
        };

        databasesActions.createNewCalculator(datas)
            .then(res => {
                if (res.errorHasOccurred != null) {
                    this.setState({ isCreateAcalculatorModalOpen: false });
                    this.showAlertError("An error has occurred. Please try again.", "#alert-baseAreaModal");
                }
                else {
                    localStorage.setItem("selectedCalculator", JSON.stringify(res.data.calculator));
                    window.location.href = "/";
                }
            })
            .catch(err => {
                this.setState({ isCreateAcalculatorModalOpen: false });
                this.showAlertError("An error has occurred. Please try again.", "#alert-baseAreaModal");
            });

    };


    createCalculatorChangeHandler = (event) => {
        var newCalculator = this.state.newCalculator;

        if (event.target.id == "name") {
            newCalculator.name = event.target.value;
        }
        else if (event.target.id == "description") {
            newCalculator.description = event.target.value;
        }

        this.setState({ newCalculator: newCalculator });
    }

    updateUserZone = (props) => {
        const auth = props.auth;
        if (auth.isAuthenticated) {
            this.setState({
                userZone: <div style={{ marginLeft: "10px" }}>
                    <div style={{ color: "white", textAlign: "center", marginBottom: "10px" }}>{utils.minifyLine(auth.user.name, 30)}</div>
                    <button onClick={this.onLogoutClick} className="nav-link logoutStyle" style={{margin: "auto"}}>Logout</button></div>
            });
        }
        else {
            this.setState({ userZone: <a href = "/login" className="nav-link contact">Login</a> });
        }
    };

    showAlertSuccess = (message, area) => {
        var alertbox = new AlertBox(area, {
            closeTime: 3500,
            persistent: false,
            hideCloseButton: false
        }, true);

        alertbox.show(message);
    }


    showAlertError = (message, area) => {
        var alertbox = new AlertBox(area, {
            closeTime: 3500,
            persistent: false,
            hideCloseButton: false
        }, false);

        alertbox.show(message);
    }

    
    componentWillReceiveProps(nextProps) {
        this.updateUserZone(nextProps);     
    }
     
    

    onLogoutClick = e => {
        console.log()
        e.preventDefault();
        this.props.logoutUser(this.props.history);
    };

    render() {

        let subModalDialogStyles = {
            base: {
                bottom: -600,
                transition: 'bottom 0.4s'
            },
            open: {
                bottom: 0
            }
        };

        return (
            <div className = "myCustomNav">
                
                <div className= "navbar navbar-expand-lg navCTCustom"> 

                    <div className="myCustomContainer">
                        <div class = "logoZone">
                            <i className="logoCT"></i>
                            <a className="navbar-brand textLogo" href="/">                       
                        {' '} Calculate Together
            </a>                            
                            </div>

                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a href="/assets" className="nav-link linkNavCT" style={{ color: this.assetLinkCol }}>Assets</a>
                            </li>
                                <li className="nav-item">
                                    <button className="nav-link linkNavButtonCT" onClick={this.createAcalculator}>New Calculator</button>
                            </li>
                            <li className="nav-item">
                                    <a href="/myCalculators" className="nav-link linkNavCT" style={{ color: this.myCalculatorLinkCol }}>My Calculators</a>
                            </li>
                            <li className="nav-item">
                                    <a href="/documentation" className="nav-link linkNavCT" style={{ color: this.documentationLinkCol }}>Documentation</a>
                            </li>
                                <li className="nav-item">
                                    {this.state.userZone}
                            </li>
                        </ul>
                    </div>
                </div>


                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>


                </div>

                <Modal isOpen={this.state.isCreateAcalculatorModalOpen} onRequestHide={this.cancelCreateAcalculator} dialogStyles={subModalDialogStyles}>
                    <div class="myFormDivContainer">
                        <form class="myFormContainer" id="contact" action="" onSubmit={this.confirmCreateAcalculator}>
                            <fieldset>
                                <input class="myFormContainer" placeholder="Name" id="name" maxlength="1000" type="text" tabindex="1" onChange={this.createCalculatorChangeHandler} required value={this.state.newCalculator.name} autofocus />
                            </fieldset>
                            <fieldset>
                                <textarea placeholder="Description (Optional)" id="description" maxlength="1000" tabindex="2" onChange={this.createCalculatorChangeHandler} value={this.state.newCalculator.description} ></textarea>
                            </fieldset>


                            <fieldset>
                                <button name="submit" type="submit" id="contact-submit">Submit</button>
                            </fieldset>

                        </form>
                    </div>

                    <div id="alert-baseAreaModal" class="alert-area"></div>

                </Modal>

                <Modal isOpen={this.state.isErrorModalOpen} onRequestHide={this.closeErrorModal} dialogStyles={subModalDialogStyles}>
                    <ModalBody>
                        <ModalClose onClick={this.closeErrorModal} />
                        {this.errorText}
                    </ModalBody>
                </Modal>


               </div> 
            
        );
    }
}

Navbar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(
    mapStateToProps,
    { logoutUser }
)(withRouter(Navbar));


var AlertBox = function (id, option, success) {
    this.show = function (msg) {
        if (msg === '' || typeof msg === 'undefined' || msg === null) {
            throw '"msg parameter is empty"';
        }
        else {
            console.log(id);
            var alertArea = document.querySelector(id);
            var alertBox = document.createElement('DIV');
            var alertContent = document.createElement('DIV');
            var alertClose = document.createElement('A');
            var alertClass = this;
            alertContent.classList.add('alert-content');
            alertContent.innerText = msg;
            alertClose.classList.add('alert-close');
            if (success) {
                alertBox.classList.add('alert-box-green');
            }
            else {
                alertBox.classList.add('alert-box-red');
            }
            alertBox.appendChild(alertContent);
            if (!option.hideCloseButton || typeof option.hideCloseButton === 'undefined') {
                alertBox.appendChild(alertClose);
            }
            alertArea.appendChild(alertBox);
            alertClose.addEventListener('click', function (event) {
                event.preventDefault();
                alertClass.hide(alertBox);
            });
            if (!option.persistent) {
                var alertTimeout = setTimeout(function () {
                    alertClass.hide(alertBox);
                    clearTimeout(alertTimeout);
                }, option.closeTime);
            }
        }
    };

    this.hide = function (alertBox) {
        alertBox.classList.add('hide');
        var disperseTimeout = setTimeout(function () {
            if (alertBox.parentNode != null) {
                alertBox.parentNode.removeChild(alertBox);
                clearTimeout(disperseTimeout);
            }

        }, 500);
    };
};