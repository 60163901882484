import React, { Component } from "react";

class TermsPrivacy extends Component {

    constructor() {
        super();
    }

    componentDidMount() {
        document.title = "Terms and Privacy";
    }

    render() {

        return (
            <div style={{ display: "block", backgroundColor: "#f5f9fc", padding: "80px 250px 80px 250px" }}>
                <div style={{ backgroundColor: "white", padding: "70px 190px 70px 190px" }}>
                    <div style={{ marginTop: "15px", marginBottom: "35px", fontWeight: "bold", fontSize: "30px", fontFamily: "Times New Roman", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                        Terms of use and privacy policy 
                    </div>

                    <p style={{ marginTop: "0cm", marginRight: "0cm", marginBottom: "8.0pt", marginLeft: "0cm", lineHeight: "107%", fontSize: "25px", fontWeight: "normal", fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ display: "block",textAlign: "justify", color: "black",fontSize:"18px",lineHeight:"107 %",fontFamily:"Times New Roman, serif"}}>
                            This app has been developed for demonstration purposes.The items in the Assets section were added by myself, to allow testing of the user interface, so some items appear more than once.&nbsp;
                        </span></p><div style={{ height: "7px"}} />

                    <p style={{  marginTop: "0cm", marginRight: "0cm", marginBottom: "8.0pt", marginLeft: "0cm", lineHeight: "107%", fontSize: "25px", fontWeight: "normal", fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ display: "block", textAlign: "justify", color: "black", fontSize: "18px", lineHeight: "107 %", fontFamily: "Times New Roman, serif" }}>
                            Additionally, email validation has not been implemented to facilitate testing. It is therefore possible to connect with any email as long as the format is respected. Post requests are used to add data to the database, so if someone knows the guid of a calculator, he can then modify it by making http requests.&nbsp;
                        </span></p><div style={{ height: "7px" }} />

                    <p style={{marginTop: "0cm", marginRight: "0cm", marginBottom: "8.0pt", marginLeft: "0cm", lineHeight: "107%", fontSize: "25px", fontWeight: "normal", fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ display: "block",  textAlign: "justify", color: "black", fontSize: "18px", lineHeight: "107 %", fontFamily: "Times New Roman, serif" }}>
                            All of the algorithms used by the calculators including error detection were designed by myself.&nbsp;
                        </span></p><div style={{ height: "7px" }} />

                    <p style={{ marginTop: "0cm", marginRight: "0cm", marginBottom: "8.0pt", marginLeft: "0cm", lineHeight: "107%", fontSize: "25px", fontWeight: "normal", fontFamily: "Calibri,sans-serif" }}>
                        <span style={{ display: "block", textAlign: "justify", color: "black", fontSize: "18px", lineHeight: "107 %", fontFamily: "Times New Roman, serif" }}>
I do not use any personal information provided, but since the application was not made for commercial purposes, there is no need to provide personal information and passwords for registration and login.&nbsp;
                        </span></p>


                </div>

            </div> 
            
        );
    }
}

export default TermsPrivacy;


