//Script qui est charg� � chaque fois qu'on charge une page. Le component App est ce qui sera affich� puisqu'il est appel� par la fonction ReactDOM.render()

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

//import './css/calculator.css'


//import "materialize-css/dist/js/materialize.min.js";
//import "materialize-css/dist/css/materialize.min.css";
//import './css/assets.css'
//import './css/templatemo-digital-trend.css'
//import './css/owl.theme.default.min.css'
//import './css/owl.carousel.min.css'
//import './css/aos.css'
//import './css/font-awesome.min.css'
//import './css/bootstrap.min.css'
//import "./css/cttables.css";
//import './css/calculator.css';


ReactDOM.render(
    <React.StrictMode>
            <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default App;