//G�re les token pour le login et le register

import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
    GET_ERRORS,
    SET_CALCULATOR,
    SET_CURRENT_USER,
    USER_LOADING
} from "./types";


export const registerUser = (userData, history) => dispatch => {
    axios
        .post(getHostVar() + "/api/users/register", userData)
        .then(res => {
            console.log(res.data);
            const loginDatas = {
                email: userData.email,
                password: userData.password
            };
            axios
                .post(getHostVar() + "/api/users/login", loginDatas)
                .then(res => {
                    // Save to localStorage            
                    // Set token to localStorage
                    const { token } = res.data;
                    const { guid } = res.data;
                    localStorage.setItem("jwtToken", token);
                    localStorage.setItem("userGuid", guid);
                    // Set token to Auth header
                    setAuthToken(token);
                    // Decode token to get user data
                    const decoded = jwt_decode(token);
                    // Set current user
                    dispatch(setCurrentUser(decoded, guid));
                    console.log("wellll");
                    history.push("/myCalculators")
                })
                .catch(err => {
                    console.log(err);
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response.data
                    })
                }
                );


        }) 
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};


export const loginUser = userData => dispatch => {          
    axios
        .post(getHostVar() + "/api/users/login", userData) 
        .then(res => {                                     
            // Save to localStorage            
            // Set token to localStorage
            const { token } = res.data;
            const { guid } = res.data;
            localStorage.setItem("jwtToken", token);
            localStorage.setItem("userGuid", guid);
            // Set token to Auth header
            setAuthToken(token);
            // Decode token to get user data
            const decoded = jwt_decode(token);           
            // Set current user
            dispatch(setCurrentUser(decoded, guid));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
// Set logged in user
export const setCurrentUser = (decoded, guid) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
        guid : guid
    };
};
// User loading
export const setUserLoading = () => {
    return {
        type: USER_LOADING
    };
};

// Log user out
export const logoutUser = (history) => dispatch => {

    // Remove token from local storage
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("selectedCalculator");
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to empty object {} which will set isAuthenticated to false
    history.push("/login");
    dispatch(setCurrentUser({}));
};

export const setCalculator = calculator => {
    return {
        type: SET_CALCULATOR,
        payload: calculator
    };
};

function getHostVar() {
    return "";
    //return "http://localhost:5000";
}