import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { databasesActions, utils } from "../../actions/generalActions";
import queryString from 'query-string';

/*
import {
    Modal,
    ModalHeader,
    ModalClose,
    ModalBody,
    ModalFooter
} from "./react-modal-bootstrap/";

import Modal from "./react-modal-bootstrap/lib/Modal";
import ModalHeader from "./react-modal-bootstrap/lib/ModalHeader";
import ModalTitle from "./react-modal-bootstrap/lib/ModalTitle";
import ModalClose from "./react-modal-bootstrap/lib/ModalClose";
import ModalBody from "./react-modal-bootstrap/lib/ModalBody";
import ModalFooter from "./react-modal-bootstrap/lib/ModalFooter";


*/

var reactMB = require("react-modal-bootstrap");
var Modal = reactMB.Modal;
var ModalHeader = reactMB.ModalHeader;
var ModalTitle = reactMB.ModalTitle;
var ModalClose = reactMB.ModalClose;
var ModalBody = reactMB.ModalBody;
var ModalFooter = reactMB.ModalFooter;


    /*
var Modal = require("react-modal-bootstrap./react-modal-bootstrap/src/Modal");
var ModalHeader = require("./react-modal-bootstrap/src/ModalHeader");
var ModalTitle = require("./react-modal-bootstrap/src/ModalTitle");
var ModalClose = require("./react-modal-bootstrap/src/ModalClose");
var ModalBody = require("./react-modal-bootstrap/src/ModalBody");
var ModalFooter = require("./react-modal-bootstrap/src/ModalFooter");
*/


class AssetPage extends Component {


    constructor() {
        super();    

        this.state = {
            loadingState: "fetching",
            isFunctionInfoModalOpen: false,
            isConstantInfoModalOpen: false,
            isLoginRequestModalOpen: false,
            isErrorSubModalOpen: false,
            isErrorModalOpen: false,
            showing: "unset",
            indexCalcFuncToAdd: "0"
        }

        this.openFunctionInfo = this.openFunctionInfo.bind(this);
        this.openConstantInfo = this.openConstantInfo.bind(this);
        this.errorText = [];

    }


    componentDidMount() {
 
        if ((Object.keys(queryString.parse(this.props.location.search)).length != 1) && (Object.keys(queryString.parse(this.props.location.search)).length != 2)) {
            document.title = "404";
            this.setState({ loadingState: "error" });
        }

        else if ((Object.keys(queryString.parse(this.props.location.search)).length === 2) && ((((queryString.parse(this.props.location.search)).id) == null) ||
            ((queryString.parse(this.props.location.search)).f) == null)) {
            document.title = "404";
            this.setState({ loadingState: "error" });
        }
        else if ((Object.keys(queryString.parse(this.props.location.search)).length === 1) && (((queryString.parse(this.props.location.search)).id) == null)) {
            document.title = "404";
            this.setState({ loadingState: "error" });

        }
        else {

            var quer = queryString.parse(this.props.location.search).id;

            databasesActions.getCalculatorOfGuidAuthorFormat({ guid: quer }).then(res => {
                if (res.data.success) {
                    const { calculator } = res.data;
                    const { authorName } = res.data;
                    this.authorName = authorName;

                    if (calculator.isPublic == "true" && (Object.keys(queryString.parse(this.props.location.search)).length === 1)) {
                        this.calc = calculator;

                        this.likesAsset = []
                        var likeButton_ = <button class="notLikedAsset" onClick={this.addLikeCalculator}><i class="fa fa-heart "></i></button>;
                        if (this.props.auth.isAuthenticated && utils.UserLikeCalc(this.calc, this.props.auth.guid)) {
                            likeButton_ = <button class="likedAsset" onClick={this.removeLikeCalculator}><i class="fa fa-heart "></i></button>;
                        }
                        const likeButton = likeButton_;
                        const likeZone = <div class="myRowFlex likesAssetPageCenter">{likeButton} <div style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            textAlign: "center"
                        }}> {utils.getNumberOfLikesCalc(this.calc)}</div></div>;
                        this.likesAsset.push(likeZone);
                        this.numberOfLikes = utils.getNumberOfLikesCalc(this.calc);


                        const func = utils.getAllCustomFunctions(calculator);
                        this.modalFunc = [];
                        var index = 0
                        while (index < func.length) {
                            const funcKey = utils.getTextFrom(utils.getKeyFrom(func[index]));
                            const funcExp = utils.getTextFrom(utils.getExpressionFrom(func[index]));
                            const theFunc = func[index];
                            const info = <button className='btn btn-primary myBtnsBlue textContentAP' onClick={() => this.openFunctionInfo(theFunc)}>
                                <i class="fa fa-ellipsis-h"></i>
                            </button>


                            this.modalFunc.push(<div><div class="funcConstDiv"><div name="" class="funcConstNameAP textContentAP">{funcKey}</div>
                                <div name="" class="funcConstExpressionOrValueAP textContentAP">{funcExp}</div><div class="funcConstInfoAP textContentAP">{info}</div>
                            </div><hr class="myHRAPEl" /></div>)
                            index += 1;

                        }

                        var constants = utils.getAllCustomConstants(calculator);
                        this.modalConstants = [];
                        index = 0
                        while (index < constants.length) {
                            const constantsKey = utils.getTextFrom(utils.getKeyFrom(constants[index]));
                            const constantsValue = utils.getTextFrom(utils.getValueFrom(constants[index]));
                            const theConst = constants[index];
                            const info = <button className='btn btn-primary myBtnsBlue textContentAP' onClick={() => this.openConstantInfo(theConst)}>
                                <i class="fa fa-ellipsis-h"></i>
                            </button>

                            this.modalConstants.push(<div><div class="funcConstDiv"><div name="" class="funcConstNameAP textContentAP">{constantsKey}</div>
                                <div name="" class="funcConstExpressionOrValueAP textContentAP">{constantsValue}</div>
                                <div class="funcConstInfoAP textContentAP">{info}</div></div><hr class="myHRAPEl" /></div>)
                            index += 1;

                        }

                        document.title = utils.getTextFrom(this.calc.name);

                        this.setState({ loadingState: "loaded", showing: "calc" });

                    }

                    else if ((Object.keys(queryString.parse(this.props.location.search)).length === 2)) {
                        this.calc = calculator;
                        var querFunc = queryString.parse(this.props.location.search).f;
                        this.func = {};
                        var found = false;
                        for (var i = 0; i < this.calc.actions.length; i++) {
                            if (utils.getTextFrom(utils.getKeyFrom(this.calc.actions[i])) == querFunc) {
                                this.func = this.calc.actions[i];
                                found = true;
                                break;
                            }
                        }

                        if (found && utils.isFuncPublic(this.calc, this.func)) {
                            this.likesAsset = []
                            var likeButton_ = <button class="notLikedAsset" onClick={this.addLikeFunc}><i class="fa fa-heart "></i></button>;
                            if (this.props.auth.isAuthenticated && utils.UserLikeFunc(this.calc, this.func, this.props.auth.guid)) {
                                likeButton_ = <button class="likedAsset" onClick={this.removeLikeFunc}><i class="fa fa-heart "></i></button>;
                            }
                            const likeButton = likeButton_;
                            const likeZone = <div class="myRowFlex likesAssetPageCenter">{likeButton} <div style={{
                                marginLeft: "15px",
                                marginRight: "15px",
                                textAlign: "center"
                            }}> {utils.getNumberOfLikesFunc(this.calc, this.func)}</div></div>;
                            this.likesAsset.push(likeZone);
                            this.numberOfLikes = utils.getNumberOfLikesFunc(this.calc, this.func);

                            document.title = utils.getTextFrom(utils.getKeyFrom(this.func));

                            this.setState({ loadingState: "loaded", showing: "func" });
                        }

                        else {
                            document.title = "404";
                            this.setState({ loadingState: "error" });
                        }
                    }

                    if (this.props.auth.isAuthenticated) {
                        databasesActions.setAllUserCalculators({ authorGuid: this.props.auth.guid }).
                            then(res => {
                                const { allUserCalculators } = res.data;
                                this.allCalculators = allUserCalculators;

                                this.calcsListModal = [];
                                index = 0
                                while (index < this.allCalculators.length) {
                                    const calcName = utils.getTextFrom(this.allCalculators[index].name);

                                    if (index == 0) {
                                        this.calcsListModal.push(<div><div class="funcConstDiv">
                                            <input class="radioDefault calcSelectRadio" type="radio" id={"" + index} name="selectCalc" onClick={this.selectCalc} defaultChecked />
                                            <div name="" class="calcSelectName">{calcName}</div>
                                        </div><hr class="myHRCF" /></div>)
                                    }
                                    else {
                                        this.calcsListModal.push(<div><div class="funcConstDiv">
                                            <input class="radioDefault calcSelectRadio" type="radio" id={"" + index} name="selectCalc" onClick={this.selectCalc} />
                                            <div name="" class="calcSelectName">{calcName}</div>
                                        </div><hr class="myHRCF" /></div>)
                                    }
                                    index += 1;

                                }

                            })
                            .catch(err => {
                                document.title = "502";
                                console.log(err)

                            });
                    }

                }

                else {
                    document.title = "404";
                    this.setState({ loadingState: "error" });
                }

            })
                .catch(err => {
                    document.title = "404";
                    this.setState({ loadingState: "error" });
                });



        }
        
    }

    showAlertSuccess = (message, area) => {
        var alertbox = new AlertBox(area, {
            closeTime: 3500,
            persistent: false,
            hideCloseButton: false
        }, true);

        alertbox.show(message);
    }


    showAlertError = (message, area) => {
        var alertbox = new AlertBox(area, {
            closeTime: 3500,
            persistent: false,
            hideCloseButton: false
        }, false);

        alertbox.show(message);
    }

    selectCalc = (e) => {

        this.setState({
            indexCalcFuncToAdd: e.target.id
        });
    };

    openConstantInfo = (theConstant) => {

        this.constantInfo = []

        const header = <ModalHeader>
            <h4 class="modal-title centerEl paddingModalTitle">{utils.getTextFrom(utils.getKeyFrom(theConstant))}</h4>
            <ModalClose onClick={this.hideConstantInfo} />
        </ModalHeader>;

        const value = <div class="infoPanel"><div class="titleInfoPanel">Value</div>
            <div class="contentInfoPanel">{utils.getTextFrom(utils.getValueFrom(theConstant))}</div></div>

        const description = <div class="infoPanel"><div class="titleInfoPanel">Description</div>
            <div class="contentInfoPanel">{utils.getTextFrom(utils.getDescriptionFrom(theConstant))}</div></div>

        const modalBodyFooter = <React.Fragment><ModalBody>
            {value}<hr class="myHRInfo" />{description}
        </ModalBody>
            <ModalFooter>
                <button className='btn btn-default myBtns' onClick={this.hideConstantInfo}>Close</button>
            </ModalFooter></React.Fragment>

        const theModal = <React.Fragment>{header}{modalBodyFooter}</React.Fragment>

        this.constantInfo.push(theModal);




        this.setState({
            isConstantInfoModalOpen: true
        });
    };

    hideConstantInfo = () => {
        this.setState({
            isConstantInfoModalOpen: false
        });
    };

    addLikeFunc = () => {

        if (this.props.auth.isAuthenticated) {
            this.likesAsset = []
            const likeButton = <button class="likedAsset" onClick={this.removeLikeFunc}><i class="fa fa-heart "></i></button>;
            this.numberOfLikes += 1;
            const likeZone = <div class="myRowFlex likesAssetPageCenter">{likeButton} <div style={{
                marginLeft: "15px",
                marginRight: "15px",
                textAlign: "center"
            }}> {this.numberOfLikes}</div></div>;
            this.likesAsset.push(likeZone);
            this.setState({ loadingState: this.state.loadingState })

            databasesActions.addLikeFunc({ calculatorGuid: this.calc.guid, userGuid: this.props.auth.guid, funcKey: utils.getKeyFrom(this.func) }).then(res => {

            }).catch(err => {
                console.log(err)
            });
        }
        else {
            this.setState({ isLoginRequestModalOpen: true })
        }
    };

    removeLikeFunc = () => {

        this.likesAsset = []
        const likeButton = <button class="notLikedAsset" onClick={this.addLikeCFunc}><i class="fa fa-heart "></i></button>;
        this.numberOfLikes -= 1;
        const likeZone = <div class="myRowFlex likesAssetPageCenter">{likeButton} <div style={{
            marginLeft: "15px",
            marginRight: "15px",
            textAlign: "center"
        }}> {this.numberOfLikes}</div></div>;
        this.likesAsset.push(likeZone);
        this.setState({ loadingState: this.state.loadingState })

        databasesActions.removeLikeFunc({ calculatorGuid: this.calc.guid, userGuid: this.props.auth.guid, funcKey: utils.getKeyFrom(this.func) }).then(res => {

        }).catch(err => {
            console.log(err)
        });
    };

    addLikeCalculator = () => {

        if (this.props.auth.isAuthenticated) {
            this.likesAsset = []
            const likeButton = <button class="likedAsset" onClick={this.removeLikeCalculator}><i class="fa fa-heart "></i></button>;
            this.numberOfLikes += 1;
            const likeZone = <div class="myRowFlex likesAssetPageCenter">{likeButton} <div style={{
                marginLeft: "15px",
                marginRight: "15px",
                textAlign: "center"
            }}> {this.numberOfLikes}</div></div>;
            this.likesAsset.push(likeZone);
            this.setState({ loadingState: this.state.loadingState})

            databasesActions.addLikeCalculator({ calculatorGuid: this.calc.guid, userGuid: this.props.auth.guid}).then(res => {

            }).catch(err => {

            });
        }
        else {
            this.setState({ isLoginRequestModalOpen: true})
        }
    };

    removeLikeCalculator = () => {

        this.likesAsset = []
        const likeButton = <button class="notLikedAsset" onClick={this.addLikeCalculator}><i class="fa fa-heart "></i></button>;
        this.numberOfLikes -= 1;
        const likeZone = <div class="myRowFlex likesAssetPageCenter">{likeButton} <div style={{
            marginLeft: "15px",
            marginRight: "15px",
            textAlign: "center"
        }}> {this.numberOfLikes}</div></div>;
        this.likesAsset.push(likeZone);
        this.setState({ loadingState: this.state.loadingState })

        databasesActions.removeLikeCalculator({ calculatorGuid: this.calc.guid, userGuid: this.props.auth.guid }).then(res => {

        }).catch(err => {

        });
    };

    openFunctionInfo = (theFunction) => {

        this.functionInfo = []

        const header = <ModalHeader>
            <h4 class="modal-title centerEl paddingModalTitle">{utils.getTextFrom(utils.getKeyFrom(theFunction))}</h4>
            <ModalClose onClick={this.hideFunctionInfo} />
        </ModalHeader>;

        const parametrs = <div class="infoPanel"><div class="titleInfoPanel">Parameters</div>
            <div class="contentInfoPanel">{utils.decodeParams(utils.getParamsFrom(theFunction))}</div></div>

        const expression = <div class="infoPanel"><div class="titleInfoPanel">Expression</div>
            <div class="contentInfoPanel">{utils.getTextFrom(utils.getExpressionFrom(theFunction))}</div></div>

        const description = <div class="infoPanel"><div class="titleInfoPanel">Description</div>
            <div class="contentInfoPanel">{utils.getTextFrom(utils.getDescriptionFrom(theFunction))}</div></div>

        const modalBodyFooter = <React.Fragment><ModalBody>
            {parametrs}<hr class="myHRInfo" />{expression}<hr class="myHRInfo" />{description}
        </ModalBody>
            <ModalFooter>
                <button className='btn btn-default myBtns' onClick={this.hideFunctionInfo}>Close</button>
            </ModalFooter></React.Fragment>

        const theModal = <React.Fragment>{header}{modalBodyFooter}</React.Fragment>

        this.functionInfo.push(theModal);


                                                        
                                                
        this.setState({
            isFunctionInfoModalOpen: true
        });
    };

    hideFunctionInfo = () => {
        this.setState({
            isFunctionInfoModalOpen: false
        });
    };

    closeLoginRequestModal = () => {
        this.setState({
            isLoginRequestModalOpen: false
        });
    };

    confirmAddFunc = () => {
        const theCalculator = this.allCalculators[parseInt(this.state.indexCalcFuncToAdd)];
        var actions = theCalculator.actions;

        for (var i = 0; i < actions.length; i++) {
            if (utils.getKeyFrom(actions[i]) == utils.getKeyFrom(this.func)) {
                this.errorText = [];
                this.errorText.push(
                    <div style={{
                    margin: "25px",
                    display: "flex",
                    alignItems: "center",
                        justifyContent: "space-around",
                        flexDirection: "column"
                    }}>
                        <div style={{ color: "red" }}>Error</div><br />
                        {"A function or constant named " + utils.getTextFrom(utils.getKeyFrom(this.func)) + " is already present in this calculator"}</div>)
                this.setState({ isErrorSubModalOpen: true });
                return;
            }
        }

        actions.push(this.func);
        theCalculator.actions = actions;
        this.setState({ isAddFuncModalOpen: false });

        databasesActions.createNewAction({
            calculatorGuid: theCalculator.guid,
            type: "func",
            params: utils.getParamsFrom(this.func),
            expressionOrValue: utils.getExpressionFrom(this.func),
            special: false,
            key: utils.getKeyFrom(this.func),
            description: utils.getDescriptionFrom(this.func)
        }).then(res => {

            if (res.errorHasOccurred != null) {
                for (var i = 0; i < actions.length; i++) {
                    if (utils.getKeyFrom(actions[i]) == utils.getKeyFrom(this.func)) {
                        actions.splice(i, 1);
                        theCalculator.actions = actions;
                        break;
                    }
                }

                this.showAlertError("An error has occurred. Please try again.", "#alert-areaModal");
            }
            else {
                this.showAlertSuccess("Function successfully added", "#alert-areaModal");
            }


        }).catch(err => {

            for (var i = 0; i < actions.length; i++) {
                if (utils.getKeyFrom(actions[i]) == utils.getKeyFrom(this.func)) {
                    actions.splice(i, 1);
                    theCalculator.actions = actions;
                    break;
                }
            }
            this.showAlertError("An error has occurred. Please try again.", "#alert-areaModal");

            console.log(err);
            });
        

    };

    importCalculator = () => {
        if (this.props.auth.isAuthenticated) {

            var allCalculators = this.allCalculators;
            for (var i = 0; i < allCalculators.length; i++) {
                if (allCalculators[i].originalGuid == this.calc.guid) {
                    this.errorText = [];
                    this.errorText.push(
                        <div style={{
                            margin: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            flexDirection: "column"
                        }}>
                            <div style={{ color: "red" }}>Error</div><br />
                            {"You have already added this calculator"}</div>)
                    this.setState({ isErrorModalOpen: true });
                    return;
                }
            }

            allCalculators.push({ originalGuid: this.calc.guid })
            this.allCalculators = allCalculators;

            databasesActions.importCalculator({
                calculatorGuid: this.calc.guid,
                userWhoImportGuid: this.props.auth.guid,
            }).then(res => {

                if (res.errorHasOccurred != null) {
                    for (var i = 0; i < allCalculators.length; i++) {
                        if (allCalculators.originalGuid == this.calc.guid) {
                            allCalculators.splice(i, 1);
                            this.allCalculators = allCalculators;
                            break;
                        }
                    }

                    this.showAlertError("An error has occurred. Please try again.", "#alert-areaModal");
                }
                else {
                    this.showAlertSuccess("Calculator successfully added", "#alert-areaModal");
                }


            }).catch(err => {

                for (var i = 0; i < allCalculators.length; i++) {
                    if (allCalculators.originalGuid == this.calc.guid) {
                        allCalculators.splice(i, 1);
                        this.allCalculators = allCalculators;
                        break;
                    }
                }
                this.showAlertError("An error has occurred. Please try again.", "#alert-areaModal");
            });
        }
        else {
            this.setState({ isLoginRequestModalOpen: true });
        }
    };

    useCalculator = () => {

        var calcDatas = {};

        if (this.props.auth.isAuthenticated && this.props.auth.guid == this.calc.authorGuid) {
            calcDatas = this.calc;
        }
        else {
            calcDatas = {
                name: this.calc.name,
                guid: "-used",
                description: this.calc.description,
                authorGuid: this.calc.authorGuid,
                isPublic: "false",
                originalGuid: this.calc.originalGuid,
                publicationDatas: {
                    listOfLikes: "",
                    listOfLikesFunc: "",
                    updated: ""
                },
                actions: this.calc.actions
            }

        }

        localStorage.setItem("selectedCalculator", JSON.stringify(calcDatas));
        window.location.href = "/";
    }

    addFunc = () => {
        if (this.props.auth.isAuthenticated) {
            this.setState({ isAddFuncModalOpen: true });
        }
        else {
            this.setState({ isLoginRequestModalOpen: true });
        }
    };

    cancelAddFunc = () => {
        this.setState({ isAddFuncModalOpen: false }); 
    };

    closeErrorSubModal = () => {
        this.setState({ isErrorSubModalOpen: false });
    };

    closeErrorModal = () => {
        this.setState({ isErrorModalOpen: false });
    };
    
    render() {         

        let subModalDialogStyles = {
            base: {
                bottom: -600,
                transition: 'bottom 0.4s'
            },
            open: {
                bottom: 0
            }
        };

        return (
          
            <div class="assetsStyle">
                {(() => {
                    if (this.state.loadingState == "fetching") {
                        return (
                            <div style={{ height: "600px", display: "flex", alignItems: "center", justifyContent: "space-around", fontSize: "16px", color: "white" }}>Fetching data...</div>
                        )
                    } else {
                        if (this.state.loadingState == "loaded" && this.state.showing == "calc") {
                            return (
                                <div>
                                    <div class="APdatatable-container">
                                        <div class="myRowFlex">
                                            <div class="likeContainerAsset">{this.likesAsset}</div>
                                            <div class="headerAP">{utils.getTextFrom(this.calc.name)}</div>
                                            <div class="headerAPBut">
                                                <button class="centerEl btn btn-default myBtnStyleBlue" onClick={this.importCalculator}>Add to My Calculators</button>
                                                <button class="centerEl btn btn-default myBtnStyleBlue" onClick={this.useCalculator}>Use</button>
                                            </div>
                                        </div>
                                        <hr class="myHRAP" />

                                        <div class="sectionAP">
                                            <div class="titleSectionAP">
                                                Description
                                                    </div>
                                            <div class="contentSectionAP textContentAP">
                                                {utils.getTextFrom(this.calc.description)}
                                            </div>
                                        </div >
                                        <hr class="myHRAP" />


                                        <div class="sectionAP">
                                            <div class="titleSectionAP">
                                                Functions
                                                    </div>
                                            <div class="contentSectionAP">
                                                <div class="listAP">{this.modalFunc}</div>
                                            </div>
                                        </div>
                                        <hr class="myHRAP" />

                                        <div class="sectionAP">
                                            <div class="titleSectionAP">
                                                Constants
                                                    </div>
                                            <div class="contentSectionAP">
                                                <div class="listAP">{this.modalConstants}</div>
                                            </div>
                                        </div>
                                        <hr class="myHRAP" />

                                        <div class="authorAP">{"By "}{this.authorName}</div>
                                    </div>


                                    <Modal isOpen={this.state.isFunctionInfoModalOpen} size='modal-lg' onRequestHide={this.hideFunctionInfo}>
                                        {this.functionInfo}
                                    </Modal>

                                    <Modal isOpen={this.state.isConstantInfoModalOpen} size='modal-lg' onRequestHide={this.hideConstantInfo}>
                                        {this.constantInfo}
                                    </Modal>

                                    <Modal isOpen={this.state.isLoginRequestModalOpen} size='modal-lg' onRequestHide={this.closeLoginRequestModal}>
                                        <ModalBody>
                                            <ModalClose onClick={this.closeLoginRequestModal} />
                                            <div style={{
                                                margin: "25px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-around"
                                            }}>You need to login before liking a calculator/function or adding a calculator/function</div>
                                        </ModalBody>
                                    </Modal>

                                    <Modal isOpen={this.state.isErrorModalOpen} onRequestHide={this.closeErrorModal} dialogStyles={subModalDialogStyles}>
                                        <ModalBody>
                                            <ModalClose onClick={this.closeErrorModal} />
                                            {this.errorText}
                                        </ModalBody>
                                    </Modal>

                                </div>


                            )
                        }





                        else if (this.state.loadingState == "loaded" && this.state.showing == "func") {
                            return (
                                <div>
                                    <div class="APdatatable-container">
                                        <div class="myRowFlex">
                                            <div class="likeContainerAsset">{this.likesAsset}</div>
                                            <div class="headerAP">{utils.getTextFrom(utils.getKeyFrom(this.func))}</div>
                                            <div class="headerAPBut">
                                                <button class="centerEl btn btn-default myBtnStyleBlue" onClick={this.addFunc}>Add</button>
                                            </div>
                                        </div>
                                        <hr class="myHRAP" />

                                        <div class="sectionAP">
                                            <div class="titleSectionAP">
                                                Parameters
                                                    </div>
                                            <div class="contentSectionAP textContentAP">
                                                {utils.decodeParams(utils.getParamsFrom(this.func))}
                                            </div>
                                        </div >
                                        <hr class="myHRAP" />

                                        <div class="sectionAP">
                                            <div class="titleSectionAP">
                                                Expression
                                                    </div>
                                            <div class="contentSectionAP textContentAP">
                                                {utils.getTextFrom(utils.getExpressionFrom(this.func))}
                                            </div>
                                        </div >
                                        <hr class="myHRAP" />


                                        <div class="sectionAP">
                                            <div class="titleSectionAP">
                                                Description
                                                    </div>
                                            <div class="contentSectionAP textContentAP">
                                                {utils.getTextFrom(utils.getDescriptionFrom(this.func))}
                                            </div>
                                        </div >
                                        <hr class="myHRAP" />

                                        <div class="authorAP">{"By "}{this.authorName}</div>
                                    </div>

                                    <Modal isOpen={this.state.isLoginRequestModalOpen} size='modal-lg' onRequestHide={this.closeLoginRequestModal}>
                                        <ModalBody>
                                            <ModalClose onClick={this.closeLoginRequestModal} />
                                            <div style={{
                                                margin: "25px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-around"
                                            }}>You need to login before liking a calculator/function or adding a calculator/function</div>
                                        </ModalBody>
                                    </Modal>


                                    <Modal isOpen={this.state.isAddFuncModalOpen} size='modal-lg' onRequestHide={this.cancelAddFunc}>
                                        <ModalHeader>
                                            <h4 class="modal-title centerEl">Select calculator</h4>
                                            <ModalClose onClick={this.cancelAddFunc} />
                                        </ModalHeader>
                                        <ModalBody>
                                            <hr />
                                            <div>{this.calcsListModal}</div>


                                            <Modal isOpen={this.state.isErrorSubModalOpen} onRequestHide={this.closeErrorSubModal} dialogStyles={subModalDialogStyles}>
                                                <ModalBody>
                                                    <ModalClose onClick={this.closeErrorSubModal} />
                                                    {this.errorText}
                                                </ModalBody>
                                            </Modal>

                                        </ModalBody>
                                        <ModalFooter>
                                            <button className='btn btn-default myBtns' onClick={this.confirmAddFunc}>
                                                Confirm
                </button>
                                            <button className='btn btn-default myBtns' onClick={this.cancelAddFunc}>
                                                Cancel
                </button>
                                        </ModalFooter>
                                    </Modal>
                                </div>


                            )
                        }





                        else {
                            return (
                                <div
                                    style={{
                                        height: "600px", display: "flex", alignItems: "center", justifyContent: "space-around", fontSize: "16px", color: "white"
                                    }}>Error loading the page. Either the asset you want to view is missing or deleted, or the address entered is incorrect</div>
                            )
                        }






                                    
                    }
                })()}

                <div id="alert-areaModal" class="alert-area"></div>
            </div>


        );
        
    }
}





AssetPage.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(
    mapStateToProps,
    {}
)(AssetPage);

var AlertBox = function (id, option, success) {
    this.show = function (msg) {
        if (msg === '' || typeof msg === 'undefined' || msg === null) {
            throw '"msg parameter is empty"';
        }
        else {
            console.log(id);
            var alertArea = document.querySelector(id);
            var alertBox = document.createElement('DIV');
            var alertContent = document.createElement('DIV');
            var alertClose = document.createElement('A');
            var alertClass = this;
            alertContent.classList.add('alert-content');
            alertContent.innerText = msg;
            alertClose.classList.add('alert-close');
            if (success) {
                alertBox.classList.add('alert-box-green');
            }
            else {
                alertBox.classList.add('alert-box-red');
            }
            alertBox.appendChild(alertContent);
            if (!option.hideCloseButton || typeof option.hideCloseButton === 'undefined') {
                alertBox.appendChild(alertClose);
            }
            alertArea.appendChild(alertBox);
            alertClose.addEventListener('click', function (event) {
                event.preventDefault();
                alertClass.hide(alertBox);
            });
            if (!option.persistent) {
                var alertTimeout = setTimeout(function () {
                    alertClass.hide(alertBox);
                    clearTimeout(alertTimeout);
                }, option.closeTime);
            }
        }
    };

    this.hide = function (alertBox) {
        alertBox.classList.add('hide');
        var disperseTimeout = setTimeout(function () {
            if (alertBox.parentNode != null) {
                alertBox.parentNode.removeChild(alertBox);
                clearTimeout(disperseTimeout);
            }

        }, 500);
    };
};