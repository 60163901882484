import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { databasesActions, utils } from "../../actions/generalActions";
import queryString from 'query-string';


class Assets extends Component {


    constructor() {
        super();    

        this.goToPage = this.goToPage.bind(this);

        this.state =
        {
            fetching: true,
            showing: "calc",
            searchField: "",
            numberByPage: 10
        }
    }

    sortSearch = () => {
        if (!(Object.keys(queryString.parse(this.props.location.search)).length === 0)) {
            if (!(queryString.parse(this.props.location.search).lookFor == null)) {
                var quer = queryString.parse(this.props.location.search).lookFor;
                var keywords_ = quer.split(" ");
                var keywords = [];
                for (var i = 0; i < keywords_.length; i++) {
                    keywords[i] = keywords_[i].trim();
                }


                for (var i = 0; i < this.rowsCalcs.length; i++)
                {
                    var rowAct_ = this.calcsName[i].split(" ");
                    var rowAct = [];
                    for (var j = 0; j < rowAct_.length; j++) {
                        rowAct[j] = rowAct_[j].trim();
                    }

                    var containing = true;
                    for (var j = 0; j < keywords.length; j++) {
                        if (!(rowAct.includes(keywords[j]))) {
                            containing = false;
                            break;
                        }
                    }
                    if (containing) {
                        this.calcsSorted.push(this.rowsCalcs[i])
                    }
                }

                for (var i = 0; i < this.rowsFuncs.length; i++) {
                    var rowAct_ = this.funcName[i].split(" ");
                    var rowAct = [];
                    for (var j = 0; j < rowAct_.length; j++) {
                        rowAct[j] = rowAct_[j].trim();
                    }

                    var containing = true;
                    for (var j = 0; j < keywords.length; j++) {
                        if (!(rowAct.includes(keywords[j]))) {
                            containing = false;
                            break;
                        }
                    }
                    if (containing) {
                        this.funcSorted.push(this.rowsFuncs[i])
                    }
                }

            }
        }
    }

    showOnlyCalcs = () => {
        this.setState({ showing: "calc" })
        this.goToPage(1);
    }

    showOnlyFuncs = () => {
        this.setState({ showing: "func" })
                this.goToPage(1);
    }

    componentDidMount() {

        document.title = "Assets";

        this.allUserCalculators = {};

        this.rowsCalcs = [];
        this.rowsFuncs = [];

        this.calcsName = [];
        this.funcName = [];

        this.calcsSorted = [];
        this.funcSorted = [];

        this.calcsToShow = [];
        this.funcsToShow = [];

        this.pagesCalcToShow = [];
        this.pagesFuncsToShow = [];

        this.calcPagesNums = []
        this.funcPagesNums = []

        databasesActions.GetAllPublicCalculatorsAuthorFormat().then(res => {

            const { allPublicCalculators } = res.data;
            var allPublicCalculatorsNoAuthor = []

            for (var k = 0; k < allPublicCalculators.length; k++) {
                allPublicCalculatorsNoAuthor.push(allPublicCalculators[k].calculator)
            }
            //console.log(allPublicCalculators);
            //console.log(allPublicCalculatorsNoAuthor);

            for (var i = 0; i < allPublicCalculatorsNoAuthor.length; i++) {
                const calc = allPublicCalculatorsNoAuthor[i];

                const numberOfLikes = utils.getNumberOfLikesCalc(calc);
                const likes = <React.Fragment><td class="tdLikesAssets"><div><i class="fa fa-heart likeMargin"></i>{numberOfLikes}</div></td></React.Fragment>

                const calcLink = "assetPage?id=" + calc.guid;
                var calcNameText = utils.getTextFrom(calc.name);
                calcNameText = utils.minify(calcNameText, 50); 
                const calcName = <React.Fragment><td class="tdNameCalcAssets"><a class="myHref" href={calcLink}>{calcNameText}</a></td></React.Fragment>

                var description = utils.getTextFrom(calc.description);
                description = utils.minify(description, 170);                
                const calcDesc = <React.Fragment><td class="tdDescriptionCalcAssets">{description}</td></React.Fragment>

                const calcAuthorNameText = "By " + allPublicCalculators[i].authorName;
                const calcAuthor = <React.Fragment><td class="tdAuthorCalcAssets authorAssets">{calcAuthorNameText}</td></React.Fragment>

                const calcRender = <React.Fragment><tr>
                    {likes}{calcName}{calcDesc}{calcAuthor}
                </tr></React.Fragment>

                this.rowsCalcs.push(calcRender);
                this.calcsName.push(calcNameText);

            }

            databasesActions.GetAllPublicFunctionsAuthorFormat().
                then(res => {
                    const { allPublicFunctions } = res.data;

                    for (var i = 0; i < allPublicFunctions.length; i++) {

                        const func = allPublicFunctions[i].action;
                        const calcF = allPublicFunctions[i].calc;
                        const authorName = allPublicFunctions[i].authorName;

                        const numberOfLikesF = utils.getNumberOfLikesFunc(calcF, func);
                        const likesF = <React.Fragment><td class="tdLikesAssets"><div><i class="fa fa-heart likeMargin"></i>{numberOfLikesF}</div></td></React.Fragment>

                        var funcKeyText_ = utils.getKeyFrom(func);
                        const funcLink = "assetPage?id=" + calcF.guid + "&f=" + utils.getTextFrom(funcKeyText_);
                        funcKeyText_ = utils.getTextFrom(funcKeyText_);
                        const funcKeyText = utils.minify(funcKeyText_, 40); 

                        const funcKey = <React.Fragment><td class="tdNameFuncAssets"><a class="myHref" href={funcLink}>{funcKeyText}</a></td></React.Fragment>

                        var funcDescText_ = utils.getDescriptionFrom(func);
                        funcDescText_ = utils.getTextFrom(funcDescText_);
                        const funcDescText = utils.minify(funcDescText_, 145); 

                        const funcDesc = <React.Fragment><td class="tdDescriptionFuncAssets">{funcDescText}</td></React.Fragment>

                        var funcExpressionText_ = utils.getExpressionFrom(func);
                        funcExpressionText_ = utils.getTextFrom(funcExpressionText_);
                        const funcExpressionText = utils.minify(funcExpressionText_, 60); 

                        const funcExpression = <React.Fragment><td class="tdExpressionFuncAssets">{funcExpressionText}</td></React.Fragment>

                        var funcAuthorNameText = "By " + authorName;

                        const funcAuthor = <React.Fragment><td class="tdAuthorFuncAssets authorAssets">{funcAuthorNameText}</td></React.Fragment>

                        const calcRenderF = <React.Fragment><tr>
                            {likesF}{funcKey}{funcExpression}{funcDesc}{funcAuthor}
                        </tr></React.Fragment>

                        this.rowsFuncs.push(calcRenderF);
                        this.funcName.push(funcKeyText);
                    }

                    this.sortSearch();
                    this.headerSort = "";
                    if (!(Object.keys(queryString.parse(this.props.location.search)).length === 0)) {
                        if (!(queryString.parse(this.props.location.search).lookFor == null)) {

                            this.allCalcsToShow = this.calcsSorted;
                            this.allFuncsToShow = this.funcSorted;

                            this.headerSort = "headerSortPart";

                            var textEl = "calculators"
                            if (this.allCalcsToShow.length == 1) {
                                textEl = "calculator"
                            }
                            const resultTextCalc = this.allCalcsToShow.length + " " + textEl + " found";
                            this.headerResultsCalc = <div class="headerSortPart headerResultSearch">{resultTextCalc}</div>

                            textEl = "functions"
                            if (this.allFuncsToShow.length == 1) {
                                textEl = "function"
                            }
                            const resultTextFunc = this.allFuncsToShow.length + " " + textEl + " found";
                            this.headerResultsFunc = <div class="headerSortPart headerResultSearch">{resultTextFunc}</div>

                                
                        }
                    }

                    else {
                        this.allCalcsToShow = this.rowsCalcs;
                        this.allFuncsToShow = this.rowsFuncs;
                        this.headerSort = "headerSortTot";
                    }

                    var inversedCalcs = [];
                    var inversedFuncs = [];

                    for (var v = this.allCalcsToShow.length - 1; v >= 0; v--) {
                        inversedCalcs.push(this.allCalcsToShow[v])
                    }

                    for (var v = this.allFuncsToShow.length - 1; v >= 0; v--) {
                        inversedFuncs.push(this.allFuncsToShow[v])
                    }

                    this.allCalcsToShow = inversedCalcs;
                    this.allFuncsToShow = inversedFuncs;


                    this.goToPage(1);

                    this.setState({ fetching: false }); 
                })
                .catch(err =>
                    console.log(err)
                );


        })
            .catch(err =>
                console.log(err)
            );

        /*
        this.sortSearch();

        */
        //console.log(this.rowsCalcs);

        
    }

    confirmSearch = (e) => {
        if (e.key === 'Enter') {
            if (this.state.searchField.trim() != "") {
                window.location.href = "?lookFor=" + this.state.searchField;
            }
        }
    }

    confirmSearchBut = () => {
        if (this.state.searchField.trim() != "") {
            window.location.href = "?lookFor=" + this.state.searchField;
        }
    }

    searchBarChanged = (e) => {
        this.state.searchField = e.target.value;
    }

    goToPage = (pageAct) => {
        this.updatePagesNums(pageAct);

        this.funcsToShow = [];

        const minEl = this.state.numberByPage * (pageAct - 1);
        //console.log(minEl);
        //console.log(this.allCalcsToShow.length);
        for (var i = minEl; i < this.state.numberByPage + minEl; i++) {
            if (i == this.allFuncsToShow.length) {
                break;
            }
            this.funcsToShow.push(this.allFuncsToShow[i]);
        }

        this.calcsToShow = [];

        for (var i = minEl; i < this.state.numberByPage + minEl; i++) {
            if (i == this.allCalcsToShow.length) {
                break;
            }
            this.calcsToShow.push(this.allCalcsToShow[i]);
        }

        //console.log(this.calcsToShow);
        //console.log(this.funcsToShow);

        this.setState({ fetching: false });

    }

    updatePagesNums = (pageAct) => {
        const n = this.state.numberByPage;

        var nPagesFunc = (this.allFuncsToShow.length / n) | 0;
        if ((this.allFuncsToShow.length % n) != 0) {
            nPagesFunc += 1;
        }

        var funcPagesNumsEls = [];
        for (var i = 1; i <= nPagesFunc; i++) {
            const iConst = i;
            if (pageAct == i) {
                const lineIt = <li><span class="active">{i}</span></li>
                funcPagesNumsEls.push(lineIt);
            }
            else {
                if (pageAct == i + 1) {
                    const lineIt = <li><button onClick={() => this.goToPage(iConst)}>{i}</button></li>
                    funcPagesNumsEls.push(lineIt);

                    if (i != 1) {
                        if (i > 2) {
                            const lineFirst = <li><button onClick={() => this.goToPage(1)}>1</button></li>
                            const pointsSep = <li><span>...</span></li>
                            funcPagesNumsEls.splice(0, 0, pointsSep);
                            funcPagesNumsEls.splice(0, 0, lineFirst);
                        }
                        else {
                            const lineFirst = <li><button onClick={() => this.goToPage(1)}>1</button></li>
                            funcPagesNumsEls.splice(0, 0, lineFirst);
                        }
                    }

                }
                else if (pageAct == i - 1) {
                    const lineIt = <li><button onClick={() => this.goToPage(iConst)}>{i}</button></li>
                    funcPagesNumsEls.push(lineIt);

                    if (i != nPagesFunc) {
                        if (nPagesFunc - i > 1) {
                            const lineLast = <li><button onClick={() => this.goToPage(nPagesFunc)}>{nPagesFunc}</button></li>
                            const pointsSep = <li><span>...</span></li>
                            funcPagesNumsEls.push(pointsSep);
                            funcPagesNumsEls.push(lineLast);
                        }
                        else {
                            const lineLast = <li><button onClick={() => this.goToPage(nPagesFunc)}>{nPagesFunc}</button></li>
                            funcPagesNumsEls.push(lineLast);
                        }
                    }
                    break;
                }
            }

        }

        this.funcPagesNums = []
        this.funcPagesNums.push(<div class="pages"><ul>{funcPagesNumsEls}</ul></div >)








        var nPagesCalc = (this.allCalcsToShow.length / n) | 0;
        if ((this.allCalcsToShow.length % n) != 0) {
            nPagesCalc += 1;
        }

        var calcsPagesNumsEls = [];
        for (var i = 1; i <= nPagesCalc; i++) {
            const iConst = i;
            if (pageAct == i) {
                const lineIt = <li><span class="active">{i}</span></li>
                calcsPagesNumsEls.push(lineIt);
            }
            else {
                if (pageAct == i + 1) {
                    const lineIt = <li><button onClick={() => this.goToPage(iConst)}>{i}</button></li>
                    calcsPagesNumsEls.push(lineIt);

                    if (i != 1) {
                        if (i > 2) {
                            const lineFirst = <li><button onClick={() => this.goToPage(1)}>1</button></li>
                            const pointsSep = <li><span>...</span></li>
                            calcsPagesNumsEls.splice(0, 0, pointsSep);
                            calcsPagesNumsEls.splice(0, 0, lineFirst);
                        }
                        else {
                            const lineFirst = <li><button onClick={() => this.goToPage(1)}>1</button></li>
                            calcsPagesNumsEls.splice(0, 0, lineFirst);
                        }
                    }

                }
                else if (pageAct == i - 1) {
                    const lineIt = <li><button onClick={() => this.goToPage(iConst)}>{i}</button></li>
                    calcsPagesNumsEls.push(lineIt);

                    if (i != nPagesCalc) {
                        if (nPagesCalc - i > 1) {
                            const lineLast = <li><button onClick={() => this.goToPage(nPagesCalc)}>{nPagesCalc}</button></li>
                            const pointsSep = <li><span>...</span></li>
                            calcsPagesNumsEls.push(pointsSep);
                            calcsPagesNumsEls.push(lineLast);
                        }
                        else {
                            const lineLast = <li><button onClick={() => this.goToPage(nPagesCalc)}>{nPagesCalc}</button></li>
                            calcsPagesNumsEls.push(lineLast);
                        }
                    }
                    break;
                }
            }

        }

        this.calcPagesNums = []
        this.calcPagesNums.push(<div class="pages"> <ul>{calcsPagesNumsEls}</ul></div>);

    }

    showDDChanged = (e) => {
        this.state.numberByPage = parseInt(e.target.value);
        this.goToPage(1);
    }
    
    render() {         
      
        return (
          
            <div class="assetsStyle">


                {(() => {
                    if (this.state.fetching) {
                        return (
                            <div style={{ height: "600px", display: "flex", alignItems: "center", justifyContent: "space-around", fontSize: "16px", color: "white"}}>Fetching data...</div>
                        )
                    } else {
                                if (this.state.showing == "func") {
                                    return (
                                        <div>
                                        <div class="datatable-container">
                                            <div class="header-tools">
                                                {this.headerResultsFunc}

                                                <div class={this.headerSort}>
                                                    <div class="horizontalRadio radioDefault">
                                                        <label class="headerSortEl siteSizeLabel">Show only : </label>
                                                        <div class="headerSortEl">
                                                            <input class="radioDefault" type="radio" id="calculators" name="sortEl" onClick={this.showOnlyCalcs} /><label class="labelRadio siteSizeLabel" for="calculators">Calculators</label><br />
                                                        </div>
                                                        <div class="headerSortEl">
                                                            <input class="radioDefault" type="radio" id="functions" name="sortEl" onClick={this.showOnlyFuncs} /><label class="labelRadio siteSizeLabel" for="functions">Functions</label>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="search">
                                                    <input type="search" class="search-input browser-default" placeholder="Search by name" onChange={this.searchBarChanged} onKeyDown={this.confirmSearch}/>
                                                </div>
                                                <button class="btn btn-primary searchBut" onClick={this.confirmSearchBut}><i class="fa fa-search"></i></button>
                                            </div>


                                            <table class="datatable">
                                                <thead>
                                                    <tr>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.funcsToShow}
                                                </tbody>
                                            </table>
                                           

                                        </div>

                                        <div class="datatable-container footDataTable">
                                            <div class="footer-tools">
                                                <div class="list-items">
                                                    <label class="listContentAssets siteSizeLabel">Show</label>
                                                    <select name="n-entries" id="n-entries" class="browser-default listContentAssets n-entries" onChange={this.showDDChanged}>
                                                        <option value="5">5</option>
                                                        <option value="10" selected>10</option>
                                                        <option value="15">15</option>
                                                    </select>
                                                    <label class="listContentAssets siteSizeLabel">entries</label>
                                                </div>

                                                    <div class="pages">
                                                        {this.funcPagesNums}
                                                </div>
                                            </div>
                                                </div>


                                            </div>

                                    )
                                } else {
                                    return (
                                        <div>       
                                        <div class="datatable-container">


                                            <div class="header-tools">
                                                {this.headerResultsCalc}

                                                <div class={this.headerSort}>
                                                    <div class="horizontalRadio radioDefault">
                                                        <label class="headerSortEl siteSizeLabel">Show only : </label>
                                                        <div class="headerSortEl">
                                                            <input class="radioDefault" type="radio" id="calculators" name="sortEl" defaultChecked onClick={this.showOnlyCalcs}/><label class="labelRadio siteSizeLabel" for="calculators">Calculators</label><br />
                                                        </div>
                                                        <div class="headerSortEl">
                                                            <input class="radioDefault" type="radio" id="functions" name="sortEl" onClick={this.showOnlyFuncs}/><label class="labelRadio siteSizeLabel" for="functions">Functions</label>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="search">
                                                    <input type="search" class="search-input browser-default" placeholder="Search by name" onChange={this.searchBarChanged} onKeyDown={this.confirmSearch} />
                                                </div>
                                                <button class="btn btn-primary searchBut" onClick={this.confirmSearchBut}><i class="fa fa-search"></i></button>
                                            </div>


                                            <table class="datatable">
                                                <thead>
                                                    <tr>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.calcsToShow}
                                                </tbody>
                                            </table>


                                            

                                            </div >

                                            <div class="datatable-container footDataTable">
                                            <div class="footer-tools">
                                                <div class="list-items">
                                                    <label class="listContentAssets siteSizeLabel">Show</label>
                                                    <select name="n-entries" id="n-entries" class="browser-default listContentAssets n-entries" onChange={this.showDDChanged}>
                                                        <option value="5">5</option>
                                                        <option value="10" selected>10</option>
                                                        <option value="15">15</option>
                                                    </select>
                                                    <label class="listContentAssets siteSizeLabel">entries</label>
                                                </div>

                                                    <div class="pages">
                                                        {this.calcPagesNums}
                                                </div>
                                            </div>
                                                </div>
                                        </div>

                                    )
                                }
                    }
                })()}

            </div>


        );
        
    }
}





Assets.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(
    mapStateToProps,
    {}
)(Assets);