

import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import { Provider } from "react-redux";
import store from "./store";

import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import MyCalculators from "./components/layout/MyCalculators";
import Assets from "./components/layout/Assets";
import AssetPage from "./components/layout/AssetPage";
import Documentation from "./components/layout/Documentation";
import TermsPrivacy from "./components/layout/TermsPrivacy";


//import "./css/cttables.css";
//import './css/bootstrap.min.css'
//import './css/font-awesome.min.css'
//import './css/aos.css'
//import './css/owl.carousel.min.css'
//import './css/owl.theme.default.min.css'
//import './css/templatemo-digital-trend.css'
//import './css/assets.css'
//import "materialize-css/dist/css/materialize.min.css";
//import "materialize-css/dist/js/materialize.min.js";


//import "materialize-css/dist/js/materialize.min.js";
//import "materialize-css/dist/css/materialize.min.css";
//import './css/assets.css'
//import './css/templatemo-digital-trend.css'
//import './css/owl.theme.default.min.css'
//import './css/owl.carousel.min.css'
//import './css/aos.css'
//import './css/font-awesome.min.css'
//import './css/bootstrap.min.css'
//import "./css/cttables.css";
//import './css/calculator.css'










//import { Router, Route, Switch } from "react-router";




// Check for token to keep user logged in
if (localStorage.jwtToken) {
    // Set auth token header auth
    const token = localStorage.jwtToken;
    const userGuid = localStorage.userGuid;
    setAuthToken(token);
    // Decode token and get user info and exp
    const decoded = jwt_decode(token);
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded, userGuid));
    // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser());
        // Redirect to login
        window.location.href = "./login";
    }
}


class App extends Component {
    render() {
        return (
            <Provider store={store}>
            <Router>
                <div className="App">
                    <Navbar />                 
                    <Route exact path="/" component={Landing} />               
                    <Route exact path="/register" component={Register} />
                        <Route exact path="/login" component={Login} />   
                        <Route exact path="/assets" component={Assets} />
                        <Route exact path="/assetPage" component={AssetPage} />
                        <Route exact path="/documentation" component={Documentation} />
                        <Route exact path="/termsPrivacy" component={TermsPrivacy} />
                        <Switch>
                            <PrivateRoute exact path="/myCalculators" component={MyCalculators} />
                        </Switch>
                    <Footer />
                </div>
                </Router>
            </Provider>
        );
    }
}
export default App;