import React, { Component } from "react";
import { Link } from "react-router-dom";


class Footer extends Component {
    render() {
        return (
            <div className="footer">


<footer class="site-footer">
                    <div style={{width: "100%"}} class="container">

                        <div class="column">

                            {/*
<div class="col-lg-5 mx-lg-auto col-md-8 col-10">
                    <h1 class="text-white" data-aos="fade-up" data-aos-delay="100">We make creative <strong>brands</strong> only.</h1>
                </div>
                */}

                            <div class="myFooter myFooterUI col-lg-3 col-md-6 col-12" data-aos="fade-up" data-aos-delay="200">

                                <p class="mb-1 centerText">
                        Made by Chamsdine Assani
                    </p>

                                <p class="mb-1 centerText">
                                    <a href="mailto:cassa092@uottawa.ca">
                                        <i class="fa fa-envelope mr-2 footer-icon"></i>
                            cassa092@uottawa.ca
                        </a>
                                </p>
                            </div>

                            <div class="col-lg-4 mx-lg-auto col-md-6 col-12" data-aos="fade-up" style={{ margin: "auto" }} data-aos-delay="500">

                                <ul class="myFooterUl footer-link" style={{ textAlign: "center" }}>
                                    <li><a href="/termsPrivacy">Terms of use</a></li>
                                    <li><a href="/termsPrivacy">Privacy policy</a></li>
                                    </ul>
                                </div>

            </div>
        </div>
    </footer>

            </div>


        );
    }
}
export default Footer;