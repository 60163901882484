import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { databasesActions, utils } from "../../actions/generalActions";
import logoFileDownload from "../../css/logoFileDownload.png"
import equalLogo from "../../css/equalImg.png"

/*
import {
    Modal,
    ModalHeader,
    ModalTitle,
    ModalClose,
    ModalBody,
    ModalFooter
} from "./react-modal-bootstrap/";
*/

var reactMB = require("react-modal-bootstrap");
var Modal = reactMB.Modal;
var ModalHeader = reactMB.ModalHeader;
var ModalTitle = reactMB.ModalTitle;
var ModalClose = reactMB.ModalClose;
var ModalBody = reactMB.ModalBody;
var ModalFooter = reactMB.ModalFooter;


class Landing extends Component {

    constructor() {
        super();
        this.state = {
            selectedCalculator: JSON.parse(localStorage.getItem('selectedCalculator')) || {},
            calcField: "",
            isCalculatorModalOpen: false,
            isFunctionsModalOpen: false,
            isFunctionInfoModalOpen: false,
            isConstantsModalOpen: false,
            isConstantInfoModalOpen: false,
            isDeleteActionModalOpen: false,
            isConfirmEditActionModalOpen: false,
            isConfirmEditActionModalOpen_Const: false,
            isEditActionModalOpen: false,
            isEditActionModalOpen_Const: false,
            theFunction: false,
            isConfirmUpublishFuncModalOpen: false,
            isConfirmCreateActionModalOpen: false,
            isConfirmCreateActionModalOpen_Const: false,
            isErrorActionModalOpen: false,
            isErrorActionModalOpenEdit: false,
            isErrorActionModalOpen_Const: false,
            isErrorActionModalOpenEdit_Const: false,
            newFunctionModal: false,
            newConstantModal: false,
            isConfirmDeleteActionModalOpen: false,
            isConfirmDeleteActionModalOpen_Const: false,
            theActionToBeEdited: "",
            newAction: {
                key: "",
                expressionOrValue: "",
                params: "",
                description: ""
            },
            editStateAction: {
                key: "",
                expressionOrValue: "",
                params: "",
                description: ""
            },
            calculatorEditState: {
                name: "",
                description: ""
            },
            isEditCalcModalOpen: false,
            isConfirmEditCalcModalOpen: false,
            errorComputingModalOpen: false,
            isParametersCalcOpen: false,
            errorComputingText: "",
            lastCalcField: [""],
            paramsProvided: [],
            funcToGiveParams: "",
        }
        this.inputKey = this.inputKey.bind(this);
        this.moveAction = this.moveAction.bind(this);
        this.openFunctionInfo = this.openFunctionInfo.bind(this);
        this.openConstantInfo = this.openConstantInfo.bind(this);
        this.openDelConfActionModal = this.openDelConfActionModal.bind(this);
        this.openConfirmUpublishFuncModal = this.openConfirmUpublishFuncModal.bind(this);
        this.publishFunc = this.publishFunc.bind(this);

        var calculator_ = this.state.selectedCalculator;
        if (Object.keys(calculator_).length == 0) {
            calculator_ = getLandingCalculator();
        }
        this.state.selectedCalculator = calculator_;
        this.paramsModal = [];
        this.inputParams = [];
    }

    componentDidMount() {

        document.title = "Calculate Together";

        this.keyInput.focus();
        this.keyPlaceholder = "Key (you can enter unicode characters, for example: \u03BB)";
        this.updatePublicationsElCalc();
        this.setState({ calcField: this.state.calcField });
    }


    requestParams(func) {
        this.paramsModal = [];
        this.state.funcToGiveParams = func;

        for (var i = 0; i < this.inputParams.length; i++) {
            if (this.inputParams[i] != null) {
                this.inputParams[i].value = "";
            }
        }

        this.state.paramsProvided = [];

        var params = utils.getParams(utils.getCodedFrom(func), this.state.selectedCalculator).split(",");

        for (var i = 0; i < params.length; i++) {
            var paramLayoutZone;
            if (i == 0) {
                paramLayoutZone = <input ref={el => this.inputParams.push(el)} type="reset" class="" placeholder={utils.getTextFrom(params[i])} id={params[i]} maxlength="1000" type="text" tabindex="1" onChange={this.paramsCalcChangeHandler} autofocus defaultValue="" />;
            }

            else {
                paramLayoutZone = <input ref={el => this.inputParams.push(el)} type="reset" class="" placeholder={utils.getTextFrom(params[i])} id={params[i]} maxlength="1000" type="text" tabindex="1" onChange={this.paramsCalcChangeHandler} defaultValue="" />;
            }

            this.paramsModal.push(paramLayoutZone);
            this.state.paramsProvided.push({ paramName: params[i], param: "" });
        }

        for (var i = 0; i < this.inputParams.length; i++) {
            if (this.inputParams[i] != null) {
                this.inputParams[i].value = "";
            }
        }


        this.setState({
            isParametersCalcOpen: true
        });

    }

    closeParametersCalc = () => {
        this.keyInput.focus();
        this.setState({
            isParametersCalcOpen: false
        });
    };

    confirmParametersCalc = (e) => {
        e.preventDefault();

        var textDec = utils.getTextFrom(this.state.funcToGiveParams) + "(";
        const params = utils.getParamsFrom(utils.getActionOfName(this.state.funcToGiveParams, this.state.selectedCalculator)).split(",");

        for (var i = 0; i < params.length; i++) {
            if (i != 0) {
                textDec += ",";
            }
            textDec += this.state.paramsProvided[i].param;
        }

        textDec += ")";

        this.state.lastCalcField.push(this.state.calcField);
        this.state.calcField += textDec;

        this.keyInput.focus();

        this.setState({
            isParametersCalcOpen: false
        });
    };

    paramsCalcChangeHandler = (e) => {

        var newStateParams = this.state.paramsProvided;

        for (var i = 0; i < newStateParams.length; i++) {
            if (newStateParams[i].paramName == e.target.id) {
                newStateParams[i].param = e.target.value;
                break;
            }
        }


        this.setState({ paramsProvided: newStateParams })

        /*
        if (event.target.id == "key") {
            newStateAction.key = event.target.value;
        }
        if (event.target.id == "params") {
            newStateAction.params = event.target.value;
        }
        else if (event.target.id == "expressionOrValue") {
            newStateAction.expressionOrValue = event.target.value;
        }
        else if (event.target.id == "description") {
            newStateAction.description = event.target.value;
        }
        this.setState({ newAction: newStateAction });

        this.refreshState();
        */
    };

    showAlertSuccess = (message, area) => {
        var alertbox = new AlertBox(area, {
            closeTime: 3500,
            persistent: false,
            hideCloseButton: false
        }, true);

        alertbox.show(message);
    }


    showAlertError = (message, area) => {
        var alertbox = new AlertBox(area, {
            closeTime: 3500,
            persistent: false,
            hideCloseButton: false
        }, false);

        alertbox.show(message);
    }

    updatePublicationsElCalc = () => {

        this.publicationZoneCalc = []
        if (this.props.auth.isAuthenticated) {
            if (this.state.selectedCalculator.isPublic == "true") {
                this.publicationZoneCalc =
                    <button className='btn btn-default myBtns' onClick={this.unpublishCalculator}>
                        Unpublish
                </button>
            }
            else if (this.state.selectedCalculator.originalGuid == "" && this.state.selectedCalculator.guid != "-landing" && this.state.selectedCalculator.guid != "-used") {
                this.publicationZoneCalc =
                    <button className='btn btn-default myBtns' onClick={this.publishCalculator}>
                        Publish
                </button>
            }
            else {
                this.publicationZoneCalc = []
            }
        }
        else {
            this.publicationZoneCalc = []
        }

        var calcState = []
        var calcPub = []

        if (this.state.selectedCalculator.isPublic == "true") {
            calcState = <i class="fa fa-globe marginHeader blueColorStyle"></i>;
            calcPub = <div><a class= "spacingCenterStyle" href={"/assetPage?id=" + this.state.selectedCalculator.guid}>Go to public page</a></div>
        }

        else if (this.state.selectedCalculator.originalGuid != "") {
            calcState = <img style={{ marginLeft: "25px", width: "25px" }} src={logoFileDownload}></img>
            calcPub = <div><a class="spacingCenterStyle" href={"/assetPage?id=" + this.state.selectedCalculator.originalGuid}>Go to public page</a></div>

            if (this.state.selectedCalculator.publicationDatas.updated == "false") {
                calcState = <React.Fragment><img style={{ marginLeft: "25px", width: "25px" }} src={logoFileDownload}></img></React.Fragment>
                calcPub = <div class="spacingCenterStyle"><div class="spacingCenterStyle"><a class="spacingCenterStyle" href={"/assetPage?id=" + this.state.selectedCalculator.originalGuid}>Go to public page</a></div>
                    <div class="spacingCenterStyle"><i style={{ fontSize: "23px", color: "#f9e401" }} class="fa fa-exclamation-circle"></i>
                        <div style={{ marginLeft: "10px" }}>{"Update available. Go to My Calculators to update."}</div></div></div>
            }
        }

        var calcInfoHeaderName = <div style={{ width: "93%", textAlign: "center" }}><h4 class="modal-title centerEl paddingModalTitle breakAllWord">{utils.getTextFrom(this.state.selectedCalculator.name)}{calcState}           
        </h4></div>

        this.calcInfoHeader = <ModalHeader>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
            }}>
                <div class="modalHeaderNoBorder">
                    <div style={{ width: "7%" }}></div>
                    {calcInfoHeaderName}
                    <ModalClose onClick={this.hideCalculatorModal} />
                </div>
                {calcPub}
            </div>
        </ModalHeader>;

    }

    publishCalculator = () => {
        databasesActions.publishCalculator({ calculatorGuid: this.state.selectedCalculator.guid }).then(res => {

            if (res.errorHasOccurred != null) {
                this.showAlertError("An error has occurred. Please try again.", "#alert-areaCalcModal");
            }
            else {
                this.state.selectedCalculator.isPublic = "true";
                this.state.selectedCalculator.originalGuid = this.state.selectedCalculator.guid;
                this.state.selectedCalculator.publicationDatas = {
                    listOfLikes: "",
                    listOfLikesFunc: this.state.selectedCalculator.listOfLikesFunc,
                    updated: "notApplicable"
                }
                localStorage.setItem("selectedCalculator", JSON.stringify(this.state.selectedCalculator));
                this.updatePublicationsElCalc();
                this.setState({ calcField: this.state.calcField });

                this.showAlertSuccess("Calculator successfully published", "#alert-areaCalcModal");
            }

        }).catch(err => {
            this.showAlertError("An error has occurred. Please try again.", "#alert-areaCalcModal");
        })
    }

    unpublishCalculator = () => {
        databasesActions.unpublishCalculator({ calculatorGuid: this.state.selectedCalculator.guid }).then(res => {
            this.state.selectedCalculator.isPublic = "false";
            this.state.selectedCalculator.originalGuid = "";
            this.state.selectedCalculator.publicationDatas = {
                listOfLikes: "",
                listOfLikesFunc: this.state.selectedCalculator.listOfLikesFunc,
                updated: "notApplicable"
            }
            localStorage.setItem("selectedCalculator", JSON.stringify(this.state.selectedCalculator));
            this.updatePublicationsElCalc();
            this.setState({ calcField: this.state.calcField });

            this.showAlertSuccess("Calculator has been unpublished", "#alert-areaCalcModal");

        }).catch(err => {
            this.showAlertError("An error has occurred. Please try again.", "#alert-areaCalcModal")
        })
    }

    inputKey(key) {
            this.keyInput.focus();
        if (key == "equal") {
            if (this.state.calcField.trim() != "") {
                var result = utils.computeExpressionErrorFormat(this.state.calcField, this.state.selectedCalculator);
                if (result.error != null) {
                    this.errorComputingText = result.error;
                    this.setState({ errorComputingModalOpen: true });
                }
                else {

                    var showFormatXN = false;
                    var toShowFormatXN = "";

                    var testEStr = "" + result.value;
                    for (var i = 0; i < testEStr.length; i++) {
                        if (testEStr[i] == "e") {

                            i++;
                            var fractB = "";
                            for (var j = i; j < testEStr.length; j++) {
                                fractB += testEStr[j]
                            }

                            toShowFormatXN = toShowFormatXN + "x\u207f(10,  " + fractB + ")";
                            showFormatXN = true;
                            break;
                        }
                        else {
                            toShowFormatXN += testEStr[i];
                        }
                    }

                    if (showFormatXN) {
                        this.setState({ calcField: toShowFormatXN });
                    }
                    else {
                        this.setState({ calcField: "" + result.value });
                    }
                }
            }
        }
        else if (key == "\u21b6")
        {

            if (this.state.lastCalcField.length > 1) {
                this.setState({ calcField: this.state.lastCalcField[this.state.lastCalcField.length - 2] });
                this.state.lastCalcField.splice(this.state.lastCalcField.length - 1, 1);
            }
            else {
                this.setState({ calcField: this.state.lastCalcField[this.state.lastCalcField.length - 1] });
            }
        }
        else if (key == "Clear")
        {
            this.setState({ calcField: "" });  
            this.state.lastCalcField = [""];
        }
        else if (key != "equal") {
            this.setState({ calcField: this.state.calcField + key });
            this.state.lastCalcField.push(this.state.calcField + key);
        }
    };

    inputKeyDouble(key) {
        if (utils.getTypeFrom(utils.getActionOfName(utils.getCodedFrom(key), this.state.selectedCalculator)) == "func") {
            this.keyInput.blur();
            this.requestParams(utils.getCodedFrom(key));
            this.state.calcField = this.state.calcField.substring(0, this.state.calcField.length - (key.length*2));
        }
    };

    equalKey= (e) => {
        if (e.key === 'Enter') {
            this.inputKey("equal");
        }
    }

    calcFieldChanged = (e) => {
        this.setState({ calcField: e.target.value });
        this.state.lastCalcField.push(this.state.calcField);
    }

    closeErrorComputingModal = () => {
        this.setState({ errorComputingModalOpen: false });
    };



    //----------------------------------------------------------------------------------Actions----------------------------------------------


    openCalculatorModal = () => {
        this.setState({
            isCalculatorModalOpen: true
        });
    };

    hideCalculatorModal = () => {
        this.keyInput.focus();
        this.setState({
            isCalculatorModalOpen: false
        });
    };

    openConstantsModal = () => {
        this.setState({
            isConstantsModalOpen: true
        });
    };

    hideConstantsModal = () => {
        this.keyInput.focus();
        this.setState({
            isConstantsModalOpen: false
        });
    };

    openFunctionsModal = () => {
        this.setState({
            isFunctionsModalOpen: true
        });
    };

    hideFunctionsModal = () => {
        this.keyInput.focus();
        this.setState({
            isFunctionsModalOpen: false
        });
    };

    moveAction = (theAction, direction) => {

        if (this.state.selectedCalculator.guid != "-landing" && this.state.selectedCalculator.guid != "-used") {

            const datas = {
                calculatorGuid: this.state.selectedCalculator.guid,
                theAction: theAction,
                direction: direction
            };

            this.state.selectedCalculator.actions = utils.moveActionGA(this.state.selectedCalculator, theAction, direction)

            databasesActions.moveAction(datas)
                .then(res => {
                    if (res.errorHasOccurred != null) {
                        localStorage.setItem("selectedCalculator", JSON.stringify(this.state.selectedCalculator));
                    }

                })
                .catch(err => {

                });

            this.refreshActions();
            this.refreshState();
        }

        else {
            this.state.selectedCalculator.actions = utils.moveActionGA(this.state.selectedCalculator, theAction, direction)
            localStorage.setItem("selectedCalculator", JSON.stringify(this.state.selectedCalculator));
            this.refreshActions();
            this.refreshState();
        }



    };

    loadFunctionInfo = (theFunction) => {

        this.functionInfo = []

        var funcState = []
        var funcPub = []

        if (utils.isFuncPublic(this.state.selectedCalculator, theFunction)) {
            funcState = <i class="fa fa-globe marginHeader blueColorStyle"></i>;
            funcPub = <div><a class="spacingCenterStyle" href={"/assetPage?id=" + this.state.selectedCalculator.guid + "&f=" + utils.getKeyFrom(theFunction)}>
                Go to public page</a></div>
        }

        var funcInfoHeaderName = <div style={{ width: "93%", textAlign: "center" }}><h4 class="modal-title centerEl paddingModalTitle breakAllWord modalTitleInfo">{utils.getTextFrom(utils.getKeyFrom(theFunction))}{funcState}
        </h4></div>

        const header = <ModalHeader>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
            }}>
                <div class="modalHeaderNoBorder">
                    <div style={{ width: "7%"}}></div>
                    {funcInfoHeaderName}
                    <ModalClose onClick={this.hideFunctionInfo} />
                </div>
                {funcPub}
            </div>
        </ModalHeader>;





        const parametrs = <div class="infoPanel"><div class="titleInfoPanel">Parameters</div>
            <div class="contentInfoPanel">{utils.decodeParams(utils.getParamsFrom(theFunction))}</div></div>

        const expression = <div class="infoPanel"><div class="titleInfoPanel">Expression</div>
            <div class="contentInfoPanel">{utils.getTextFrom(utils.getExpressionFrom(theFunction))}</div></div>

        const description = <div class="infoPanel"><div class="titleInfoPanel">Description</div>
            <div class="contentInfoPanel">{utils.getTextFrom(utils.getDescriptionFrom(theFunction))}</div></div>

        var publishZone = [];
        if (this.props.auth.isAuthenticated && this.state.selectedCalculator.guid != "-used" && this.state.selectedCalculator.guid != "-landing") {
            if (utils.isFuncPublic(this.state.selectedCalculator, theFunction)) {
                publishZone = <button className='btn btn-default myBtns' onClick={() => this.openConfirmUpublishFuncModal(theFunction)}>Unpublish</button>
            }
            else {
                publishZone = <button className='btn btn-default myBtns' onClick={() => this.publishFunc(theFunction)}>Publish</button>
            }
        }

        const modalBodyFooter = <React.Fragment><ModalBody>
            {parametrs}<hr class="myHRInfo" />{expression}<hr class="myHRInfo" />{description}
        </ModalBody>
            <ModalFooter>
                {publishZone}
                <button className='btn btn-default myBtns' onClick={() => this.showEditActionModal(theFunction)}>Edit</button>
                <button className='btn btn-default myBtns' onClick={() => this.openDelConfActionModal(theFunction)}>Delete</button>
                <button className='btn btn-default myBtns' onClick={this.hideFunctionInfo}>Close</button>
            </ModalFooter></React.Fragment>



        const theModal = <React.Fragment>{header}{modalBodyFooter}</React.Fragment>

        this.functionInfo.push(theModal);
    }

    openFunctionInfo = (theFunction) => {

        this.loadFunctionInfo(theFunction);

        this.setState({
            isFunctionInfoModalOpen: true
        });

    };

    hideFunctionInfo = () => {
        this.setState({
            isFunctionInfoModalOpen: false
        });
    };

    createAFunction = () => {
        this.setState({
            newFunctionModal: true
        });
    };

    cancelCreateAFunction = () => {
        this.state.newAction = { key: "", params: "", expressionOrValue: "", description: "" }
        this.setState({
            newFunctionModal: false
        });
    };


    openConfirmUpublishFuncModal = (theFunction) => {

        this.confirmUnpublishFuncLayout = [];
        this.confirmUnpublishFuncLayout.push(
            <div style={{
                margin: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                flexDirection: "column"
            }}>
                <div>{"Do you want to unpublish this function? "}<br /><br /></div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <button class="btn btn-default myBtns" onClick={() => this.confirmUpublishFunc(theFunction)}>Yes</button>
                    <button class="btn btn-default myBtns" onClick={this.cancelUnpublishFunc}>No</button><br /></div></div>);

        this.setState({
            isConfirmUpublishFuncModalOpen: true
        });
    };

    confirmUpublishFunc = (theFunction) => {
        databasesActions.unpublishFunction({ calculatorGuid: this.state.selectedCalculator.guid, funcKey: utils.getKeyFrom(theFunction) }).then(res => {

            this.setState({ isConfirmUpublishFuncModalOpen: false });
            if (res.errorHasOccurred != null) {
                this.showAlertError("An error has occurred. Please try again.", "#alert-viewFunctionInfoModal");
            }

            else {
                var listOfLikesFunc = this.state.selectedCalculator.publicationDatas.listOfLikesFunc;
                var listOfLikesFuncArr = listOfLikesFunc.split(";");
                var included = false;
                for (var i = 0; i < listOfLikesFuncArr.length; i++) {
                    var funcAct = listOfLikesFuncArr[i].split(":");
                    if (funcAct[0] == utils.getKeyFrom(theFunction)) {
                        included = true;
                        break;
                    }
                }

                if (included) {

                    var listOfLikesFuncToSet = "";
                    for (var j = 0; j < listOfLikesFuncArr.length; j++) {

                        var funcAct = listOfLikesFuncArr[j].split(":");

                        if (funcAct[0] != utils.getKeyFrom(theFunction)) {
                            if (j != 0) {
                                listOfLikesFuncToSet += ";";
                            }

                            listOfLikesFuncToSet += listOfLikesFuncArr[j];
                        }
                    }

                    this.state.selectedCalculator.publicationDatas.listOfLikesFunc = listOfLikesFuncToSet;
                    localStorage.setItem("selectedCalculator", JSON.stringify(this.state.selectedCalculator));
                    this.loadFunctionInfo(theFunction);
                    this.setState({
                        isConfirmUpublishFuncModalOpen: false
                    });

                }

                this.showAlertSuccess("Function successfully unpublished.", "#alert-viewFunctionInfoModal");
            }

        }).catch(err => {
            this.setState({ isConfirmUpublishFuncModalOpen: false });
            this.showAlertError("An error has occurred. Please try again.", "#alert-viewFunctionInfoModal");
        });
    };

    cancelUnpublishFunc = () => {
        this.setState({
            isConfirmUpublishFuncModalOpen: false
        });
    };

    publishFunc = (theFunction) => {
        databasesActions.publishFunction({ calculatorGuid: this.state.selectedCalculator.guid, funcKey: utils.getKeyFrom(theFunction) }).then(res => {

            if (res.errorHasOccurred != null) {
                this.showAlertError("An error has occurred. Please try again.", "#alert-viewFunctionInfoModal");
            }

            else {
                var listOfLikesFunc = this.state.selectedCalculator.publicationDatas.listOfLikesFunc;
                var listOfLikesFuncArr = listOfLikesFunc.split(";");
                var included = false;
                for (var i = 0; i < listOfLikesFuncArr.length; i++) {
                    var funcAct = listOfLikesFuncArr[i].split(":");
                    if (funcAct[0] == utils.getKeyFrom(theFunction)) {
                        included = true;
                        break;
                    }
                }

                if (!included) {
                    if (listOfLikesFunc == "") {
                        listOfLikesFunc = utils.getKeyFrom(theFunction) + ":";
                    }
                    else {
                        listOfLikesFunc = listOfLikesFunc + ";" + utils.getKeyFrom(theFunction) + ":";
                    }

                    this.state.selectedCalculator.publicationDatas.listOfLikesFunc = listOfLikesFunc;
                    localStorage.setItem("selectedCalculator", JSON.stringify(this.state.selectedCalculator));
                    this.loadFunctionInfo(theFunction);
                    this.refreshState();

                }

                this.showAlertSuccess("Function successfully published.", "#alert-viewFunctionInfoModal");
            }

        }).catch(err => {
            this.showAlertError("An error has occurred. Please try again.", "#alert-viewFunctionInfoModal");
        });
    };



    loadConstantInfo = (theConstant) => {

        this.constantInfo = []

        var constInfoHeaderName = <div style={{ width: "93%", textAlign: "center" }}><h4 class="modal-title centerEl paddingModalTitle breakAllWord modalTitleInfo">{utils.getTextFrom(utils.getKeyFrom(theConstant))}
        </h4></div>

        const header = <ModalHeader>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
            }}>
                <div class="modalHeaderNoBorder">
                    <div style={{ width: "7%" }}></div>
                    {constInfoHeaderName}
                    <ModalClose onClick={this.hideConstantInfo} />
                </div>
            </div>
        </ModalHeader>;


        const value = <div class="infoPanel"><div class="titleInfoPanel">Value</div>
            <div class="contentInfoPanel">{utils.getTextFrom(utils.getValueFrom(theConstant))}</div></div>

        const description = <div class="infoPanel"><div class="titleInfoPanel">Description</div>
            <div class="contentInfoPanel">{utils.getTextFrom(utils.getDescriptionFrom(theConstant))}</div></div>

        const modalBodyFooter = <React.Fragment><ModalBody><hr class="myHRInfo" />{value}<hr class="myHRInfo" />{description}
        </ModalBody>
            <ModalFooter>
                <button className='btn btn-default myBtns' onClick={() => this.showEditActionModal(theConstant)}>Edit</button>
                <button className='btn btn-default myBtns' onClick={() => this.openDelConfActionModal(theConstant)}>Delete</button>
                <button className='btn btn-default myBtns' onClick={this.hideConstantInfo}>Close</button>
            </ModalFooter></React.Fragment>



        const theModal = <React.Fragment>{header}{modalBodyFooter}</React.Fragment>

        this.constantInfo.push(theModal);
    }


    openEditConstantModal = (theConstant) => {
        this.setState({
            isEditConstantModalOpen: true
        });
    };

    closeEditConstantModal = () => {
        this.setState({
            isEditConstantModalOpen: false
        });
    };

    openConstantInfo = (theConstant) => {

        this.loadConstantInfo(theConstant);

        this.setState({
            isConstantInfoModalOpen: true
        });

    };

    hideConstantInfo = () => {
        this.setState({
            isConstantInfoModalOpen: false
        });
    };

    openDelConfActionModal = (theAction) => {
        this.state.theActionToBeDeleted = theAction;
        if (utils.getTypeFrom(theAction) == "func") {
            this.setState({
                isConfirmDeleteActionModalOpen: true
            });
        }
        else {
            this.setState({
                isConfirmDeleteActionModalOpen_Const: true
            });
        }
    };

    confirmDeleteAction = () => {
        if (this.state.selectedCalculator.guid != "-landing" && this.state.selectedCalculator.guid != "-used") {
            const datas = {
                calculatorGuid: this.state.selectedCalculator.guid,
                actionKey: utils.getKeyFrom(this.state.theActionToBeDeleted),
            };

            databasesActions.removeAction(datas)
                .then(res => {
                    this.setState({ isConfirmDeleteActionModalOpen: false });
                    this.setState({ isConfirmDeleteActionModalOpen_Const: false });
                    if (res.errorHasOccurred != null) {
                        if (utils.getTypeFrom(this.state.theActionToBeDeleted) == "func") {
                            this.showAlertError("An error has occurred. Please try again.", "#alert-funcInfoModal");
                        }
                        else {
                            this.showAlertError("An error has occurred. Please try again.", "#alert-constInfoModal");
                        }
                    }
                    else {
                        this.removeAction(this.state.theActionToBeDeleted);
                        this.setState({ isFunctionInfoModalOpen: false });
                        this.setState({ isConstantInfoModalOpen: false });
                    }
                })
                .catch(err => {
                    //console.log(err);
                    this.setState({ isConfirmDeleteActionModalOpen: false });
                    this.setState({ isConfirmDeleteActionModalOpen_Const: false });
                    if (utils.getTypeFrom(this.state.theActionToBeDeleted) == "func") {
                        this.showAlertError("An error has occurred. Please try again.", "#alert-funcInfoModal");
                    }
                    else {
                        this.showAlertError("An error has occurred. Please try again.", "#alert-constInfoModal");
                    }
                });
        }

        else {

            this.removeAction(this.state.theActionToBeDeleted);
            this.setState({ isConfirmDeleteActionModalOpen: false });
            this.setState({ isConfirmDeleteActionModalOpen_Const: false });
            this.setState({ isFunctionInfoModalOpen: false });
            this.setState({ isConstantInfoModalOpen: false });
        }
    };

    cancelDelAction = () => {
        this.setState({
            isConfirmDeleteActionModalOpen: false
        });
        this.setState({
            isConfirmDeleteActionModalOpen_Const: false
        });
    };




    addAction = theAction => {
        var calculator = this.state.selectedCalculator;
        var params = "";
        if (theAction.type == "func") {
            params = "; params =" + theAction.params;
        }
        else {
            params = "";
        }
        const newFunction = "type = " + theAction.type + "; special = " + theAction.special + "; key = " + theAction.key + "; size = 1" + params +
            "; expressionOrValue = " + theAction.expressionOrValue + "; description = " + theAction.description;
        calculator.actions.push(newFunction);
        this.state.selectedCalculator = calculator;
        localStorage.setItem("selectedCalculator", JSON.stringify(this.state.selectedCalculator));

        this.refreshActions();
        this.setState({
            newFunctionModal: false
        });
        this.setState({
            newConstantModal: false
        });
    };

    editAction = theAction => {
        var calculator = this.state.selectedCalculator;
        var params = "";
        if (theAction.type == "func") {
            params = "; params =" + theAction.params;
        }
        else {
            params = "";
        }
        const newAction = "type = " + theAction.type + "; special = " + theAction.special + "; key = " + theAction.key + "; size = 1" + params +
            "; expressionOrValue = " + theAction.expressionOrValue + "; description = " + theAction.description;

        var actions = calculator.actions;
        for (var i = 0; i < actions.length; i++) {
            if (utils.getKeyFrom(actions[i]) == theAction.keyToModify) {
                actions.splice(i, 1, newAction);

                if (this.state.selectedCalculator.guid != "-landing" && this.state.selectedCalculator.guid != "-used") {
                    var listOfLikesFunc_ = calculator.publicationDatas.listOfLikesFunc;
                    var listOfLikesFunc = listOfLikesFunc_.split(";");

                    for (var j = 0; j < listOfLikesFunc.length; j++) {

                        const funcAct = listOfLikesFunc[j].split(":");
                        if (funcAct[0] == theAction.keyToModify) {
                            funcAct[0] = theAction.key;
                            var textFuncLike = funcAct[0] + ":";
                            if (funcAct.length == 2) {
                                textFuncLike = textFuncLike + funcAct[1];
                            }
                            listOfLikesFunc.splice(j, 1, textFuncLike);
                            break;
                        }
                    }

                    var listOfLikesFuncText = "";
                    for (var j = 0; j < listOfLikesFunc.length; j++) {
                        if (j != 0) {
                            listOfLikesFuncText += ";";
                        }

                        listOfLikesFuncText += listOfLikesFunc[j];

                    }

                    calculator.publicationDatas.listOfLikesFunc = listOfLikesFuncText;
                }
                break;
            }
        }
        calculator.actions = actions

        this.state.selectedCalculator = calculator;
        localStorage.setItem("selectedCalculator", JSON.stringify(this.state.selectedCalculator));

        if (theAction.type == "func") {
            this.loadFunctionInfo(newAction);
        }
        else {
            this.loadConstantInfo(newAction);
        }
        this.refreshActions();
        this.setState({
            isEditActionModalOpen: false
        });
        this.setState({
            isEditActionModalOpen_Const: false
        });
    };


    removeAction = theAction => {

        var calculator = this.state.selectedCalculator;
        var actions = calculator.actions;



        for (var i = 0; i < actions.length; i++) {
            if (utils.getKeyFrom(actions[i]) == utils.getKeyFrom(theAction)) {
                actions.splice(i, 1);

                if (this.state.selectedCalculator.guid != "-landing" && this.state.selectedCalculator.guid != "-used") {
                    var listOfLikesFunc = calculator.publicationDatas.listOfLikesFunc;
                    var listOfLikesFuncArr = listOfLikesFunc.split(";");

                    var listOfLikesFuncToSet = "";
                    for (var j = 0; j < listOfLikesFuncArr.length; j++) {

                        var funcAct = listOfLikesFuncArr[j].split(":");

                        if (funcAct[0] != utils.getKeyFrom(theAction)) {
                            if (j != 0) {
                                listOfLikesFuncToSet += ";";
                            }

                            listOfLikesFuncToSet += listOfLikesFuncArr[j];
                        }
                    }

                    calculator.publicationDatas.listOfLikesFunc = listOfLikesFuncToSet;
                }
                break;

            }
        }
        calculator.actions = actions;

        this.state.selectedCalculator = calculator;
        localStorage.setItem("selectedCalculator", JSON.stringify(this.state.selectedCalculator));

        this.refreshActions();
        this.setState({
            isFunctionInfoModalOpen: false
        });
        this.setState({
            isConstantInfoModalOpen: false
        });
    };


    refreshState = () => {
        this.setState({ calcField: this.state.calcField });
    }

    createActionChangeHandler = (event) => {
        var newStateAction = this.state.newAction;

        if (event.target.id == "key") {
            newStateAction.key = event.target.value;
        }
        if (event.target.id == "params") {
            newStateAction.params = event.target.value;
        }
        else if (event.target.id == "expressionOrValue") {
            newStateAction.expressionOrValue = event.target.value;
        }
        else if (event.target.id == "description") {
            newStateAction.description = event.target.value;
        }
        this.setState({ newAction: newStateAction });

    }


    confirmCreateAction = (event) => {
        event.preventDefault();
        var theType = "";

        if (this.state.newFunctionModal) {
            theType = "func";
        }
        else {
            theType = "const"
        }

        var errorPot = "";
        if (theType == "func") {
            errorPot = utils.isActionCreationValid(true, this.state.newAction.key, this.state.newAction.params, this.state.newAction.expressionOrValue, this.state.selectedCalculator, true, null);
        }
        else {
            errorPot = utils.isActionCreationValid(false, this.state.newAction.key, this.state.newAction.params, this.state.newAction.expressionOrValue, this.state.selectedCalculator, true, null);
        }
        
        if (errorPot.error != null) {
            this.errorActionModalText = errorPot.error;
            if (this.state.newFunctionModal) {
                this.setState({ isErrorActionModalOpen: true });
            }
            else {
                this.setState({ isErrorActionModalOpen_Const: true });
            }
            this.setState({ isConfirmCreateActionModalOpen: false });
                this.setState({ isConfirmCreateActionModalOpen_Const: false });

            return;
        }

        if (this.state.selectedCalculator.guid != "-landing" && this.state.selectedCalculator.guid != "-used") {

            var codedParams = utils.codeParams(this.state.newAction.params);

            const datas = {
                type: theType,
                special: false,
                key: utils.getCodedFrom(this.state.newAction.key),
                params: codedParams,
                expressionOrValue: utils.getCodedFrom(this.state.newAction.expressionOrValue),
                description: utils.getCodedFrom(this.state.newAction.description),
                calculatorGuid: this.state.selectedCalculator.guid,
            };

            databasesActions.createNewAction(datas)
                .then(res => {
                    this.setState({ isConfirmCreateActionModalOpen: false });
                    this.setState({ isConfirmCreateActionModalOpen_Const: false });
                    if (res.errorHasOccurred != null) {
                        this.showAlertError("An error has occurred. Please try again.", "#alert-createActionModal");
                    }
                    else {
                        this.addAction(datas);
                        this.state.newAction = { key: "", params: "", expressionOrValue: "", description: "" }
                        this.setState({ newFunctionModal: false });
                        this.setState({ newConstantModal: false });
                        if (theType == "func") {
                            this.showAlertSuccess("Function successfully created.", "#alert-funcInfoModal");
                        }
                        else {
                            this.showAlertSuccess("Constant successfully created.", "#alert-constInfoModal");
                        }
                    }
                })
                .catch(err => {
                    this.setState({ isConfirmCreateActionModalOpen: false });
                    this.setState({ isConfirmCreateActionModalOpen_Const: false });
                    this.showAlertError("An error has occurred. Please try again.", "#alert-createActionModal");
                }
                );
        }

        else {

            var codedParams = "";
            var paramsArr = this.state.newAction.params.split(",");
            for (var i = 0; i < paramsArr.length; i++) {
                if (i != 0) {
                    codedParams += ",";
                }
                codedParams += utils.getCodedFrom(paramsArr[i]);
            }

            const datas = {
                type: theType,
                special: false,
                key: utils.getCodedFrom(this.state.newAction.key),
                params: codedParams,
                expressionOrValue: utils.getCodedFrom(this.state.newAction.expressionOrValue),
                description: utils.getCodedFrom(this.state.newAction.description),
                calculatorGuid: this.state.selectedCalculator.guid,
            };

            this.addAction(datas);
            this.state.newAction = { key: "", params: "", expressionOrValue: "", description: "" }
            this.setState({ newFunctionModal: false });
            this.setState({ newConstantModal: false });
            if (theType == "func") {
                this.showAlertSuccess("Function successfully created.", "#alert-funcInfoModal");
            }
            else {
                this.showAlertSuccess("Constant successfully created.", "#alert-constInfoModal");
            }
        }
    };

    editActionChangeHandler = (event) => {
        var editStateAction = this.state.editStateAction;

        if (event.target.id == "key") {
            editStateAction.key = event.target.value;
        }
        if (event.target.id == "params") {
            editStateAction.params = event.target.value;
        }
        else if (event.target.id == "expressionOrValue") {
            editStateAction.expressionOrValue = event.target.value;
        }
        else if (event.target.id == "description") {
            editStateAction.description = event.target.value;
        }

        this.setState({ editStateAction: editStateAction });
    }

    confirmEditAction = (e) => {

            e.preventDefault();

        var theType = "";

        var errorPot;

        if (this.state.isFunctionsModalOpen) {
            errorPot = utils.isActionCreationValid(true, this.state.editStateAction.key, this.state.editStateAction.params, this.state.editStateAction.expressionOrValue, this.state.selectedCalculator, false, utils.getKeyFrom(this.state.theActionToBeEdited));
            theType = "func";
        }
        else {
            errorPot = utils.isActionCreationValid(false, this.state.editStateAction.key, this.state.editStateAction.params, this.state.editStateAction.expressionOrValue, this.state.selectedCalculator, false, utils.getKeyFrom(this.state.theActionToBeEdited));
            theType = "const"
        }

        
        if (errorPot.error != null) {
            this.errorActionModalText = errorPot.error;
            if (this.state.isFunctionsModalOpen) {
                this.setState({ isErrorActionModalOpenEdit: true });
            }
            else {
                this.setState({ isErrorActionModalOpenEdit_Const: true });
            }
            this.setState({ isConfirmEditActionModalOpen: false });
            this.setState({ isConfirmEditActionModalOpen_Const: false });
            return;
        }

        if (this.state.selectedCalculator.guid != "-landing" && this.state.selectedCalculator.guid != "-used") {

            var codedParams = utils.codeParams(this.state.editStateAction.params);

            const datas = {
                type: theType,
                special: false,
                key: utils.getCodedFrom(this.state.editStateAction.key),
                params: codedParams,
                expressionOrValue: utils.getCodedFrom(this.state.editStateAction.expressionOrValue),
                description: utils.getCodedFrom(this.state.editStateAction.description),
                calculatorGuid: this.state.selectedCalculator.guid,
                keyToModify: utils.getKeyFrom(this.state.theActionToBeEdited)
            };

            databasesActions.editAction(datas)
                .then(res => {
                    this.setState({ isConfirmEditActionModalOpen: false });
                    this.setState({ isConfirmEditActionModalOpen_Const: false });
                    this.setState({ isConfirmEditPublicActionModalOpen: false });
                    if (res.errorHasOccurred != null) {
                        this.showAlertError("An error has occurred. Please try again.", "#alert-editActionModal");
                    }
                    else {
                        this.editAction(datas);
                        this.setState({ isEditActionModalOpen: false });
                        this.setState({ isEditActionModalOpen_Const: false });
                    }
                })
                .catch(err => {
                    this.setState({ isConfirmEditActionModalOpen: false });
                    this.setState({ isConfirmEditActionModalOpen_Const: false });
                    this.showAlertError("An error has occurred. Please try again.", "#alert-editActionModal");
                });
        }

        else {
            var codedParams = utils.codeParams(this.state.editStateAction.params);

            const datas = {
                type: theType,
                special: false,
                key: utils.getCodedFrom(this.state.editStateAction.key),
                params: codedParams,
                expressionOrValue: utils.getCodedFrom(this.state.editStateAction.expressionOrValue),
                description: utils.getCodedFrom(this.state.editStateAction.description),
                calculatorGuid: this.state.selectedCalculator.guid,
                keyToModify: utils.getKeyFrom(this.state.theActionToBeEdited)
            };

            this.editAction(datas);
            this.setState({ isEditActionModalOpen: false });
            this.setState({ isEditActionModalOpen_Const: false });
        }
    }


    showEditActionModal = (theAction) => {
        this.state.theActionToBeEdited = theAction;

        var editStateAction = {};

        if (utils.getTypeFrom(theAction) == "func") {
            editStateAction =
            {
                key: utils.getTextFrom(utils.getKeyFrom(theAction)),
                params: utils.decodeParams(utils.getParamsFrom(theAction)),
                expressionOrValue: utils.getTextFrom(utils.getExpressionFrom(theAction)),
                description: utils.getTextFrom(utils.getDescriptionFrom(theAction)),
            }
        }
        else {
            editStateAction =
            {
                key: utils.getTextFrom(utils.getKeyFrom(theAction)),
                params: "",
                expressionOrValue: utils.getTextFrom(utils.getValueFrom(theAction)),
                description: utils.getTextFrom(utils.getDescriptionFrom(theAction)),
            }
        }

        this.state.editStateAction = editStateAction;

        if (utils.getTypeFrom(theAction) == "func") {
            this.setState({
                isEditActionModalOpen: true
            });
        }

        else {
            this.setState({
                isEditActionModalOpen_Const: true
            });
        }
    };

    cancelEditAction = (e) => {

        if (e != null) {
            e.preventDefault();
        }

        this.setState({
            isEditActionModalOpen: false
        });

        this.setState({
            isEditActionModalOpen_Const: false
        });
    };

    requestConfirmEditAction = (e) => {
        e.preventDefault();
        if (utils.getTypeFrom(this.state.theActionToBeEdited) == "func" && utils.isFuncPublic(this.state.selectedCalculator, this.state.theActionToBeEdited)) {
            this.setState({
                isConfirmEditPublicActionModalOpen: true
            });
        }

        else if (this.state.selectedCalculator.isPublic == "true") {
            if (utils.getTypeFrom(this.state.theActionToBeEdited) == "func") {
                this.setState({
                    isConfirmEditActionModalOpen: true
                });
            }
            else {
                this.setState({
                    isConfirmEditActionModalOpen_Const: true
                });
            }
        }

        else {
            this.confirmEditAction(e);
        }
    };

    cancelRequestConfirmEditAction = () => {
        this.setState({
            isConfirmEditActionModalOpen: false
        });
        this.setState({
            isConfirmEditActionModalOpen_Const: false
        });
    };

    cancelRequestConfirmEditPublicAction = () => {
        this.setState({
            isConfirmEditPublicActionModalOpen: false
        });
    };

    requestConfirmCreateAction = (e) => {
        e.preventDefault();
        if (this.state.selectedCalculator.isPublic == "true") {
            this.setState({
                isConfirmCreateActionModalOpen: true
            });
            this.setState({
                isConfirmCreateActionModalOpen_Const: true
            });
        }

        else {
            this.confirmCreateAction(e);
        }
    };

    cancelRequestConfirmCreateAction = () => {
        this.setState({
            isConfirmCreateActionModalOpen: false
        });
        this.setState({
            isConfirmCreateActionModalOpen_Const: false
        });
    };

    closeErrorActionModal = () => {
        this.setState({
            isErrorActionModalOpen: false
        });
        this.setState({
            isErrorActionModalOpen_Const: false
        });
        this.setState({
            isErrorActionModalOpenEdit: false
        });
        this.setState({
            isErrorActionModalOpenEdit_Const: false
        });
    };


    createAConstant = () => {
        this.setState({
            newConstantModal: true
        });
    };

    cancelCreateAConstant = () => {
        this.state.newAction = { key: "", params: "", expressionOrValue: "", description: "" }
        this.setState({
            newConstantModal: false
        });
    };

    refreshActions = () => {

        var calculator_ = this.state.selectedCalculator;
        const calculator = calculator_;

        const actions = utils.getSpecials(calculator);

        //------------------------------- Actions de base ------------------------------

        this.firstRow = [];
        this.secondRow = [];
        this.thirdRow = [];
        this.fourthRow = [];
        this.fifhRow = [];
        this.sixthRow = [];

        var index = 0;
        while (index < 5) {
            if (index >= actions.length) { break; }
            const text = utils.getTextFrom(GetKeyFrom(actions[index]));
            var cl = "globalcalc";
            if (GetSizeFrom(actions[index]) == 2) {
                cl = "globalcalcBigHor";
            }

            if (index == 1) {
                this.firstRow.push(<button name="" class={cl} style={{ fontSize: "25px" }} onClick={() => this.inputKey(text)} onDoubleClick={() => this.inputKeyDouble(text)}>{text}</button>)
            }
            else if (index == 2) {
                this.firstRow.push(<button name="" class={cl} style={{ fontSize: "20px" }} onClick={() => this.inputKey(text)} onDoubleClick={() => this.inputKeyDouble(text)}>{text}</button>)
            }
            else {
                this.firstRow.push(<button name="" class={cl} onClick={() => this.inputKey(text)} onDoubleClick={() => this.inputKeyDouble(text)}>{text}</button>)
            }
            index += 1;
        }

        while (index < 11) {
            if (index >= actions.length) { break; }
            const text = utils.getTextFrom(GetKeyFrom(actions[index]));
            var cl = "globalcalc";
            if (GetSizeFrom(actions[index]) == 2) {
                cl = "globalcalcBigHor";
            }

            if (index == 9) {
                this.secondRow.push(<button name="" class={cl} style={{ fontSize: "20px" }} onClick={() => this.inputKey(text)} onDoubleClick={() => this.inputKeyDouble(text)} onDoubleClick={() => this.inputKeyDouble(text)}>{text}</button>)
            }
            else {
                this.secondRow.push(<button name="" class={cl} onClick={() => this.inputKey(text)} onDoubleClick={() => this.inputKeyDouble(text)}>{text}</button>)
            }
            index += 1;
        }

        while (index < 17) {
            if (index >= actions.length) { break; }
            const text = utils.getTextFrom(GetKeyFrom(actions[index]));
            var cl = "globalcalc";
            if (GetSizeFrom(actions[index]) == 2) {
                cl = "globalcalcBigHor";
            }

            this.thirdRow.push(<button name="" class={cl} onClick={() => this.inputKey(text)} onDoubleClick={() => this.inputKeyDouble(text)}>{text}</button>)
            index += 1;
        }

        while (index < 23) {
            if (index >= actions.length) { break; }
            const text = utils.getTextFrom(GetKeyFrom(actions[index]));
            var cl = "globalcalc";
            if (GetSizeFrom(actions[index]) == 2) {
                cl = "globalcalcBigHor";
            }

            if (index == 20) {
                this.fourthRow.push(<button name="" class={cl} style={{ fontSize: "24px" }} onClick={() => this.inputKey(text)} onDoubleClick={() => this.inputKeyDouble(text)}>{text}</button>)
            }
            else {
                this.fourthRow.push(<button name="" class={cl} onClick={() => this.inputKey(text)} onDoubleClick={() => this.inputKeyDouble(text)}>{text}</button>)
            }
            index += 1;
        }

        while (index < 29) {
            if (index >= actions.length) { break; }
            const text = utils.getTextFrom(GetKeyFrom(actions[index]));
            var cl = "globalcalc";
            if (GetSizeFrom(actions[index]) == 2) {
                cl = "globalcalcBigHor";
            }

            if (index == 24) {
                this.fifhRow.push(<button name="" class={cl} style={{ fontSize: "26px" }} onClick={() => this.inputKey(text)} onDoubleClick={() => this.inputKeyDouble(text)}>{text}</button>)
            }
            else if (index == 26) {
                this.fifhRow.push(<button name="" class={cl} style={{ fontSize: "23px" }} onClick={() => this.inputKey(text)} onDoubleClick={() => this.inputKeyDouble(text)}>{text}</button>)
            }
            else {
                this.fifhRow.push(<button name="" class={cl} onClick={() => this.inputKey(text)} onDoubleClick={() => this.inputKeyDouble(text)}>{text}</button>)
            }
            index += 1;
        }

        while (index < 34) {
            if (index >= actions.length) { break; }
            const text = utils.getTextFrom(GetKeyFrom(actions[index]));
            var cl = "globalcalc";
            if (GetSizeFrom(actions[index]) == 2) {
                cl = "globalcalcBigHor";
            }

            if (index == 30) {
                this.sixthRow.push(<button name="" class="globalcalcEqual" style={{ fontSize: "26px" }} onClick={() => this.inputKey(text)} onDoubleClick={() => this.inputKeyDouble(text)}>{"="}</button>)
            }
            else {
                this.sixthRow.push(<button name="" class={cl} onClick={() => this.inputKey(text)} onDoubleClick={() => this.inputKeyDouble(text)}>{text}</button>)
            }
            index += 1;
        }



        //--------------------------------- Constantes --------------------------------------------

        var constants = utils.getAllCustomConstants(calculator);

        this.firstRowConstants = [];
        this.secondRowConstants = [];
        this.thirdRowConstants = [];
        this.fourthRowConstants = [];
        this.fifhRowConstants = [];
        this.sixthRowConstants = [];

        index = 0;
        while (index < constants.length) {
            if (this.firstRowConstants.length == 6) { break; }
            const constKey = utils.getTextFrom(GetKeyFrom(constants[index]));
            const text = utils.minifyLine(utils.getTextFrom(GetKeyFrom(constants[index])), 5);
            this.firstRowConstants.push(<button key={index} name="" class="globalcalc globalcalcConstants" onClick={() => this.inputKey(constKey)}>{text}</button>)
            index += 1;
        }

        while (index < constants.length) {
            if (this.secondRowConstants.length == 6) { break; }
            const constKey = utils.getTextFrom(GetKeyFrom(constants[index]));
            const text = utils.minifyLine(utils.getTextFrom(GetKeyFrom(constants[index])), 5);
            this.secondRowConstants.push(<button name="" class="globalcalc globalcalcConstants" onClick={() => this.inputKey(constKey)}>{text}</button>)
            index += 1;
        }

        while (index < constants.length) {
            if (this.thirdRowConstants.length == 6) { break; }
            const constKey = utils.getTextFrom(GetKeyFrom(constants[index]));
            const text = GetKeyFrom(constants[index]);
            this.thirdRowConstants.push(<button name="" class="globalcalc globalcalcConstants" onClick={() => this.inputKey(constKey)}>{text}</button>)
            index += 1;
        }

        while (index < constants.length) {
            if (this.fourthRowConstants.length == 6) { break; }
            const constKey = utils.getTextFrom(GetKeyFrom(constants[index]));
            const text = utils.minifyLine(utils.getTextFrom(GetKeyFrom(constants[index])), 5);
            this.fourthRowConstants.push(<button name="" class="globalcalc globalcalcConstants" onClick={() => this.inputKey(constKey)}>{text}</button>)
            index += 1;
        }

        while (index < constants.length) {
            if (this.fifhRowConstants.length == 6) { break; }
            const constKey = utils.getTextFrom(GetKeyFrom(constants[index]));
            const text = utils.minifyLine(utils.getTextFrom(GetKeyFrom(constants[index])), 5);
            this.fifhRowConstants.push(<button name="" class="globalcalc globalcalcConstants" onClick={() => this.inputKey(constKey)}>{text}</button>)
            index += 1;
        }

        while (index < constants.length) {
            if (this.sixthRowConstants.length == 6) { break; }
            const constKey = utils.getTextFrom(GetKeyFrom(constants[index]));
            const text = utils.minifyLine(utils.getTextFrom(GetKeyFrom(constants[index])), 5);
            this.sixthRowConstants.push(<button name="" class="globalcalc globalcalcConstants" onClick={() => this.inputKey(constKey)}>{text}</button>)
            index += 1;
        }

        //-------------------------------------------Modal-------------------------------------------------------------------

        this.modalConstants = [];
        index = 0
        while (index < constants.length) {
            const c = index;

            const constantsKey = utils.getTextFrom(GetKeyFrom(constants[index]));
            const constantsVal = utils.minifyLine(utils.getTextFrom(utils.getValueFrom(constants[index])), 60);
            const up = <button className='btn btn-primary myBtnsBlue myBtnsBlueActPanel' onClick={() => this.moveAction(constants[c], "up")}>
                <i class="fa fa-arrow-up"></i>
            </button>
            const down = <button className='btn btn-primary myBtnsBlue myBtnsBlueActPanel' onClick={() => this.moveAction(constants[c], "down")}>
                <i class="fa fa-arrow-down"></i>
            </button>
            const top = <button className='btn btn-primary myBtnsBlue myBtnsBlueActPanel' onClick={() => this.moveAction(constants[c], "top")}>
                <i class="fa fa-angle-double-up"></i>
            </button>
            const bottom = <button className='btn btn-primary myBtnsBlue myBtnsBlueActPanel' onClick={() => this.moveAction(constants[c], "bottom")}>
                <i class="fa fa-angle-double-down"></i>
            </button>
            const info = <button className='btn btn-primary myBtnsBlue myBtnsBlueActPanel' onClick={() => this.openConstantInfo(constants[c])}>
                <i class="fa fa-ellipsis-h"></i>
            </button>

            this.modalConstants.push(<div><div class="funcConstDiv"><div name="" class="funcConstNamePanel">{constantsKey}</div>
                <div name="" class="funcConstExpPanel">{constantsVal}</div>
                <div class="funcConstButsPanel">{up}{down}{top}{bottom}{info}</div></div><hr class="myHRCF" /></div>)
            index += 1;

        }



        //------------------------------------- Fonctions ----------------------------------------------

        const func = utils.getAllCustomFunctions(calculator);

        this.firstRowFunc = [];
        this.secondRowFunc = [];
        this.thirdRowFunc = [];
        this.fourthRowFunc = [];
        this.fifhRowFunc = [];
        this.sixthRowFunc = [];

        index = 0;
        while (index < func.length) {
            if (this.firstRowFunc.length == 6) { break; }
            const funcKey = utils.getTextFrom(GetKeyFrom(func[index]));
            const text = utils.minifyLine(utils.getTextFrom(GetKeyFrom(func[index])), 5);
            this.firstRowFunc.push(<button name="" class="globalcalc globalcalcFunc" onClick={() => this.inputKey(funcKey)} onDoubleClick={() => this.inputKeyDouble(funcKey)}>{text}</button>)
            index += 1;
        }

        while (index < func.length) {
            if (this.secondRowFunc.length == 6) { break; }
            const funcKey = utils.getTextFrom(GetKeyFrom(func[index]));
            const text = utils.minifyLine(utils.getTextFrom(GetKeyFrom(func[index])), 5);
            this.secondRowFunc.push(<button name="" class="globalcalc globalcalcFunc" onClick={() => this.inputKey(funcKey)} onDoubleClick={() => this.inputKeyDouble(funcKey)}>{text}</button>)
            index += 1;
        }

        while (index < func.length) {
            if (this.thirdRowFunc.length == 6) { break; }
            const funcKey = utils.getTextFrom(GetKeyFrom(func[index]));
            const text = utils.minifyLine(utils.getTextFrom(GetKeyFrom(func[index])), 5);
            this.thirdRowFunc.push(<button name="" class="globalcalc globalcalcFunc" onClick={() => this.inputKey(funcKey)} onDoubleClick={() => this.inputKeyDouble(funcKey)}>{text}</button>)
            index += 1;
        }

        while (index < func.length) {
            if (this.fourthRowFunc.length == 6) { break; }
            const funcKey = utils.getTextFrom(GetKeyFrom(func[index]));
            const text = utils.minifyLine(utils.getTextFrom(GetKeyFrom(func[index])), 5);
            this.fourthRowFunc.push(<button name="" class="globalcalc globalcalcFunc" onClick={() => this.inputKey(funcKey)} onDoubleClick={() => this.inputKeyDouble(funcKey)}>{text}</button>)
            index += 1;
        }

        while (index < func.length) {
            if (this.fifhRowFunc.length == 6) { break; }
            const funcKey = utils.getTextFrom(GetKeyFrom(func[index]));
            const text = utils.minifyLine(utils.getTextFrom(GetKeyFrom(func[index])), 5);
            this.fifhRowFunc.push(<button name="" class="globalcalc globalcalcFunc" onClick={() => this.inputKey(funcKey)} onDoubleClick={() => this.inputKeyDouble(funcKey)}>{text}</button>)
            index += 1;
        }

        while (index < func.length) {
            if (this.sixthRowFunc.length == 6) { break; }
            const funcKey = utils.getTextFrom(GetKeyFrom(func[index]));
            const text = utils.minifyLine(utils.getTextFrom(GetKeyFrom(func[index])), 5);
            this.sixthRowFunc.push(<button name="" class="globalcalc globalcalcFunc" onClick={() => this.inputKey(funcKey)} onDoubleClick={() => this.inputKeyDouble(funcKey)}>{text}</button>)
            index += 1;
        }


        //-------------------------------------------Modal-------------------------------------------------------------------



        this.modalFunc = [];
        index = 0
        while (index < func.length) {

            const c = index;
            const funcKey = utils.getTextFrom(utils.getKeyFrom(func[index]));
            const funcExp = utils.minifyLine(utils.getTextFrom(utils.getExpressionFrom(func[index])), 60);
            const up = <button className='btn btn-primary myBtnsBlue myBtnsBlueActPanel' onClick={() => this.moveAction(func[c], "up")}>
                <i class="fa fa-arrow-up"></i>
            </button>
            const down = <button className='btn btn-primary myBtnsBlue myBtnsBlueActPanel' onClick={() => this.moveAction(func[c], "down")}>
                <i class="fa fa-arrow-down"></i>
            </button>
            const top = <button className='btn btn-primary myBtnsBlue myBtnsBlueActPanel' onClick={() => this.moveAction(func[c], "top")}>
                <i class="fa fa-angle-double-up"></i>
            </button>
            const bottom = <button className='btn btn-primary myBtnsBlue myBtnsBlueActPanel' onClick={() => this.moveAction(func[c], "bottom")}>
                <i class="fa fa-angle-double-down"></i>
            </button>
            const info = <button className='btn btn-primary myBtnsBlue myBtnsBlueActPanel' onClick={() => this.openFunctionInfo(func[c])}>
                <i class="fa fa-ellipsis-h"></i>
            </button>


            var calcState = [];

            if (utils.isFuncPublic(this.state.selectedCalculator, func[index])) {
                calcState = <i style={{
                    fontSize: "18px", marginLeft: "10px", color: "dodgerblue", display: "tableCell", verticalAlign: "middle" }} class="fa fa-globe"></i>;
            }


            const funcKey_= <div class="text-heading Absolute-Center font-semibold fontSizeNorm breakAllWordWS" >
                {funcKey}{calcState}</div>;

            this.modalFunc.push(<div><div class="funcConstDiv"><div name="" class="funcConstNamePanel">{funcKey_}</div>
                <div name="" class="funcConstExpPanel">{funcExp}</div>
                <div class="funcConstButsPanel">{up}{down}{top}{bottom}{info}</div></div><hr class="myHRCF" /></div>)
            index += 1;

        }
    };












    //-------------------------------------------------------------------------Calculatrices--------------------------------------------------------------------







    editCalculatorChangeHandler = (event) => {
        var calculatorEditState = this.state.calculatorEditState;

        if (event.target.id == "name") {
            calculatorEditState.name = event.target.value;
        }
        else if (event.target.id == "description") {
            calculatorEditState.description = event.target.value;
        }

        this.setState({ calculatorEditState: calculatorEditState });
    }


    confirmEditcalculator = (e) => {
        e.preventDefault();

        if (this.state.selectedCalculator.guid != "-landing" && this.state.selectedCalculator.guid != "-used") {
            const datas = {
                calculatorName: utils.getCodedFrom(this.state.calculatorEditState.name),
                calculatorGuid: this.state.selectedCalculator.guid,
                description: utils.getCodedFrom(this.state.calculatorEditState.description),
            };

            databasesActions.editCalculator(datas)
                .then(res => {
                    this.setState({ isConfirmEditCalcModalOpen: false });
                    if (res.errorHasOccurred != null) {
                        this.showAlertError("An error has occurred. Please try again.", "#alert-editCalcModal");
                    }
                    else {
                        this.state.selectedCalculator.name = datas.calculatorName;
                        this.state.selectedCalculator.description = datas.description;
                        this.updatePublicationsElCalc();
                        localStorage.setItem("selectedCalculator", JSON.stringify(this.state.selectedCalculator));
                        this.setState({ isEditCalcModalOpen: false });
                    }
                })
                .catch(err => {
                    this.setState({ isConfirmEditCalcModalOpen: false });
                    this.showAlertError("An error has occurred. Please try again.", "#alert-editCalcModal");
                });
        }

        else {
            const datas_ = {
                calculatorName: utils.getCodedFrom(this.state.calculatorEditState.name),
                description: utils.getCodedFrom(this.state.calculatorEditState.description),
            };

            this.state.selectedCalculator.name = datas_.calculatorName;
            this.state.selectedCalculator.description = datas_.description;
            this.updatePublicationsElCalc();
            localStorage.setItem("selectedCalculator", JSON.stringify(this.state.selectedCalculator));
            this.setState({ isEditCalcModalOpen: false });
        }
    };

    showEditCalcModal = () => {
        this.state.calculatorEditState = { name: utils.getTextFrom(this.state.selectedCalculator.name), description: utils.getTextFrom(this.state.selectedCalculator.description) };
        this.setState({
            isEditCalcModalOpen: true
        });
    };

    cancelEditCalculator = (e) => {

        if (e != null) {
            e.preventDefault();
        }

        this.setState({
            isEditCalcModalOpen: false
        });
    };

    requestConfirmEditCalculator = (e) => {
        e.preventDefault();
        if (this.state.selectedCalculator.isPublic == "true") {
            this.setState({
                isConfirmEditCalcModalOpen: true
            });
        }
        else {
            this.confirmEditcalculator(e);
        }
    };

    cancelRequestConfirmEditCalculator = () => {
        this.setState({
            isConfirmEditCalcModalOpen: false
        });
    };




    render() {

        this.refreshActions();

        const calculator = this.state.selectedCalculator;

        let subModalDialogStyles = {
            base: {
                bottom: -600,
                transition: 'bottom 0.4s'
            },
            open: {
                bottom: 0
            }
        };

        return (


            <div class="containercalc">
                <div class="headerdiv">


                    <button class="headercalc headercalcCustom globalcalcConstants" onClick={this.openConstantsModal}>Constants</button>
                    <button class="headercalc headercalcSpecials" onClick={this.openCalculatorModal}>{utils.getTextFrom(calculator.name)}</button>
                    <button class="headercalc headercalcCustom globalcalcFunc" onClick={this.openFunctionsModal}>Functions</button>
                </div>



                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}>

                    <div style={{ paddingLeft: "15px", width: "33%", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                        <p style={{ color: "#666262c2", fontSize: "14px" }}>{"\u2022 Click on the button above to manage constants"}</p>
                    </div>

                    <div class="resultContainer" style={{ width: "34%", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                        <input 
                            ref={(input) => { this.keyInput = input; }} 
                            type="text" style={{
                            margin: "5px 5px 10px 5px", width: "92%", display: "flex", alignItems: "center", justifyContent: "space-around",
                                height: "72.5px"
                            }} name="result" value={this.state.calcField} onChange={this.calcFieldChanged} className="browser-default resultcalc"
                            onKeyDown={this.equalKey}/>
                    </div>
                    <div style={{ width: "33%", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                        <p style={{ color: "#666262c2", fontSize: "14px" }}>{"\u2022 Click on the button above to manage functions"}<div style = {{ width: "auto", height: "6px" }} /><div />
                            {"\u2022 Double click on a function to enter parameters"} </p>  
                    </div>



                    </div>




                <div class="calcDiv">

                    <div class="calcCustomActionsContainer">
                        <div class="custom-row">
                            {this.firstRowConstants}
                        </div>

                        <div class="custom-row">
                            {this.secondRowConstants}
                        </div>

                        <div class="custom-row">
                            {this.thirdRowConstants}
                        </div>

                        <div class="custom-row">
                            {this.fourthRowConstants}
                        </div>

                        <div class="custom-row">
                            {this.fifhRowConstants}
                        </div>

                        <div class="custom-row">
                            {this.sixthRowConstants}
                        </div>
                    </div>





                    <div class="calcActionsContainer">
                        <div class="custom-row">
                            {this.firstRow}
                        </div>

                        <div class="custom-row">
                            {this.secondRow}
                        </div>

                        <div class="custom-row">
                            {this.thirdRow}
                        </div>

                        <div class="custom-row">
                            {this.fourthRow}
                        </div>

                        <div class="custom-row">
                            {this.fifhRow}
                        </div>

                        <div class="custom-row">
                            {this.sixthRow}
                        </div>
                    </div>



                    <div class="calcCustomActionsContainer">
                        <div class="custom-row">
                            {this.firstRowFunc}
                        </div>

                        <div class="custom-row">
                            {this.secondRowFunc}
                        </div>

                        <div class="custom-row">
                            {this.thirdRowFunc}
                        </div>

                        <div class="custom-row">
                            {this.fourthRowFunc}
                        </div>

                        <div class="custom-row">
                            {this.fifhRowFunc}
                        </div>

                        <div class="custom-row">
                            {this.sixthRowFunc}
                        </div>
                    </div>



                </div>






                <Modal isOpen={this.state.isCalculatorModalOpen} size='modal-lg' onRequestHide={this.hideCalculatorModal}>
                    {this.calcInfoHeader}
                    <ModalBody>
                        <div style={{
                            wordBreak: "break-word",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>{utils.getTextFrom(this.state.selectedCalculator.description)}</div>
                        <div id="alert-areaCalcModal" class="alert-area"></div>


                        <Modal isOpen={this.state.isEditCalcModalOpen} onRequestHide={this.cancelEditCalculator} dialogStyles={subModalDialogStyles}>
                            <div class="myFormDivContainer">
                                <form class="myFormContainer" id="contact" action="" onSubmit={this.requestConfirmEditCalculator}>
                                    <fieldset>
                                        <input class="myFormContainer" placeholder="Name" id="name" maxlength="1000" type="text" tabindex="1" onChange={this.editCalculatorChangeHandler} required value={this.state.calculatorEditState.name} autofocus>
                                            </input>
                                    </fieldset>
                                    <fieldset>
                                        <textarea placeholder="Description (Optional)" id="description" maxlength="1000" tabindex="2" onChange={this.editCalculatorChangeHandler} value={this.state.calculatorEditState.description} ></textarea>
                                    </fieldset>


                                    <fieldset>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}>
                                        <button name="submit" type="submit" id="contact-submit" style={{width: "50%"}}>Confirm</button>
                                            <button class="formButton" style={{ width: "45%" }} onClick={this.cancelEditCalculator}>Cancel</button>
                                            </div>
                                    </fieldset>

                                </form>
                            </div>


                            <Modal isOpen={this.state.isConfirmEditCalcModalOpen} onRequestHide={this.cancelRequestConfirmEditCalculator} dialogStyles={subModalDialogStyles}>
                                <ModalBody>
                                <div style={{
                                    margin: "25px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                    flexDirection: "column"
                                }}>
                                        <div>{"The selected calculator is public, so an update will be performed. Do you want to proceed?"}<br /><br /></div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <button class="btn btn-default myBtns" onClick={this.confirmEditcalculator}>Yes</button>
                                            <button class="btn btn-default myBtns" onClick={this.cancelRequestConfirmEditCalculator}>No</button><br /></div></div>
                                </ModalBody>
                                </Modal>

                            <div id="alert-editCalcModal" class="alert-area"></div>
                        </Modal>


                    </ModalBody>
                    <ModalFooter>
                        {this.publicationZoneCalc}
                        <button className='btn btn-default myBtns' onClick={this.showEditCalcModal}>
                            Edit
                </button>
                        <button className='btn btn-default myBtns' onClick={this.hideCalculatorModal}>
                            Close
                </button>
                    </ModalFooter>
                </Modal>












                <Modal isOpen={this.state.isFunctionsModalOpen} size='modal-lg' onRequestHide={this.hideFunctionsModal}>


                    <ModalHeader>
                        <h4 class="modal-title centerEl">Functions</h4>
                        <ModalClose onClick={this.hideFunctionsModal} />
                    </ModalHeader>
                    <ModalBody>

                        <button className='btn btn-default myBtns' onClick={this.createAFunction}>
                            Create a Function
                </button>
                        <hr />


                        <div>{this.modalFunc}</div>


                        <Modal isOpen={this.state.newFunctionModal} onRequestHide={this.cancelCreateAFunction} dialogStyles={subModalDialogStyles}>
                            <div class="myFormDivContainer">
                                <form class="myFormContainer" id="contact" action="" onSubmit={this.requestConfirmCreateAction}>
                                    <fieldset>
                                        <input class="myFormContainer" placeholder={this.keyPlaceholder} id="key" maxlength="15" type="text" tabindex="1" onChange={this.createActionChangeHandler} required value={this.state.newAction.key} autofocus />
                                    </fieldset>
                                    <fieldset>
                                        <input class="myFormContainer" placeholder="Enter parameters comma-separated" id="params" maxlength="1000" type="text" tabindex="2" onChange={this.createActionChangeHandler} required value={this.state.newAction.params}  />
                                    </fieldset>
                                    <fieldset>
                                        <input class="myFormContainer" placeholder="Expression" id="expressionOrValue" maxlength="1000" type="text" tabindex="3" onChange={this.createActionChangeHandler} value={this.state.newAction.expressionOrValue} required />
                                    </fieldset>
                                    <fieldset>
                                        <textarea placeholder="Description (Optional)" id="description" maxlength="1000" tabindex="4" onChange={this.createActionChangeHandler} value={this.state.newAction.description} ></textarea>
                                    </fieldset>


                                    <fieldset>
                                        <button name="submit" type="submit" id="contact-submit">Submit</button>
                                    </fieldset>

                                </form>
                            </div>

                            <Modal isOpen={this.state.isConfirmCreateActionModalOpen} onRequestHide={this.cancelRequestConfirmCreateAction} dialogStyles={subModalDialogStyles}>
                                <ModalBody>
                                    <div style={{
                                        margin: "25px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        flexDirection: "column"
                                    }}>
                                        <div>{"The calculator you are using is public, so an update will be performed. Do you want to proceed?"}<br /><br /></div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <button class="btn btn-default myBtns" onClick={this.confirmCreateAction}>Yes</button>
                                            <button class="btn btn-default myBtns" onClick={this.cancelRequestConfirmCreateAction}>No</button><br /></div></div>
                                </ModalBody>
                            </Modal>

                            <Modal isOpen={this.state.isErrorActionModalOpen} onRequestHide={this.closeErrorActionModal} dialogStyles={subModalDialogStyles}>
                                <ModalBody>
                                    <ModalClose onClick={this.closeErrorActionModal} />
                                    <div style={{
                                        margin: "25px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        flexDirection: "column"
                                    }}>
                                        <div style={{ color: "red" }}>Error</div><br />
                                        <p style={{ whiteSpace: 'break-spaces', fontSize: "16px", color: "black" }}>{this.errorActionModalText}</p>
                                        </div>
                                </ModalBody>
                            </Modal>

                            <div id="alert-createActionModal" class="alert-area"></div>

                        </Modal>



                        <Modal isOpen={this.state.isFunctionInfoModalOpen} size='modal-lg' onRequestHide={this.hideFunctionInfo}>
                            {this.functionInfo}


                            <Modal isOpen={this.state.isConfirmUpublishFuncModalOpen} onRequestHide={this.cancelUnpublishFunc} dialogStyles={subModalDialogStyles}>
                                <ModalBody>
                                    <ModalClose onClick={this.cancelUnpublishFunc} />
                                    {this.confirmUnpublishFuncLayout}
                                </ModalBody>
                            </Modal>


                            <Modal isOpen={this.state.isConfirmDeleteActionModalOpen} onRequestHide={this.cancelDelAction} dialogStyles={subModalDialogStyles}>
                                <ModalBody>
                                    <div style={{
                                        margin: "25px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        flexDirection: "column"
                                    }}>
                                        <div>{"Please confirm"}<br /><br /></div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <button class="btn btn-default myBtns" onClick={this.confirmDeleteAction}>Yes</button>
                                            <button class="btn btn-default myBtns" onClick={this.cancelDelAction}>No</button><br /></div></div>
                                </ModalBody>
                            </Modal>

                            <Modal isOpen={this.state.isEditActionModalOpen} onRequestHide={this.cancelEditAction} dialogStyles={subModalDialogStyles}>
                                <div class="myFormDivContainer">
                                    <form class="myFormContainer" id="contact" action="" onSubmit={this.requestConfirmEditAction}>
                                        <fieldset>
                                            <input class="myFormContainer" placeholder={this.keyPlaceholder} id="key" maxlength="15" type="text" tabindex="1" onChange={this.editActionChangeHandler} required value={this.state.editStateAction.key} autofocus />
                                        </fieldset>
                                        <fieldset>
                                            <input class="myFormContainer" placeholder="Enter parameters comma-separated" id="params" maxlength="1000" type="text" tabindex="2" onChange={this.editActionChangeHandler} required value={this.state.editStateAction.params}  />
                                        </fieldset>
                                        <fieldset>
                                            <input class="myFormContainer" placeholder="Expression" id="expressionOrValue" maxlength="1000" type="text" tabindex="3" onChange={this.editActionChangeHandler} required value={this.state.editStateAction.expressionOrValue} />
                                        </fieldset>
                                        <fieldset>
                                            <textarea placeholder="Description (Optional)" id="description" maxlength="1000" tabindex="4" onChange={this.editActionChangeHandler} value={this.state.editStateAction.description} ></textarea>
                                        </fieldset>


                                        <fieldset>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}>
                                                <button name="submit" type="submit" id="contact-submit" style={{ width: "50%" }}>Confirm</button>
                                                <button class="formButton" style={{ width: "45%" }} onClick={this.cancelEditAction}>Cancel</button>
                                            </div>
                                        </fieldset>

                                    </form>
                                </div>


                                <Modal isOpen={this.state.isConfirmEditActionModalOpen} onRequestHide={this.cancelRequestConfirmEditAction} dialogStyles={subModalDialogStyles}>
                                    <ModalBody>
                                        <div style={{
                                            margin: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                            flexDirection: "column"
                                        }}>
                                            <div>{"This function belongs to a public calculator, so an update will be performed. Do you want to proceed?"}<br /><br /></div>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <button class="btn btn-default myBtns" onClick={this.confirmEditAction}>Yes</button>
                                                <button class="btn btn-default myBtns" onClick={this.cancelRequestConfirmEditAction}>No</button><br /></div></div>
                                    </ModalBody>
                                </Modal>

                                <Modal isOpen={this.state.isConfirmEditPublicActionModalOpen} onRequestHide={this.cancelRequestConfirmEditPublicAction} dialogStyles={subModalDialogStyles}>
                                    <ModalBody>
                                        <div style={{
                                            margin: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                            flexDirection: "column"
                                        }}>
                                            <div>{"The selected function is public, so an update will be performed. Do you want to proceed?"}<br /><br /></div>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <button class="btn btn-default myBtns" onClick={this.confirmEditAction}>Yes</button>
                                                <button class="btn btn-default myBtns" onClick={this.cancelRequestConfirmEditPublicAction}>No</button><br /></div></div>
                                    </ModalBody>
                                </Modal>

                                <Modal isOpen={this.state.isErrorActionModalOpenEdit} onRequestHide={this.closeErrorActionModal} dialogStyles={subModalDialogStyles}>
                                    <ModalBody>
                                        <ModalClose onClick={this.closeErrorActionModal} />
                                        <div style={{
                                            margin: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                            flexDirection: "column"
                                        }}>
                                            <div style={{ color: "red" }}>Error</div><br />
                                            <p style={{ whiteSpace: 'break-spaces', fontSize: "16px", color: "black" }}>{this.errorActionModalText}</p></div>
                                    </ModalBody>
                                </Modal>

                                <div id="alert-editActionModal" class="alert-area"></div>
                            </Modal>

                            <div id="alert-viewFunctionInfoModal" class="alert-area"></div>
                        </Modal>

                    </ModalBody>


                    <ModalFooter>
                        <button className='btn btn-default myBtns' onClick={this.hideFunctionsModal}>
                            Close
                </button>
                    </ModalFooter>
                    <div id="alert-funcInfoModal" class="alert-area"></div>
                </Modal>









                <Modal isOpen={this.state.isConstantsModalOpen} size='modal-lg' onRequestHide={this.hideConstantsModal}>


                    <ModalHeader>
                        <h4 class="modal-title centerEl">Constants</h4>
                        <ModalClose onClick={this.hideConstantsModal} />
                    </ModalHeader>
                    <ModalBody>

                        <button className='btn btn-default myBtns' onClick={this.createAConstant}>
                            Create a Constant
                </button>
                        <hr />


                        <div>{this.modalConstants}</div>


                        <Modal isOpen={this.state.newConstantModal} onRequestHide={this.cancelCreateAConstant} dialogStyles={subModalDialogStyles}>
                            <div class="myFormDivContainer">
                                <form class="myFormContainer" id="contact" action="" onSubmit={this.requestConfirmCreateAction}>
                                    <fieldset>
                                        <input class="myFormContainer" placeholder={this.keyPlaceholder} id="key" maxlength="15" type="text" tabindex="1" onChange={this.createActionChangeHandler} required value={this.state.newAction.key} autofocus />
                                    </fieldset>
                                    <fieldset>
                                        <input class="myFormContainer" placeholder="Value" id="expressionOrValue" maxlength="1000" type="text" tabindex="3" onChange={this.createActionChangeHandler} value={this.state.newAction.expressionOrValue} required />
                                    </fieldset>
                                    <fieldset>
                                        <textarea placeholder="Description (Optional)" id="description" maxlength="1000" tabindex="4" onChange={this.createActionChangeHandler} value={this.state.newAction.description} ></textarea>
                                    </fieldset>


                                    <fieldset>
                                        <button name="submit" type="submit" id="contact-submit">Submit</button>
                                    </fieldset>

                                </form>
                            </div>

                            <Modal isOpen={this.state.isConfirmCreateActionModalOpen_Const} onRequestHide={this.cancelRequestConfirmCreateAction} dialogStyles={subModalDialogStyles}>
                                <ModalBody>
                                    <div style={{
                                        margin: "25px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        flexDirection: "column"
                                    }}>
                                        <div>{"The calculator you are using is public, so an update will be performed. Do you want to proceed?"}<br /><br /></div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <button class="btn btn-default myBtns" onClick={this.confirmCreateAction}>Yes</button>
                                            <button class="btn btn-default myBtns" onClick={this.cancelRequestConfirmCreateAction}>No</button><br /></div></div>
                                </ModalBody>
                            </Modal>

                            <Modal isOpen={this.state.isErrorActionModalOpen_Const} onRequestHide={this.closeErrorActionModal} dialogStyles={subModalDialogStyles}>
                                <ModalBody>
                                    <ModalClose onClick={this.closeErrorActionModal} />
                                    <div style={{
                                        margin: "25px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        flexDirection: "column"
                                    }}>
                                        <div style={{ color: "red" }}>Error</div><br />
                                        <p style={{ whiteSpace: 'break-spaces', fontSize: "16px", color: "black" }}>{this.errorActionModalText}</p></div>
                                </ModalBody>
                            </Modal>
                            <div id="alert-createActionModal" class="alert-area"></div>

                        </Modal>


                        <Modal isOpen={this.state.isConstantInfoModalOpen} size='modal-lg' onRequestHide={this.hideConstantInfo}>
                            {this.constantInfo}

                            <Modal isOpen={this.state.isConfirmDeleteActionModalOpen_Const} onRequestHide={this.cancelDelAction} dialogStyles={subModalDialogStyles}>
                                <ModalBody>
                                    <div style={{
                                        margin: "25px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        flexDirection: "column"
                                    }}>
                                        <div>{"Please confirm"}<br /><br /></div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <button class="btn btn-default myBtns" onClick={this.confirmDeleteAction}>Yes</button>
                                            <button class="btn btn-default myBtns" onClick={this.cancelDelAction}>No</button><br /></div></div>
                                </ModalBody>
                            </Modal>

                            <Modal isOpen={this.state.isEditActionModalOpen_Const} onRequestHide={this.cancelEditAction} dialogStyles={subModalDialogStyles}>
                                <div class="myFormDivContainer">
                                    <form class="myFormContainer" id="contact" action="" onSubmit={this.requestConfirmEditAction}>
                                        <fieldset>
                                            <input class="myFormContainer" placeholder={this.keyPlaceholder} id="key" maxlength="15" type="text" tabindex="1" onChange={this.editActionChangeHandler} required value={this.state.editStateAction.key} autofocus />
                                        </fieldset>
                                        <fieldset>
                                            <input class="myFormContainer" placeholder="Value" id="expressionOrValue" maxlength="1000" type="text" tabindex="3" onChange={this.editActionChangeHandler} required value={this.state.editStateAction.expressionOrValue} />
                                        </fieldset>
                                        <fieldset>
                                            <textarea placeholder="Description (Optional)" id="description" maxlength="1000" tabindex="4" onChange={this.editActionChangeHandler} value={this.state.editStateAction.description} ></textarea>
                                        </fieldset>


                                        <fieldset>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}>
                                                <button name="submit" type="submit" id="contact-submit" style={{ width: "50%" }}>Confirm</button>
                                                <button class="formButton" style={{ width: "45%" }} onClick={this.cancelEditAction}>Cancel</button>
                                            </div>
                                        </fieldset>

                                    </form>
                                </div>


                                <Modal isOpen={this.state.isConfirmEditActionModalOpen_Const} onRequestHide={this.cancelRequestConfirmEditAction} dialogStyles={subModalDialogStyles}>
                                    <ModalBody>
                                        <div style={{
                                            margin: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                            flexDirection: "column"
                                        }}>
                                            <div>{"This constant belongs to a public calculator, so an update will be performed. Do you want to proceed?"}<br /><br /></div>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <button class="btn btn-default myBtns" onClick={this.confirmEditAction}>Yes</button>
                                                <button class="btn btn-default myBtns" onClick={this.cancelRequestConfirmEditAction}>No</button><br /></div></div>
                                    </ModalBody>
                                </Modal>

                                <Modal isOpen={this.state.isErrorActionModalOpenEdit_Const} onRequestHide={this.closeErrorActionModal} dialogStyles={subModalDialogStyles}>
                                    <ModalBody>
                                        <ModalClose onClick={this.closeErrorActionModal} />
                                        <div style={{
                                            margin: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                            flexDirection: "column"
                                        }}>
                                            <div style={{ color: "red" }}>Error</div><br />
                                            <p style={{ whiteSpace: 'break-spaces', fontSize: "16px", color: "black" }}>{this.errorActionModalText}</p></div>
                                    </ModalBody>
                                </Modal>


                                <div id="alert-editActionModal" class="alert-area"></div>
                            </Modal>

                            <div id="alert-viewFunctionInfoModal" class="alert-area"></div>
                        </Modal>





                    </ModalBody>


                    <ModalFooter>
                        <button className='btn btn-default myBtns' onClick={this.hideConstantsModal}>
                            Close
                </button>
                    </ModalFooter>
                    <div id="alert-constInfoModal" class="alert-area"></div>
                </Modal>



                <Modal isOpen={this.state.errorComputingModalOpen} onRequestHide={this.closeErrorComputingModal} dialogStyles={subModalDialogStyles}>
                <ModalBody>
                    <ModalClose onClick={this.closeErrorComputingModal} />
                    <div style={{
                        margin: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        flexDirection: "column"
                    }}>
                            <div style={{ color: "red" }}>Error</div><br />
                            <p style={{ whiteSpace: 'break-spaces', fontSize: "16px", color: "black" }}>{this.errorComputingText}</p></div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.isParametersCalcOpen} onRequestHide={this.closeParametersCalc} dialogStyles={subModalDialogStyles}>
                    <div class="myFormDivContainer">
                        <form class="myFormContainer" id="contact" action="" onSubmit={this.confirmParametersCalc}>
                            {this.paramsModal}
                            <fieldset>
                                <button name="submit" type="submit" id="contact-submit">Confirm</button>
                            </fieldset>

                        </form>
                    </div>

                    <div id="alert-baseAreaModal" class="alert-area"></div>

                </Modal>

            </div>



        );
    }
}

function GetKeyFrom(action) {
    if (action == "?") {
        return "?";
    }
    var params = action.split(";");
    return params[2].split("=")[1].trim();
}

function GetSizeFrom(action) {
    if (action == "?") {
        return "?";
    }
    var params = action.split(";");
    return params[3].split("=")[1].trim();;
}

function getLandingCalculator() {
    var toReturn = {
        name: "Calculator",
        guid: "-landing",
        description: "This is the default calculator. To create a new one, please login and select: New Calculator, on the navigation bar",
        authorGuid: "-noAuthor",
        originalGuid: "",
        isPublic: "false",
        publicationDatas: {},
        actions: getCalcBaseActions()
    };
    return toReturn;
}

function getCalcBaseActions() {
    return [
        "type = other; special = true; key =_itscodedClear; size = 2",
        "type = other; special = true; key =_itscoded\u21b6; size = 1",
        "type = func; special = true; key =_itscoded\u221a; size = 1; params=x; expression = notUsed; description = ",
        "type = other; special = true; key =_itscoded(; size = 1",
        "type = other; special = true; key =_itscoded); size = 1",
        "type = const; special = true; key =_itscoded7; size = 1; value =7; description = ",
        "type = const; special = true; key =_itscoded8; size = 1; value =8; description = ",
        "type = const; special = true; key =_itscoded9; size = 1; value =9; description = ",
        "type = operator; special = true; key =_itscoded/; size = 1",
        "type = const; special = true; key =_itscoded\u03c0; size = 1; value =3.14159265359; description = ",
        "type = const; special = true; key =_itscodede; size = 1; value =2.71828182846; description = ",
        "type = const; special = true; key =_itscoded4; size = 1; value =4; description = ",
        "type = const; special = true; key =_itscoded5; size = 1; value =5; description = ",
        "type = const; special = true; key =_itscoded6; size = 1; value =6; description = ",
        "type = operator; special = true; key =_itscoded*; size = 1",
        "type = func; special = true; key =_itscodedsin; size = 1; params=x; expression = notUsed; description = ",
        "type = func; special = true; key =_itscodedsin\u207b\u00b9; size = 1; params=x; expression = notUsed; description = ",
        "type = const; special = true; key =_itscoded1; size = 1; value =1; description = ",
        "type = const; special = true; key =_itscoded2; size = 1; value =2; description = ",
        "type = const; special = true; key =_itscoded3; size = 1; value =3; description = ",
        "type = operator; special = true; key =_itscoded-; size = 1",
        "type = func; special = true; key =_itscodedcos; size = 1; params=x; expression = notUsed; description = ",
        "type = func; special = true; key =_itscodedcos\u207b\u00b9; size = 1; params=x; expression = notUsed; description = ",
        "type = const; special = true; key =_itscoded0; size = 1; value =0; description = ",
        "type = other; special = true; key =_itscoded.; size = 1",
        "type = other; special = true; key =_itscoded,; size = 1",
        "type = operator; special = true; key =_itscoded+; size = 1",
        "type = func; special = true; key =_itscodedtan; size = 1; params=x; expression = notUsed; description = ",
        "type = func; special = true; key =_itscodedtan\u207b\u00b9; size = 1; params=x; expression = notUsed; description = ",
        "type = func; special = true; key =_itscoded!; size = 1; params=x; expression = fact(x); description = ",
        "type = other; special = true; key =_itscodedequal; size = 2",
        "type = func; special = true; key =_itscodedx\u00b2; size = 1; params=x; expression = notUsed; description = ",
        "type = func; special = true; key =_itscodedx\u00b3; size = 1; params=x; expression = notUsed; description = ",
        "type = func; special = true; key =_itscodedx\u207f; size = 1; params=x,n; expression = notUsed; description = "]
}


Landing.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    {}
)(Landing);


var AlertBox = function (id, option, success) {
    this.show = function (msg) {
        if (msg === '' || typeof msg === 'undefined' || msg === null) {
            throw '"msg parameter is empty"';
        }
        else {
            var alertArea = document.querySelector(id);
            var alertBox = document.createElement('DIV');
            var alertContent = document.createElement('DIV');
            var alertClose = document.createElement('A');
            var alertClass = this;
            alertContent.classList.add('alert-content');
            alertContent.innerText = msg;
            alertClose.classList.add('alert-close');
            if (success) {
                alertBox.classList.add('alert-box-green');
            }
            else {
                alertBox.classList.add('alert-box-red');
            }
            alertBox.appendChild(alertContent);
            if (!option.hideCloseButton || typeof option.hideCloseButton === 'undefined') {
                alertBox.appendChild(alertClose);
            }
            alertArea.appendChild(alertBox);
            alertClose.addEventListener('click', function (event) {
                event.preventDefault();
                alertClass.hide(alertBox);
            });
            if (!option.persistent) {
                var alertTimeout = setTimeout(function () {
                    alertClass.hide(alertBox);
                    clearTimeout(alertTimeout);
                }, option.closeTime);
            }
        }
    };

    this.hide = function (alertBox) {
        alertBox.classList.add('hide');
        var disperseTimeout = setTimeout(function () {
            if (alertBox.parentNode != null) {
                alertBox.parentNode.removeChild(alertBox);
                clearTimeout(disperseTimeout);
            }

        }, 500);
    };
};
