import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { databasesActions, utils } from "../../actions/generalActions";
import logoFileDownload from "../../css/logoFileDownload.png"

/*
import {
    Modal,
    ModalHeader,
    ModalTitle,
    ModalClose,
    ModalBody,
    ModalFooter
} from "./react-modal-bootstrap/";
*/

var reactMB = require("react-modal-bootstrap");
var Modal = reactMB.Modal;
var ModalHeader = reactMB.ModalHeader;
var ModalTitle = reactMB.ModalTitle;
var ModalClose = reactMB.ModalClose;
var ModalBody = reactMB.ModalBody;
var ModalFooter = reactMB.ModalFooter;


class MyCalculators extends Component {
   
    constructor() {
        super();

        this.useCalculator = this.useCalculator.bind(this);

        this.allUserCalculators = {};
        this.state =
        {
            fetching: true,
            isCalcInfoModalOpen: false,
            isConfirmDeleteCalcOpen: false,
            isCreateAcalculatorModalOpen: false,
            isErrorSubModalOpen: false,
            isErrorSubModalCreateCalcOpen: false,
            newCalculator: {
                name: "",
                description: ""
            },
            showing: "calculators"
        }
        this.rows = [];
        this.headerEl = [];
        this.headerTable = [];

        this.showCalcInfo = this.showCalcInfo.bind(this);
        this.deleteCalc = this.deleteCalc.bind(this);

    }

    componentDidMount() {

        document.title = "My Calculators";

        databasesActions.setAllUserCalculators({ authorGuid: this.props.auth.guid }).
            then(res => {
                const { allUserCalculators } = res.data;
                this.allUserCalculators = allUserCalculators;

                this.confPage("calculators");
                this.setState({ fetching: false });
            })
            .catch(err =>
                console.log(err)
            );
    }

    showAlertSuccess = (message, area) => {
        var alertbox = new AlertBox(area, {
            closeTime: 3500,
            persistent: false,
            hideCloseButton: false
        }, true);

        alertbox.show(message);
    }


    showAlertError = (message, area) => {
        var alertbox = new AlertBox(area, {
            closeTime: 3500,
            persistent: false,
            hideCloseButton: false
        }, false);

        alertbox.show(message);
    }

    confPage = (toShow) => {
        this.headerEl = [];
        this.rows = [];
        this.headerTable = [];

        if (toShow == "calculators") {

            this.headerTable = <tr class="myTRCalc">
                <th scope="col" class="myTHNameCalc">Name</th>
                <th scope="col" class="descMycalc">Description</th>
                <th scope="col" class="collectionsMycalc">Collections</th>
                <th scope="col" class="myTHbutsExecCalc"></th>
            </tr>;

            var calcRender = {};

            const textHeader = <div class="textHeaderMyCalc"><h3 className="customProps mb-2">All added calculators</h3></div>
            const showingBut = <div class="butHeaderMyCalc"><button class="btn btn-default myBtns" onClick={this.showPublicFunctions}>Show published functions</button></div>;
            const newCalcBut = <div class="butHeaderMyCalc"><button class="btn btn-default myBtns" onClick={this.createAcalculator}>New Calculator</button></div>
            this.headerEl.push(
                <React.Fragment>
                    {showingBut}
                    {textHeader}
                    {newCalcBut}
                </React.Fragment>);


            for (var i = 0; i < this.allUserCalculators.length; i++) {

                const c = i;

                const calc = this.allUserCalculators[i];

                var calcState = {};

                if (calc.isPublic == "true") {
                    calcState = <i style={{
                        fontSize: "18px", marginLeft: "10px", color: "dodgerblue", display: "tableCell", verticalAlign: "middle" }} class="fa fa-globe"></i>;
                }

                else if (calc.originalGuid != "") {
                    calcState = <img style={{ width: "15px", marginLeft: "10px" }} src={logoFileDownload}></img>
                    if (calc.publicationDatas.updated == "false") {
                        calcState = <React.Fragment><img style={{ width: "15px", marginLeft: "10px" }} src={logoFileDownload}></img>
                            <i style={{
                                marginLeft: "10px", fontSize: "23px", color: "#f9e401", display: "tableCell",
                                verticalAlign: "middle" }} class="fa fa-exclamation-circle"></i></React.Fragment>
                    }

                }

                else {
                    calcState = []
                }


                const calcNameText = <div class="text-heading Absolute-Center font-semibold fontSizeNorm breakAllWordWS" >
                    {utils.minify(utils.getTextFrom(calc.name), 80)}{calcState}</div>;
                const calcName = <React.Fragment ><td class="nameMyCalc" data-label="Name">{calcNameText}
                    </td></React.Fragment>

                var calcConstants = utils.getNumberOfCustomConstants(calc);
                var collectionsText = "";
                if (calcConstants == 0) {
                    collectionsText += "No constants, ";
                }
                else if (calcConstants == 1) {
                    collectionsText = "1 constant, ";
                }
                else {
                    collectionsText += calcConstants + " constants, "
                }

                var calcFunctions = utils.getNumberOfCustomFunctions(calc);
                if (calcFunctions == 0) {
                    collectionsText += "No functions";
                }
                else if (calcFunctions == 1) {
                    collectionsText += "1 function";
                }
                else {
                    collectionsText += calcFunctions + " functions"
                }

                calcRender = <React.Fragment><tr class="myTRCalc">{calcName}<td class="descMycalc fontSizeNorm theCenterAlignVert" data-label="Description">
                    <span class="breakAllWordWS">{utils.minify(utils.getTextFrom(calc.description), 230)}</span>
                </td>
                    <td class="collectionsMycalc fontSizeNorm theCenterAlignVert" data-label="Collections">
                        <span>{collectionsText}</span>
                    </td>
                    <td class="butsExecMycalc" data-label="Actions">
                        <button className='btn btn-default myBtnsInfCalcs' onClick={() => this.showCalcInfo(this.allUserCalculators[c])}>
                            More
            </button>
                        <button className='btn btn-default myBtnsInfCalcs' onClick={() => this.useCalculator(this.allUserCalculators[c])}>
                            Use
            </button>
                    </td></tr></React.Fragment>;
                this.rows.push(calcRender);

            }
        }
        else {

            this.headerTable = <tr class="myTRCalc">
                <th scope="col" class="myTHNameFunc">Name</th>
                <th scope="col" class="myTHNameCalcFunc">Calculator</th>
                <th scope="col" class="myTHNamePublPage"></th>
            </tr>;

            var calcRender = {};

            const textHeader = <div class ="textHeaderMyCalc"><h3 className="customProps mb-2">All public functions</h3></div>
            const showingBut = <div class="butHeaderMyCalc"><button class= "btn btn-default myBtns" onClick={this.showCalculators}>Show calculators</button></div>;
            const newCalcBut = <div class="butHeaderMyCalc"><button class="btn btn-default myBtns" onClick={this.createAcalculator}>New Calculator</button></div>
            this.headerEl.push(
                <React.Fragment>
                    {showingBut}
                    {textHeader}
                    {newCalcBut}
                </React.Fragment>);

            var allPublicFunctionsArr = [];
            for (var i = 0; i < this.allUserCalculators.length; i++) {
                for (var j = 0; j < this.allUserCalculators[i].actions.length; j++) {
                    if (utils.isFuncPublic(this.allUserCalculators[i], this.allUserCalculators[i].actions[j])) {
                        allPublicFunctionsArr.push({
                            funcKey: utils.getKeyFrom(this.allUserCalculators[i].actions[j]), calcName: this.allUserCalculators[i].name,
                            calcGuid: this.allUserCalculators[i].guid});
                    }
                }
            }

            for (var i = 0; i < allPublicFunctionsArr.length; i++) {

                const funcAct = allPublicFunctionsArr[i];
                const funcKeyText = <div class="text-heading theCenterAlignVert font-semibold fontSizeNorm breakAllWordWS" >
                    {utils.getTextFrom(funcAct.funcKey)}</div>;
                const funcKey = <React.Fragment ><td class="nameFuncMyCalc" data-label="Name">{funcKeyText}</td></React.Fragment>

                //--------------------------------------------------minify (ligne de note)

                calcRender = <React.Fragment><tr class="myTRCalc">{funcKey}<td class="calcFuncMycalc fontSizeNorm theCenterAlignVert" data-label="Calculator">
                    <span class="breakAllWordWS">{utils.minify(utils.getTextFrom(funcAct.calcName), 230)}</span>
                </td>
                    <td class="pubPageMycalc fontSizeNorm theCenterAlignVert" data-label="Collections">
                        <a href={"/assetPage?id=" + funcAct.calcGuid + "&f=" + utils.getTextFrom(funcAct.funcKey)}>Go to public page</a>
                    </td>
                </tr></React.Fragment>;
                this.rows.push(calcRender);

            }
        }
    };

    showCalculators = () => {
        this.confPage("calculators");
        this.setState({ showing: "calculators" });
    };

    showPublicFunctions = () => {
        this.confPage("publicFunctions");
        this.setState({ showing: "publicFunctions" });
    };

    showCalcInfo = (theCalc) => {

        this.calcInfoLayout = []

        const header = <ModalHeader>
            <h4 class="modal-title centerEl paddingModalTitle">{utils.getTextFrom(theCalc.name)}</h4>
            <ModalClose onClick={this.closeCalcInfo} />
        </ModalHeader>;

        var calcState = []
        var calcPub = []

        if (theCalc.isPublic == "true") {
            calcState = <i class="fa fa-globe marginHeader blueColorStyle" ></i>;
            calcPub = <div><a class="spacingCenterStyle" href={"/assetPage?id=" + theCalc.guid}>Go to public page</a></div>
        }

        else if (theCalc.originalGuid != "") {
            calcState = <img style={{ marginLeft: "25px", width: "25px" }} src={logoFileDownload}></img>
            calcPub = <div><a class="spacingCenterStyle" href={"/assetPage?id=" + theCalc.originalGuid}>Go to public page</a></div>

            if (theCalc.publicationDatas.updated == "false") {
                calcState = <React.Fragment><img style={{ marginLeft: "25px", width: "25px" }} src={logoFileDownload}></img></React.Fragment>
                calcPub = <div class="spacingCenterStyle"><div class="spacingCenterStyle"><a class="spacingCenterStyle" href={"/assetPage?id=" + theCalc.originalGuid}>Go to public page</a></div>
                    <div class="spacingCenterStyle"><i style={{ fontSize: "23px", color: "#f9e401" }} class="fa fa-exclamation-circle"></i>
                        <div style={{ marginLeft: "10px" }}>{"Update available"}</div>
                        <button class="btn btn-default myBtnsGreenUpd" style={{ marginLeft: "30px" }} onClick={() => { this.updateCalc(theCalc) }}>Update</button></div></div>
            }
            
        }

        var calcInfoHeaderName = <div style={{ width: "93%", textAlign: "center" }}><h4 class="modal-title centerEl paddingModalTitle breakAllWord">{utils.getTextFrom(theCalc.name)}{calcState}
        </h4></div>

        var calcInfoHeader = <ModalHeader>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
            }}>
                <div class="modalHeaderNoBorder">
                    <div style={{ width: "7%" }}></div>
                    {calcInfoHeaderName}
                    <ModalClose onClick={this.closeCalcInfo} />
                </div>
                {calcPub}
            </div>
        </ModalHeader>;



        const description = <div class="infoPanel"><div class="titleInfoPanel">Description</div>
            <div class="contentInfoPanel">{utils.getTextFrom(theCalc.description)}</div></div>

        const func = utils.getAllCustomFunctions(theCalc);
        var funcList = [];

        var index = 0
        while (index < func.length) {
            const funcKey = utils.getTextFrom(utils.getKeyFrom(func[index]));
            const funcExp = utils.getTextFrom(utils.getExpressionFrom(func[index]));

            funcList.push(<div><div class="funcConstDiv">
                <div class="actionKeyInfo">{funcKey}</div>
                <div name="" class="actionExprInfo">{funcExp}</div>
                </div><hr class="myHRCF" /></div>)
            
            index += 1;

        }

        const constants = utils.getAllCustomConstants(theCalc);
        var constantsList = [];
        index = 0
        while (index < constants.length) {
            const constantKey = utils.getTextFrom(utils.getKeyFrom(constants[index]));
            const constantValue = utils.getTextFrom(utils.getValueFrom(constants[index]));

            constantsList.push(<div><div class="funcConstDiv">
                <div class="actionKeyInfo">{constantKey}</div>
                <div name="" class="actionExprInfo">{constantValue}</div>
            </div><hr class="myHRCF" /></div>)

            index += 1;

        }

        const funcsLayout = <div class="infoPanel"><div class="titleInfoPanel">Functions</div>
            <div class="contentInfoPanelMyCalc">{funcList}</div></div>

        const constantsLayout = <div class="infoPanel"><div class="titleInfoPanel">Constants</div>
            <div class="contentInfoPanelMyCalc">{constantsList}</div></div>

        const modalBodyFooter = <React.Fragment><ModalBody>
            {description}<hr class="myHRInfo" />{funcsLayout}<hr class="myHRInfo" />{constantsLayout}
        </ModalBody>
            <ModalFooter>
                <button className='btn btn-default myBtns' onClick={this.closeCalcInfo}>Close</button>
                <button className='btn btn-default myBtns' onClick={() => this.deleteCalc(theCalc)}>Delete</button>
            </ModalFooter></React.Fragment>

        const theModal = <React.Fragment>{calcInfoHeader}{modalBodyFooter}</React.Fragment>

        this.calcInfoLayout.push(theModal);




        this.setState({
            isCalcInfoModalOpen: true
        });
    };

    closeCalcInfo = () => {
        this.setState({
            isCalcInfoModalOpen: false
        });
    };

    deleteCalc = (theCalc) => {
        this.confirmDeleteCalcLayout = [];
        this.confirmDeleteCalcLayout.push(
            <div style={{
                margin: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                flexDirection: "column"
            }}>
                <div>{"Please confirm "}<br /><br /></div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <button class="btn btn-default myBtns" onClick={() => this.confirmDeleteCalc(theCalc)}>Confirm</button>
                    <button class="btn btn-default myBtns" onClick={this.closeConfirmDeleteCalc}>Cancel</button><br /></div></div>)
        this.setState({
            isConfirmDeleteCalcOpen: true
        });
    };

    closeConfirmDeleteCalc = () => {
        this.setState({
            isConfirmDeleteCalcOpen: false
        });
    };

    confirmDeleteCalc = (theCalc) => {

        if (theCalc.isPublic == "true") {
            this.errorText = [];
            this.errorText.push(
                <div style={{
                    margin: "25px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    flexDirection: "column"
                }}>
                    <div style={{ color: "red" }}>Error</div><br />
                    {"You cannot delete a published calculator. Please unpublish this calculator before deleting it."}</div>)
            this.setState({ isErrorSubModalOpen: true });
            return;
        }

        const datas = {
            calculatorGuid: theCalc.guid,
        };

        databasesActions.removeCalculator(datas)
            .then(res => {
                if (res.errorHasOccurred != null) {
                    this.setState({ isConfirmDeleteCalcOpen: false });
                    this.showAlertError("An error has occurred. Please try again.", "#alert-InfoCalcAreaModal");
                }
                else {
                    window.location.reload();
                }
            })
            .catch(err => {
                this.setState({ isConfirmDeleteCalcOpen: false });
                this.showAlertError("An error has occurred. Please try again.", "#alert-InfoCalcAreaModal");
            }
            );
    };

    updateCalc = (theCalc) => {

        const datas = {
            calculatorGuid: theCalc.guid,
        };

        databasesActions.updateCalculator(datas)
            .then(res => {
                if (res.errorHasOccurred != null) {
                    this.showAlertError("An error has occurred. Please try again.", "#alert-InfoCalcAreaModal");
                }
                else {
                    window.location.reload();
                }
            })
            .catch(err => {
                this.showAlertError("An error has occurred. Please try again.", "#alert-InfoCalcAreaModal");
            }
            );
    };


    createCalculatorChangeHandler = (event) => {
        var newCalculator = this.state.newCalculator;

        if (event.target.id == "name") {
            newCalculator.name = event.target.value;
        }
        else if (event.target.id == "description") {
            newCalculator.description = event.target.value;
        }

        this.setState({ newCalculator: newCalculator });
    }



    useCalculator(calculator) {
        console.log(calculator.actions);
        localStorage.setItem("selectedCalculator", JSON.stringify(calculator));
        this.props.history.push("/");
    };

    createAcalculator = () => {
        this.state.newCalculator = { name: "", description: "" };
        this.setState({
            isCreateAcalculatorModalOpen: true
        });
    };

    cancelCreateAcalculator = () => {
        this.setState({
            isCreateAcalculatorModalOpen: false
        });
    };

    confirmCreateAcalculator = (e) => {
        e.preventDefault();

        console.log(this.props.auth.guid);

        const datas = {
            calculatorName: utils.getCodedFrom(this.state.newCalculator.name),
            description: utils.getCodedFrom(this.state.newCalculator.description),
            authorGuid: this.props.auth.guid
        };

        if (this.state.newCalculator.name.trim() == "") {
            this.errorTextCreateCalc = [];
            this.errorTextCreateCalc.push(
                <div style={{
                    margin: "25px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    flexDirection: "column"
                }}>
                    <div style={{ color: "red" }}>Error</div><br />
                    {"Please enter a name for your calculator."}</div>)
            this.setState({ isErrorSubModalCreateCalcOpen: true });
            return;
        }

        databasesActions.createNewCalculator(datas)
            .then(res => {
                if (res.errorHasOccurred != null) {
                    this.setState({ isCreateAcalculatorModalOpen: false });
                    this.showAlertError("An error has occurred. Please try again.", "#alert-baseAreaModal");
                }
                else {
                    window.location.reload();
                }
            })
            .catch(err => {
                this.setState({ isCreateAcalculatorModalOpen: false });
                this.showAlertError("An error has occurred. Please try again.", "#alert-baseAreaModal");
            });
    };

    closeErrorSubModal = () => {
        this.setState({ isErrorSubModalOpen: false });
        this.setState({ isConfirmDeleteCalcOpen: false });
    };

    closeErrorCreateCalcSubModal = () => {
        this.setState({ isErrorSubModalCreateCalcOpen: false });
    };

    
    render() {              
        
        let subModalDialogStyles = {
            base: {
                bottom: -600,
                transition: 'bottom 0.4s'
            },
            open: {
                bottom: 0
            }
        };

        return (
                    <div>
                            {(() => {
                                if (this.state.fetching) {
                                    return (
                                        <div style={{ height: "600px", display: "flex", alignItems: "center", justifyContent: "space-around", fontSize: "16px" }}>Fetching data...</div>
                                    )
                                } else {
                                    return (
                                        <div className="browser-default princ">
                                            <div class = "myCalcPaddingSetter">
                                                <div class="customProps p-10 bg-surface-secondary">
                                                    <div class="customProps myCalcCustTableContainer">
                                                        <div class= "headerMyCalcCont">
                                                        <div class="theflexRow">
                                                            {this.headerEl}
                                                            </div>
                                                            </div>
                                                        <div class="my-8 text-center"></div>



                                                        <div class="customProps card">

                                                            <div class="table-responsive">
                                                                <table class="table table-hover table-nowrap myTableCalc">
                                                                    <thead class="thead-light myTableCalc">
                                                                        {this.headerTable}
                                                                    </thead>
                                                                    <tbody class= "myTableCalc">{this.rows}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    )
                                }
                            })()}
                        

                <Modal isOpen={this.state.isCalcInfoModalOpen} size='modal-lg' onRequestHide={this.closeCalcInfo}>
                    {this.calcInfoLayout}

                    <Modal isOpen={this.state.isConfirmDeleteCalcOpen} onRequestHide={this.closeConfirmDeleteCalc} dialogStyles={subModalDialogStyles}>
                        <ModalBody>
                            <ModalClose onClick={this.closeConfirmDeleteCalc} />
                            {this.confirmDeleteCalcLayout}
                        </ModalBody>

                        <Modal isOpen={this.state.isErrorSubModalOpen} onRequestHide={this.closeErrorSubModal} dialogStyles={subModalDialogStyles}>
                            <ModalBody>
                                <ModalClose onClick={this.closeErrorSubModal} />
                                {this.errorText}
                            </ModalBody>
                        </Modal>
                    </Modal>
                    <div id="alert-InfoCalcAreaModal" class="alert-area"></div>
                </Modal>


                <Modal isOpen={this.state.isCreateAcalculatorModalOpen} onRequestHide={this.cancelCreateAcalculator} dialogStyles={subModalDialogStyles}>
                    <div class="myFormDivContainer">
                        <form class="myFormContainer" id="contact" action="" onSubmit={this.confirmCreateAcalculator}>
                            <fieldset>
                                <input class="myFormContainer" placeholder="Name" id="name" maxlength="1000" type="text" tabindex="1" onChange={this.createCalculatorChangeHandler} required value={this.state.newCalculator.name} autofocus />
                            </fieldset>
                            <fieldset>
                                <textarea placeholder="Description (Optional)" id="description" maxlength="1000" tabindex="2" onChange={this.createCalculatorChangeHandler} value={this.state.newCalculator.description} ></textarea>
                            </fieldset>


                            <fieldset>
                                <button name="submit" type="submit" id="contact-submit">Submit</button>
                            </fieldset>

                        </form>
                    </div>

                    <Modal isOpen={this.state.isErrorSubModalCreateCalcOpen} onRequestHide={this.closeErrorCreateCalcSubModal} dialogStyles={subModalDialogStyles}>
                        <ModalBody>
                            <ModalClose onClick={this.closeErrorCreateCalcSubModal} />
                            {this.errorTextCreateCalc}
                        </ModalBody>
                    </Modal>


                </Modal>


                <div id="alert-baseAreaModal" class="alert-area"></div>
            </div>
        );
        
    }
}





MyCalculators.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(
    mapStateToProps,
    {}
)(MyCalculators);



var AlertBox = function (id, option, success) {
    this.show = function (msg) {
        if (msg === '' || typeof msg === 'undefined' || msg === null) {
            throw '"msg parameter is empty"';
        }
        else {
            console.log(id);
            var alertArea = document.querySelector(id);
            var alertBox = document.createElement('DIV');
            var alertContent = document.createElement('DIV');
            var alertClose = document.createElement('A');
            var alertClass = this;
            alertContent.classList.add('alert-content');
            alertContent.innerText = msg;
            alertClose.classList.add('alert-close');
            if (success) {
                alertBox.classList.add('alert-box-green');
            }
            else {
                alertBox.classList.add('alert-box-red');
            }
            alertBox.appendChild(alertContent);
            if (!option.hideCloseButton || typeof option.hideCloseButton === 'undefined') {
                alertBox.appendChild(alertClose);
            }
            alertArea.appendChild(alertBox);
            alertClose.addEventListener('click', function (event) {
                event.preventDefault();
                alertClass.hide(alertBox);
            });
            if (!option.persistent) {
                var alertTimeout = setTimeout(function () {
                    alertClass.hide(alertBox);
                    clearTimeout(alertTimeout);
                }, option.closeTime);
            }
        }
    };

    this.hide = function (alertBox) {
        alertBox.classList.add('hide');
        var disperseTimeout = setTimeout(function () {
            if (alertBox.parentNode != null) {
                alertBox.parentNode.removeChild(alertBox);
                clearTimeout(disperseTimeout);
            }

        }, 500);
    };
};