import axios from "axios";
const fetch = require('node-fetch');

export const databasesActions =
{
    getHostVar: function () {
        return "";
        //return "http://localhost:5000";
    }, 

    setAllUserCalculators: function (authorGuid) {

        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() +  "/api/databaseActions/setAllUserCalculators", authorGuid)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });             
    }, 

    GetAllPublicCalculatorsAuthorFormat: function () {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() +  "/api/databaseActions/GetAllPublicCalculatorsAuthorFormat")
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    GetAllPublicFunctionsAuthorFormat: function () {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/GetAllPublicFunctionsAuthorFormat")
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    }, 

    createNewCalculator: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/createNewCalculator", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    createNewAction: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/createNewAction", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    getCalculatorOfGuidAuthorFormat: function (datas) {

        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/getCalculatorOfGuidAuthorFormat", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    addLikeCalculator: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/addLikeCalculator", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    removeLikeCalculator: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/removeLikeCalculator", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    addLikeFunc: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/addLikeFunc", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    removeLikeFunc: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/removeLikeFunc", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    removeAction: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/removeAction", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    publishFunction: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/publishFunction", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    publishCalculator: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/publishCalculator", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    unpublishFunction: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/unpublishFunction", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    unpublishCalculator: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/unpublishCalculator", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    editAction: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/editAction", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    editCalculator: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/editCalculator", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    removeCalculator: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/removeCalculator", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    importCalculator: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/importCalculator", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    moveAction: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/moveAction", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    },

    updateCalculator: function (datas) {
        return new Promise(function (success, error) {
            axios.post(databasesActions.getHostVar() + "/api/databaseActions/updateCalculator", datas)
                .then(res => success(res))
                .catch(err => {
                    error(err)
                });
        });
    }



};


export const utils =
{
    getAllCustomConstants: function (calc) {
        var toReturn = []
        const actions = calc.actions;
        for (var i = 0; i < actions.length; i++) {
            if (this.getTypeFrom(actions[i]) === "const" && !(this.isSpecial(actions[i]))) {
                toReturn.push(actions[i]);
            }
        }
        return toReturn;
    },

    getNumberOfCustomConstants: function (calc) {
        return this.getAllCustomConstants(calc).length;
    },

    getAllCustomFunctions: function (calc) {
        var toReturn = []
        const actions = calc.actions;
        for (var i = 0; i < actions.length; i++) {
            if (this.getTypeFrom(actions[i]) === "func" && !(this.isSpecial(actions[i]))) {
                toReturn.push(actions[i]);
            }
        }
        return toReturn;
    },

    getSpecials: function (calc) {
        var toReturn = []
        const actions = calc.actions;
        for (var i = 0; i < actions.length; i++) {
            if (this.isSpecial(actions[i])) {
                toReturn.push(actions[i]);
            }
        }
        return toReturn;
    },

    getNumberOfCustomFunctions: function (calc) {
        return this.getAllCustomFunctions(calc).length;
    },

    getAllCustomActions: function (calc) {
        var toReturn = []
        const actions = calc.actions;
        for (var i = 0; i < actions.length; i++) {
            toReturn.push(actions[i]);
        }
        return toReturn;
    },

    getTypeFrom: function (action) {
        if (action == "?") {
            return "?";
        }
        var params = action.split(";");
        return params[0].split("=")[1].trim();
    },

    isSpecial: function (action) {
        if (action == "?") {
            return "?";
        }
        var params = action.split(";");
        return (params[1].split("=")[1]).trim() === "true";
    },

    isSpecialByKey: function (key, calc) {
        return this.isSpecial(this.getActionOfName(key, calc));
    },

    getKeyFrom: function (action) {
        if (action == "?") {
            return "?";
        }
        var params = action.split(";");
        return params[2].split("=")[1].trim();
    },

    getKey: function (action) {
        if (action == "?") {
            return "?";
        }
        var params = action.split(";");
        return params[2].split("=")[1].trim();
    },

    getActionOfName(actionName, calc) {
        for (var i = 0; i < calc.actions.length; i++) {
            if (this.getKeyFrom(calc.actions[i]) == actionName) {
                return calc.actions[i];
            }
        }

        //console.log(calc.actions);
        console.log("Error. The action " + actionName + " was not found");
        throw ("Error. The action was not found");
    },


    getValue: function (constant, calc) {
        return parseFloat(this.getTextFrom(this.getValueFrom(this.getActionOfName(constant, calc))));
    },


    getParams: function (func, calc) {
        return this.getParamsFrom(this.getActionOfName(func, calc));
    },

    getExpression: function (func, calc) {
        return this.getExpressionFrom(this.getActionOfName(func, calc));
    },

    isOperator: function (term) {
        return term == "+" || term == "-" || term == "*" || term == "/";
    },



    isConstant: function (term, calc) {
        return this.getTypeFrom(this.getActionOfName(term, calc)) == "const";
    },

    getSizeFrom: function (action) {
        if (action == "?") {
            return "?";
        }
        var params = action.split(";");
        return params[3].split("=")[1].trim();;
    },

    getParamsFrom: function (func) {
        if (func == "?") {
            return "?";
        }
        var params = func.split(";");
        return params[4].split("=")[1].trim();
    },

    getExpressionFrom: function (func) {
        if (func == "?") {
            return "?";
        }
        var params = func.split(";");
        return params[5].split("=")[1];
    },

    getValueFrom: function (constant) {
        if (constant == "?") {
            return "?";
        }
        var params = constant.split(";");
        return (params[4].split("=")[1]).trim();
    },

    getDescriptionFrom: function (action) {
        if (action == "?") {
            return "?";
        }
        if (this.getTypeFrom(action) == "func") {
            var params = action.split(";");
            var desc = params[6].split("=");
            if (desc.length == 2) {
                return desc[1];
            }
            else {
                return "";
            }
        }

        else {
            var params = action.split(";");
            var desc = params[5].split("=");
            if (desc.length == 2) {
                return desc[1];
            }
            else {
                return "";
            }
        }
    },

    UserLikeCalc: function (calc, userGuid) {
        var listOfLikes_ = calc.publicationDatas.listOfLikes;
        const listOfLikes = listOfLikes_.split(";");
        return listOfLikes.includes(userGuid);
    },

    UserLikeFunc: function (calc, func, userGuid) {
        const funcKey = this.getKeyFrom(func);
        var listOfLikesFunc_ = calc.publicationDatas.listOfLikesFunc;
        const listOfLikesFunc = listOfLikesFunc_.split(";");
        for (var i = 0; i < listOfLikesFunc.length; i++) {
            const funcAct = listOfLikesFunc[i].split(":");
            if (funcAct[0] == funcKey) {
                const userLiking = funcAct[1].split(",")
                return userLiking.includes(userGuid)
            }
        }
        return false;
    },

    isFuncPublic: function (calc, func) {
        if (calc.publicationDatas != null && calc.publicationDatas.listOfLikesFunc != null) {
            var listOfLikesFunc_ = calc.publicationDatas.listOfLikesFunc;
            const listOfLikesFunc = listOfLikesFunc_.split(";");

            for (var i = 0; i < listOfLikesFunc.length; i++) {
                const funcAct = listOfLikesFunc[i].split(":");
                if (funcAct[0] == this.getKeyFrom(func)) {
                    return true;
                }
            }
        }
        return false;
    },

    getNumberOfLikesCalc(calc) {
        const likes = calc.publicationDatas.listOfLikes.split(";")
        if (likes.length == 0 || (likes.length == 1 && likes[0].trim() == "")) {
            return 0;
        }
        return likes.length;
    },

    getNumberOfLikesFunc(calc, func) {
        const listOfLikesFunc = calc.publicationDatas.listOfLikesFunc.split(";")
        for (var i = 0; i < listOfLikesFunc.length; i++) {
            const funcAct = listOfLikesFunc[i].split(":");
            if (funcAct[0] == this.getKeyFrom(func)) {
                const likes = funcAct[1].split(",")
                if (likes.length == 0 || (likes.length == 1 && likes[0].trim() == "")) {
                    return 0;
                }
                return likes.length;
            }
        }

        return 0;
    },

    getCodedFrom(text) {
        var trimBeg = "";
        var k = 0;
        while (k < text.length && text[k] == " ") {
            k++;
        }
        if (k < text.length) {
            //trimBeg = text.substring(k, text.length - k + 1);
            trimBeg = text.substring(k, text.length);
        }


        if (trimBeg.length >= 9 && trimBeg.substring(0, 9) == "_itscoded") {
            return text;
        }

        var toReturn = "_itscoded";
        for (var i = 0; i < text.length; i++) {
            toReturn += this.getCodeFrom(text[i]);
        }
        return toReturn;
    },

    getTextFrom(coded) {
        var trimBeg = "";
        var k = 0;
        while (k < coded.length && coded[k] == " ")
        {
            k++;
        }
        
        if (k < coded.length) {
            //trimBeg = coded.substring(k, coded.length-k+1);
            trimBeg = coded.substring(k, coded.length);
        }


        if (trimBeg.length < 9 || (trimBeg.length >= 9 && trimBeg.substring(0, 9) != "_itscoded")) {
            return coded;
        }

        var codedToCons = trimBeg.substring(9, trimBeg.length);

        var toReturn = "";
        for (var i = 0; i < codedToCons.length; i++) {
            if (codedToCons[i] === "_") {
                toReturn = toReturn + (this.getCharacterFrom("" + codedToCons[i] + codedToCons[i + 1] + codedToCons[i + 2]));
                i += 2;
            }
            else {
                toReturn += codedToCons[i];
            }
        }
        return toReturn;
    },

    getCodeFrom(character) {
        const chars = ["&", ";", ":", ",", "%", "?", "=", "/", ".", "_", "-", "#"]
        const codes = ["_00", "_01", "_02", "_03", "_04", "_05", "_06", "_07", "_08", "_09", "_10", "_11"]

        for (var i = 0; i < chars.length; i++) {
            if (chars[i] == character) {
                return codes[i];
            }
        }

        return character;
    },

    getCharacterFrom(code) {
        const chars = ["&", ";", ":", ",", "%", "?", "=", "/", ".", "_", "-", "#"]
        const codes = ["_00", "_01", "_02", "_03", "_04", "_05", "_06", "_07", "_08", "_09", "_10", "_11"]

        for (var i = 0; i < codes.length; i++) {
            if (codes[i] == code) {
                return chars[i];
            }
        }

        return code;
    },

    isKeyValid(theKey) {
        const restricted = ["_", "+", "-", "X", "/", ".", "(", ")", "espace", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]

        for (var i = 0; i < theKey.length; i++) {
            if (restricted.includes(theKey[i])) {
                return false
            }
        }

        return true;
    },

    minifyWord(text, numberOfCharsMax) {

        if (text.length == 0) {
            return "";
        }

        var toReturn = "";
        var lengthAct = 0;
        for (var i = 0; i < text.length; i++) {

            toReturn += text[i];
            lengthAct += 1;

            if (text[i] == " ") {
                lengthAct = 0;
            }

            else if (lengthAct > numberOfCharsMax) {
                return toReturn + "...";
            }
        }

        return toReturn;
    },

    minify(text, numberOfCharsMax) {

        if (text.length == 0) {
            return "";
        }

        var toReturn = "";
        var lengthAct = 0;

        for (var i = 0; i < numberOfCharsMax; i++) {

            if (i >= text.length) {
                return toReturn;
            }

            if (text[i] === "\n") {
                return toReturn + "...";
            }

            toReturn += text[i];
            lengthAct += 1;

            if (text[i] == " ") {
                lengthAct = 0;
            }
            else if (lengthAct > ((numberOfCharsMax / 2) | 0)) {
                return toReturn + "...";
            }

            if (i == text.length - 1) {
                return toReturn;
            }
        }

        return toReturn + "...";
    },

    minifyLine(text, numberOfCharsMax) {

        if (text.length == 0) {
            return "";
        }

        var toReturn = "";

        for (var i = 0; i < numberOfCharsMax; i++) {

            if (i >= text.length) {
                return toReturn;
            }

            if (text[i] === "\n") {
                return toReturn + "...";
            }

            toReturn += text[i];

            if (i == text.length - 1) {
                return toReturn;
            }
        }

        return toReturn + "...";
    },

    minifyLineNoPoints(text, numberOfCharsMax) {

        if (text.length == 0) {
            return "";
        }

        var toReturn = "";

        for (var i = 0; i < numberOfCharsMax; i++) {

            if (i >= text.length) {
                return toReturn;
            }

            if (text[i] === "\n") {
                return toReturn;
            }

            toReturn += text[i];

            if (i == text.length - 1) {
                return toReturn;
            }
        }

        return toReturn;
    },

    moveActionGA(calc, theAction, direction) {

        var relatedActions = [];
        var notRelatedActions = []

        if (this.getTypeFrom(theAction) == "func") {
            relatedActions = this.getAllCustomFunctions(calc);
            notRelatedActions = this.getAllCustomConstants(calc);
        }
        else {
            relatedActions = this.getAllCustomConstants(calc);
            notRelatedActions = this.getAllCustomFunctions(calc);
        }

        for (var i = 0; i < relatedActions.length; i++) {
            if (this.getKeyFrom(theAction) == this.getKeyFrom(relatedActions[i])) {
                if (direction == "up" && i != 0) {
                    relatedActions.splice(i - 1, 0, theAction)
                    relatedActions.splice(i + 1, 1)
                }
                else if (direction == "down" && i != relatedActions.length - 1) {
                    relatedActions.splice(i + 2, 0, theAction)
                    relatedActions.splice(i, 1)
                }
                else if (direction == "top" && i != 0) {
                    relatedActions.splice(i, 1)
                    relatedActions.splice(0, 0, theAction)
                }
                else if (direction == "bottom" && i != relatedActions.length - 1) {
                    relatedActions.splice(i, 1)
                    relatedActions.splice(relatedActions.length, 0, theAction);
                }

                break;
            }
        }

        var toReturn = this.getSpecials(calc);

        if (this.getTypeFrom(theAction) == "func") {
            for (var j = 0; j < relatedActions.length; j++) {
                toReturn.push(relatedActions[j]);
            }
            for (var j = 0; j < notRelatedActions.length; j++) {
                toReturn.push(notRelatedActions[j]);
            }
        }
        else {
            for (var j = 0; j < notRelatedActions.length; j++) {
                toReturn.push(notRelatedActions[j]);
            }
            for (var j = 0; j < relatedActions.length; j++) {
                toReturn.push(relatedActions[j]);
            }
        }

        return toReturn;

    },


    isActionCreationValid(isFunc, key, params, expressionOrValue, calculator, creating, originalKey) {
        var notValidInput = ["_", "+", "-", "*", "/", ".", "(", ")", ",", " ", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]

        if (key.trim() == "") {
            return { error: "Please enter a key." }
        }

        for (var i = 0; i < key.length; i++) {
            if (notValidInput.includes(key[i])) {
                if (key[i] == " ") {
                    if (utils.thereIsSpacesInMiddle(key)) {
                        return { error: "There should be no space in a function or constant key." }
                    }
                }
                else {
                    return { error: "The character " + key[i] + " cannot be used in a function or constant key.\n\n You can learn more about restricted characters in the documentation." }
                }
            }
        }

        if (creating || (!creating && (key != utils.getTextFrom(originalKey)))) {
            var actions = calculator.actions;
            for (var i = 0; i < actions.length; i++) {
                if (this.getKeyFrom(actions[i]) == utils.getCodedFrom(key)) {
                    return { error: "A function or constant named " + key + " is already present in this calculator." }
                }
            }
        }


        if (isFunc) {
            var paramsArr = params.split(",");

            for (var i = 0; i < paramsArr.length; i++) {

                var paramAct = paramsArr[i];

                if (paramAct.trim() == "") {
                    return { error: "Please enter parameters comma-separated." }
                }

                for (var j = 0; j < paramAct.length; j++) {
                    if (notValidInput.includes(paramAct[j])) {
                        if (paramAct[j] == " ") {
                            if (utils.thereIsSpacesInMiddle(paramAct)) {
                                return { error: "There should be no space in a parameter name." }
                            }
                        }
                        else {
                            return { error: "The character " + paramAct[j] + " cannot be used in a parameter's name.\n\n You can learn more about restricted characters in the documentation." }
                        }
                    }
                }
            }

            for (var i = 0; i < paramsArr.length; i++) {
                var spliced = []
                spliced.splice(i, 1);
                if (spliced.includes(paramsArr[i])) {
                    return { error: "Two parameters have similar names." }
                }
            }

            var expressionToCompute = expressionOrValue;
            var lenghtIn = expressionToCompute.length;
            for (var i = 0; i < paramsArr.length; i++) {
                var randNum = Math.random();
                expressionToCompute = this.replaceInStr(expressionToCompute, paramsArr[i].trim(), "" + randNum, calculator);
            }

            var errorsPot = this.computeExpression(expressionToCompute, calculator);

            var characterPosErr;
            if (lenghtIn != expressionToCompute.length) {
                characterPosErr = errorsPot.characterPos - (("" + randNum).length * params.length) + params.length;
            }
            else {
                characterPosErr = errorsPot.characterPos;
            }

            if (errorsPot.error != null) {                
                return { error: "Expression - " + this.makeErrorText(errorsPot.error, characterPosErr) };
            }
        }

        else {
            if (this.thereIsSpacesInMiddle(expressionOrValue)) {
                return { error: "The value must be a real number" };
            }

            var expressionOrValue_ = expressionOrValue.trim();
            for (var i = 0; i < expressionOrValue_.length; i++) {
                if (!(this.isNumber(expressionOrValue_[i])) && expressionOrValue_[i] != ".") {
                    return { error: "The value must be a real number" };
                }
                else if (expressionOrValue_[i] == ".") {
                    if (i == expressionOrValue_.length - 1) {
                        return { error: "The value must be a real number" };
                    }

                    for (var j = i + 1; j < expressionOrValue_.length; j++) {
                        if (!(this.isNumber(expressionOrValue_[j]))) {
                            return { error: "The value must be a real number" };
                        }
                    }

                    break;
                }
            }
        }

        return { success: "" };

    },

    getNextWord(expression, i) {
        var i_ = i;
        var toReturn = "";

        while (i_ < expression.length) {
            if (expression[i_] == " ") {
                i_++;
            }
            else {
                if (this.isNumber(expression[i_])) {
                    while (i_ < expression.length && this.isNumber(expression[i_])) {
                        toReturn += expression[i_];
                        i_++;
                    }
                    return { word: toReturn, pos: i_ };
                }
                else {
                    while (i_ < expression.length && expression[i_] != " ") {
                        if (this.isOperator(expression[i_]) || expression[i_] == "(" || expression[i_] == ")" || expression[i_] == "," || expression[i_] == ".") {
                            if (toReturn.length > 0) {
                                return { word: toReturn, pos: i_ };
                            }
                            else {
                                return { word: expression[i_], pos: i_ + 1 };
                            }
                        }

                        toReturn += expression[i_];
                        i_++;
                    }
                }
            }
        }

        if (toReturn.length == 0) {
            return null
        }
        else {
            return { word: toReturn, pos: i_ };
        }
    },

    isNumber(term) {
        return term == 0 || term == 1 || term == 2 || term == 3 || term == 4 || term == 5 || term == 6 || term == 7 || term == 8 || term == 9;
    },

    isDouble(term) {
        return this.isNumber(term[0])
    },

    getNextToken(expression, i, calculator) {
        var nextWord = this.getNextWord(expression, i);

        if (nextWord != null && nextWord.word == "." || nextWord.word == ")" || nextWord.word == ",") {
            return { error: "The symbol " + nextWord.word + " is unexpected.", characterPos: i };
        }
        else if (nextWord != null && nextWord.word == "(") {
            var sentBetweenPar = this.getSentPar(expression, i);
            if (sentBetweenPar.error != null) {
                return { error: sentBetweenPar.error, characterPos: i };
            }
            else {
                return { token: sentBetweenPar.sent, pos: sentBetweenPar.pos, characterPos: i }
            }
        }
        else if (nextWord != null){

            if (this.isDouble(nextWord.word)) {
                var tokenNumber = nextWord.word;
                var nextWordPoint = this.getNextWord(expression, nextWord.pos);

                if (nextWordPoint != null && nextWordPoint.word == ".") {
                    tokenNumber += ".";

                    var decWord = this.getNextWord(expression, nextWordPoint.pos);
                    if (decWord != null && this.isDouble(decWord.word)) {
                        var tokenNumberDec = decWord.word;
                        return { token: tokenNumber + tokenNumberDec, pos: decWord.pos, characterPos: i }
                    }
                    else {
                        return { error: "The decimal part is expected.", characterPos: nextWordPoint.pos }
                    }
                }
                else {
                    return { token: tokenNumber, pos: nextWord.pos, characterPos: i }
                }
            }
            else {
                if (this.isOperator(nextWord.word)) {
                    return { token: nextWord.word, pos: nextWord.pos, characterPos: i }
                }
                else {
                    var actions = calculator.actions;
                    for (var j = 0; j < actions.length; j++) {
                        if (!(this.isNumber(this.getTextFrom(this.getKeyFrom(actions[j])))) &&
                            !(this.isOperator(this.getTextFrom(this.getKeyFrom(actions[j])))) && !(this.getTypeFrom(actions[j]) == "other")
                            && this.getKeyFrom(actions[j]) == utils.getCodedFrom(nextWord.word)) {
                            return { token: utils.getCodedFrom(nextWord.word), pos: nextWord.pos, characterPos: i }
                        }
                    }
                }
            }


            return {
                error: "The term " + nextWord.word + " is not recognized.", characterPos: nextWord.pos
            }
        }

    },

    getTokensExpression(expression, calculator) {
        var i = 0;
        var toReturn = [];
        while (i < expression.length) {
            var nextToken = this.getNextToken(expression, i, calculator);
            if (nextToken.error != null) {
                return nextToken;
            }
            else {
                toReturn.push(nextToken);
                i = nextToken.pos;
            }
        }

        return toReturn;

    },

    makeErrorText(errorText, characterPos) {
        if (characterPos < 0) {
            return "The input expression is incorrect.\n\nPlease check the the synthax. You can consult the documentation to see the valid terms."
        }
        var toReturn = "Character " + characterPos + " : " + errorText + "\n\n";
        toReturn += "Please check the the synthax. You can consult the documentation to see the valid terms."
        return toReturn;
    },

    removeParenthesis(str) {
        var toReturn = "";
        for (var p = 0; p < str.length; p++) {
            if (p != 0 && p != str.length - 1) {
                toReturn += str[p];
            }
        }
        return toReturn;
    },

    getSentPar(str, i) {
        var toReturn = "(";

        var i_ = i + 1;

        while (i_ < str.length && str[i_] != ")") {
            if (str[i_] == "(") {
                var parSup = this.getSentPar(str, i_);
                if (parSup.error != null) {
                    return parSup;
                }
                else {
                    toReturn += parSup.sent;
                }
                i_ += parSup.sent.length - 1;
            }
            else {
                toReturn += str[i_];
            }
            if (i_ == str.length - 1) {
                return { error: "Error: The open parenthesis has not been closed." };
            }
            i_++;
        }
        toReturn += ")";

        return { sent: toReturn, pos: i_ + 1 };

    },

    isOperatorDivMult(operator) {
        return operator == "/" || operator == "*";
    },

    getStringOperator(operator) {
        if (operator == "+") {
            return "add";
        }
        else if (operator == "-") {
            return "sub";
        }

        else if (operator == "/") {
            return "div";
        }
        else {
            return "mult";
        }
    },

    getNextTermGroup(tokens, i, calculator) {
        if (i == tokens.length) {
            return null;
        }

        var operator = "";

        var toReturn = [];

        if (tokens[i].token[0] == "(") {
            operator = "mult";
            toReturn.push({ operator: "mult" });
            toReturn.push({ token: tokens[i].token, characterPos: tokens[i].characterPos });
            return { termGroup: toReturn, pos: i + 1};
        }

        else if (this.isOperator(tokens[i].token)) {
            operator = this.getStringOperator(tokens[i].token);
            if (i == tokens.length - 1) {
                return { error: "Terms are expected after operator.", characterPos: tokens[i].characterPos };
            }
            else {
                var nextToken = tokens[i + 1].token;
                if (this.isOperator(nextToken)) {
                    return { error: "Two consecutive operators found.", characterPos: tokens[i + 1].characterPos };
                }
                else if (nextToken[0] == "(") {
                    toReturn.push({ operator: operator });
                    toReturn.push({ token: nextToken, characterPos: tokens[i + 1].characterPos });
                    return { termGroup: toReturn, pos: i + 2 };
                }
                else if (this.isDouble(nextToken)) {
                    toReturn.push({ operator: operator });
                    toReturn.push({ token: nextToken, characterPos: tokens[i + 1].characterPos })
                    return { termGroup: toReturn, pos: i + 2 };
                }
                else if (this.isConstant(nextToken, calculator)) {
                    toReturn.push({ operator: operator });
                    toReturn.push({ token: nextToken, characterPos: tokens[i + 1].characterPos })
                    return { termGroup: toReturn, pos: i + 2 };
                }
                else {
                    if (i == tokens.length - 2) {
                        return {
                            error: "Parameters of function " + utils.getTextFrom(nextToken) + " are expected.", characterPos: tokens[i + 1].characterPos
                        };
                    }
                    else {
                        var decToken = tokens[i + 2].token;
                        if (decToken[0] != "(") {
                            return { error: "Parameters of function " + utils.getTextFrom(nextToken) + " are expected.", characterPos: tokens[i + 1].characterPos };
                        }
                        else {
                            toReturn.push({ operator: operator });
                            toReturn.push({ token: nextToken, characterPos: tokens[i + 1].characterPos });
                            toReturn.push({ token: decToken, characterPos: tokens[i + 2].characterPos });
                            return { termGroup: toReturn, pos: i + 3 };
                        }
                    }

                }
            }
        }

        else {
            operator = "mult";
            var tokenAct = tokens[i].token
            if (this.isDouble(tokenAct)) {
                toReturn.push({ operator: operator });
                toReturn.push({ token: tokenAct, characterPos: tokens[i].characterPos })
                return { termGroup: toReturn, pos: i + 1 };
            }
            else if (this.isConstant(tokenAct, calculator)) {
                toReturn.push({ operator: operator });
                toReturn.push({ token: tokenAct, characterPos: tokens[i].characterPos })
                return { termGroup: toReturn, pos: i + 1 };
            }
            else {
                if (i == tokens.length - 1) {
                    return { error: "Parameters of function " + this.getTextFrom(tokens[i].token) + " are expected.", characterPos: tokens[i].characterPos };
                }
                else {
                    var decToken = tokens[i + 1].token;
                    if (decToken[0] != "(") {
                        return { error: "Parameters of function " + this.getTextFrom(tokens[i].token) + " are expected.", characterPos: tokens[i].characterPos };
                    }
                    else {
                        toReturn.push({ operator: operator });
                        toReturn.push({ token: tokenAct, characterPos: tokens[i].characterPos });
                        toReturn.push({ token: decToken, characterPos: tokens[i].characterPos });
                        return { termGroup: toReturn, pos: i + 2 };
                    }
                }

            }
        }


    },

    computeTermGroup(termGroup, calculator) {
        if (termGroup[1].token != null && termGroup[1].token[0] == "(") {
            var result = this.computeExpression(this.removeParenthesis(termGroup[1].token), calculator);
            if (result.error != null) {
                return { error: result.error, characterPos: termGroup[1].characterPos + 1 + result.characterPos };
            }
            else {
                return { operator: termGroup[0].operator, value: result.value, characterPos: termGroup[1].characterPos };
            }
        }
        else {
            if (this.isDouble(termGroup[1].token, calculator)) {
                return { operator: termGroup[0].operator, value: parseFloat(termGroup[1].token), characterPos: termGroup[1].characterPos};
            }
            else if (this.isConstant(termGroup[1].token, calculator)) {
                return { operator: termGroup[0].operator, value: this.getValue(termGroup[1].token, calculator), characterPos: termGroup[1].characterPos };
            }
            else {
                var resultFunc = this.computeFunction(termGroup[1].token, termGroup[2].token, calculator);
                if (resultFunc.error != null) {
                    return { error: resultFunc.error, characterPos: termGroup[1].token.characterPos + 1 + resultFunc.characterPos, characterPos: termGroup[1].characterPos }; 
                }
                else {
                    return { operator: termGroup[0].operator, value: resultFunc.value, characterPos: termGroup[1].characterPos }; 
                }
            }
        }

    },

    replaceInStr(str, fractionA, fractionB, calc) {
        var toReturn = "";

        for (var i = 0; i < str.length; i++) {

            var nextWord = this.getNextWord(str, i)
            var actions = calc.actions;
            var jumped = false;

            if (str[i] != " " && nextWord != null) {
                for (var ac = 0; ac < actions.length; ac++) {
                    if ((this.getTypeFrom(actions[ac]) == "func" || this.getTypeFrom(actions[ac]) == "const") && !(this.isNumber(this.getTextFrom(this.getKeyFrom(actions[ac])))) &&
                        this.getTextFrom(this.getKeyFrom(actions[ac])) == nextWord.word) {
                        if (nextWord.word != fractionA) {
                            jumped = true;
                            toReturn += nextWord.word;
                            i = nextWord.pos - 1;
                            break;
                        }

                    }
                }
            }


            if (!jumped && (i + fractionA.length <= str.length)) {
                var toReplacePot = "";
                var replacementPot = toReturn;
                for (var j = 0; j < fractionA.length; j++) {
                    toReplacePot = toReplacePot + str[i + j];
                }
                if (toReplacePot == fractionA) {
                    replacementPot = replacementPot + fractionB;
                    toReturn = replacementPot;
                    i += fractionA.length -1;
                }
                else {
                    toReturn += str[i];
                }
            }
            else if(!jumped) {
                toReturn += str[i];
            }
        }
        return toReturn;
    },

    splitPar(str) {
        var toReturn = [];
        var i = 0;
        var elAct = "";
        while (i < str.length) {

            if (str[i] == ",") {
                if (elAct != "") {
                    toReturn.push(elAct);
                    elAct = "";
                }
                i++;
            }

            else if (str[i] == "(") {
                var betweenPar = this.getSentPar(str, i);
                elAct += betweenPar.sent;
                i = i + betweenPar.sent.length;
            }
            else {
                elAct += str[i];
                i++;
            }
        }

        if (elAct != "") {
            toReturn.push(elAct);
        }

        return toReturn;
    },

    decodeParams(codedParams) {
        var paramsArr = codedParams.split(",");
        var toReturn = "";
        for (var i = 0; i < paramsArr.length; i++) {
            if (i != 0) {
                toReturn += ",";
            }
            toReturn += utils.getTextFrom(paramsArr[i]);
        }
        return toReturn;

    },

    codeParams(decodedParams) {

        var paramsArr = decodedParams.split(",");
        var toReturn = "";
        for (var i = 0; i < paramsArr.length; i++) {
            if (i != 0) {
                toReturn += ",";
            }
            toReturn += utils.getCodedFrom(paramsArr[i]);
        }
        return toReturn;
    },


    computeFunction(func, declaration, calculator) {

        var params = this.splitPar(this.decodeParams(this.getParams(func, calculator)));;
        var paramsProvided = this.splitPar(this.removeParenthesis(declaration))
        var computations = []

        if (params.length != paramsProvided.length) {
            if (params.length == 1) {
                return { error: "The function " + this.getTextFrom(func) + " accept " + params.length + " parameter. Given : " + paramsProvided.length, characterPos: 0 };
            }
            else {
                return { error: "The function " + this.getTextFrom(func) + " accept " + params.length + " parameters. Given : " + paramsProvided.length, characterPos: 0 };
            }
            
        }

        else {
            for (var i = 0; i < paramsProvided.length; i++) {
                var computation = this.computeExpression(paramsProvided[i], calculator);
                computations.push(computation);
                if (computation.error != null) {
                    var posAct = 0;
                    for (var j = 0; j < i; j++) {
                        posAct = posAct + paramsProvided.length + 1;
                    }
                    return { error: computation.error, characterPos: posAct + computation.characterPos };
                }
            }

            if (this.isSpecialByKey(func, calculator)) {
                if (utils.getTextFrom(func) == "\u221a") {
                    return { value: Math.sqrt(computations[0].value) };
                }
                else if (utils.getTextFrom(func) == "sin") {
                    return { value: Math.sin(computations[0].value) };
                }
                else if (utils.getTextFrom(func) == "sin\u207b\u00b9") {
                    return { value: Math.asin(computations[0].value) };
                }
                else if (utils.getTextFrom(func) == "cos") {
                    return { value: Math.cos(computations[0].value) };
                }
                else if (utils.getTextFrom(func) == "cos\u207b\u00b9") {
                    return { value: Math.acos(computations[0].value) };
                }
                else if (utils.getTextFrom(func) == "tan") {
                    return { value: Math.tan(computations[0].value) };
                }
                else if (utils.getTextFrom(func) == "tan\u207b\u00b9") {
                    return { value: Math.atan(computations[0].value) };
                }
                else if (utils.getTextFrom(func) == "x\u00b2") {
                    return { value: Math.pow(computations[0].value, 2) };
                }
                else if (utils.getTextFrom(func) == "x\u00b3") {
                    return { value: Math.pow(computations[0].value, 3) };
                }
                else if (utils.getTextFrom(func) == "x\u207f") {
                    return { value: Math.pow(computations[0].value, computations[1].value) };
                }
                else if (utils.getTextFrom(func) == "!") {
                    return { value: this.fact(computations[0].value) };
                }
            }

            else {
                var expressionToCompute = this.getTextFrom(this.getExpression(func, calculator));
                for (var i = 0; i < paramsProvided.length; i++) {
                    expressionToCompute = this.replaceInStr(expressionToCompute, params[i], computations[i].value.toString(), calculator);
                }
                return { value: this.computeExpression(expressionToCompute, calculator).value };
            }
        }
    },

    computeTerms(operator, a, b, sign, characterPos) {
        if (operator == "add") {
            return { operator: sign, value: parseFloat(a) + parseFloat(b), characterPos: characterPos};
        }
        else if (operator == "sub") {
            return { operator: sign, value: parseFloat(a) - parseFloat(b), characterPos: characterPos };
        }
        else if (operator == "mult") {
            return { operator: sign, value: parseFloat(a) * parseFloat(b), characterPos: characterPos };
        }
        else {
            return { operator: sign, value: parseFloat(a) / parseFloat(b), characterPos: characterPos };
        }
    },

    computeSingleTerm(sign, value) {
        if (sign == "add" || sign == "mult") {
            return { value: value };
        }
        else {
            return { value: -1 * value };
        }
    },


    computeExpression(expression, calculator) {
        //console.log(expression);

        if (expression.trim() == "") {
            return { error: "No terms to compute was found", characterPos: 0 };
        }

        var tokens = this.getTokensExpression(expression, calculator);
        //console.log(tokens);
        if (tokens.error != null) {
            return tokens;
        }

        else if (this.isOperatorDivMult(tokens[0].token)) {
            return { error: "The operator " + tokens[0].token + " is not expected at the start of the term.", characterPos: tokens[0].characterPos };
        }

        else {

            var termGroups = [];
            var computationsTG = [];

            var terms = []
            var nextTermGroup = this.getNextTermGroup(tokens, 0, calculator);
            while (nextTermGroup != null) {

                if (nextTermGroup.error != null) {
                    return nextTermGroup;
                }

                termGroups.push(nextTermGroup);

                var comp = this.computeTermGroup(nextTermGroup.termGroup, calculator);
                if (comp.error != null) {
                    return { error: comp.error, characterPos: comp.characterPos };
                }
                terms.push(comp);
                computationsTG.push(comp.value);

                nextTermGroup = this.getNextTermGroup(tokens, nextTermGroup.pos, calculator);
            }

            //console.log(computationsTG);
            //console.log(termGroups);
            //console.log(terms);

            for (var i = 0; i < terms.length; i++) {
                if (terms[i].error != null) {
                    return terms[i].error;
                }
                else {
                    if (i != terms.length - 1) {
                        if (terms[i + 1].operator == "mult" || terms[i + 1].operator == "div") {
                            var computation = this.computeTerms(terms[i + 1].operator, terms[i].value, terms[i + 1].value, terms[i].operator, terms[i].characterPos);
                            terms.splice(i, 2, computation);
                            i -= 1;
                        }
                    }
                }
            }

            for (var i = 0; i < terms.length; i++) {
                //console.log(terms[i]);
            }

            for (var i = 0; i < terms.length; i++) {
                if (i != terms.length - 1) {
                    var computation = this.computeTerms(terms[i + 1].operator, terms[i].value, terms[i + 1].value, terms[i].operator, terms[i].characterPos);
                    terms.splice(i, 2, computation);
                    i -= 1;
                }
            }

            return this.computeSingleTerm(terms[0].operator, terms[0].value);
        }
    },

    computeExpressionErrorFormat(expression, calculator) {
        var result = this.computeExpression(expression, calculator)
        if (result.error != null) {
            return { error: this.makeErrorText(result.error, result.characterPos) };
        }
        else {
            return { value: result.value }
        }
    },

    thereIsSpacesInMiddle(str) {
        for (var i = 0; i < str.length; i++) {
            if (str[i] != " ") {

                for (var j = i; j < str.length; j++) {
                    if (str[j] == " ") {

                        for (var k = j; k < str.length; k++) {
                            if (str[k] != " ") {
                                return true;
                            }
                        }

                    }
                }

            }
        }

        return false;
    },

    fact(num) {
        if (num === 0) { return 1; }
        else { return num * this.fact(num - 1); }
    }
};

